import React, { useContext, useEffect, useState } from 'react';

import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Storage } from '@capacitor/storage';
import { Capacitor } from '@capacitor/core';
import { useCamera, availableFeatures } from '@capacitor-community/camera-react';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import { 
  IonContent,
  IonPage,
  IonButton,
  IonTextarea,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  isPlatform
} from '@ionic/react';
import Alerts from '../components/Alerts';
  
import { config, consoleLog, dict, lang, platforms } from '../components/Config'
import AppContext, { defaultValues } from '../Context';

import { Device } from '@capacitor/device';
//import { App } from '@capacitor/app';

const Admin: React.FC = () => {

  const { sharedValues, setSharedValues }     = useContext(AppContext);
  
  const [iserror, setIserror]                 = useState<boolean>(false);
  const [confirm, setConfirm]                 = useState<boolean>(false);
  const [success, setSuccess]                 = useState<boolean>(false);
  const [header, setHeader]                   = useState<string>("");
  const [message, setMessage]                 = useState<string>("");
  const [info_to_admin, setInfoToAdmin]       = useState<string>("");
  const [email, setEmail]                     = useState<any>("");
  const [raport_class, setRaportClass]        = useState<string>("dn");
  const [deviceInfo, setDeviceInfo]           = useState<any>([]);
  const [deviceLang, setDeviceLang]           = useState<any>([]);

  const [imageData, setImageData]             = useState<any>('');
  
  const logDeviceInfo = async () => {
    const device_lang = await Device.getLanguageCode();
    const device_info = await Device.getInfo();

    consoleLog('device_info',device_info);
    consoleLog('device_lang',device_lang);

    setDeviceInfo(device_info);
    setDeviceLang(device_lang);
  };
  
  
  useEffect(() => {
    logDeviceInfo();
    defineCustomElements(window);
  },[]);
  
  const takePicture = async () => {
    try {
      const image = await Camera.getPhoto({
        quality: 80,
        width: 480,
        height: 360,
        /* allowEditing: false, */
        source: CameraSource.Camera,
        /* resultType: CameraResultType.Uri */
        resultType: CameraResultType.Base64,
        saveToGallery: false
        
      });
    
      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      //let imageUrl = image.webPath;
      let imageUrl = image.base64String;
      consoleLog('image',JSON.stringify(imageUrl));
      setImageData(imageUrl);
      // Can be set to the src of an image now
      //let imageElement:any;
      //imageElement.src = imageUrl;
    } catch {
      consoleLog('no picture','');
    }
  };

  const getLS = () => {
    let unpl_lS_str = "";
    let unpl_lS: any[] = [],
        keys: any[]    = Object.keys(localStorage),
        i              = keys.length;
        
    while (i--) {
        unpl_lS[keys[i]] = localStorage.getItem(keys[i]);
        unpl_lS_str += "" + keys[i] + ": " + localStorage.getItem(keys[i]) + "\n\n";
    }
    return unpl_lS_str;
  }

  const handleSendConfirm = () => {
    const newSharedValues = {
      ...sharedValues,
      confirm: false
    }
    setSharedValues(newSharedValues);
    setHeader("Wysyłka maila");
    setMessage(dict[lang]['kontynuowac']);
    setConfirm(true);
  }

  const handleReportSend = () => {
    
    setMessage("send start");
    
    const fileName = new Date().getTime();
    const formData = new FormData();
          
          if (imageData) {
            if (isPlatform('hybrid')) {
              formData.append('uploadImage', "data:image/jpeg;base64,"+imageData);
              formData.append('uploadImageName', fileName + '.jpg');
            } else {
              formData.append('uploadImage', "data:image/png;base64,"+imageData);
              formData.append('uploadImageName', fileName + '.png');
            }
          }

          formData.append('options', getLS());
          formData.append('device', JSON.stringify(deviceInfo));
          formData.append('info_to_admin', info_to_admin);
          formData.append('email', email);
          formData.append('lang',JSON.stringify(deviceLang));
    
          let uid:any;
          if (localStorage.getItem("FCM_PWA_token") !== '' && localStorage.getItem("FCM_PWA_token") !== null) {
            uid = localStorage.getItem("FCM_PWA_token");
          } else if (localStorage.getItem("FCM_mobile_token") !== '' && localStorage.getItem("FCM_mobile_token") !== null) {
            uid = localStorage.getItem("FCM_mobile_token");
          }
          
    // logowanie - pobieramy JWT
    fetch(config.apiURL+'/mail.php?uid='+uid, {
      method: "POST",
      body: formData
    })
    .then(response => {
      if (!response.ok) {
        consoleLog("response:", response);
        setMessage("Całkiem źle (1)");
        setIserror(true);
        throw new Error('Wrong Network response');
      }
      return response.json();
    })
    .then(json => {
      consoleLog("SEND:", json);
      if (json.error) {
        setHeader("INFO");
        setMessage("Błąd w czasie wysyłki");
        setIserror(true);
      } else {
        if(json.info) {
          setHeader("INFO");
          setMessage("Mail został wysłany");
          setSuccess(true);
          setImageData('');
        }
      }
    })
    .catch(err =>{
      setHeader("INFO");
      setMessage("Całkiem źle (2)");
      setIserror(true);
      consoleLog("Error:",err)

    });

  };
  
  
  /* const onChangeCheckBoxHandler = (e) => {
    this.setState(
      {[e.target.name]: e.target.checked}
    );
  } */

  const onDismiss = () => {
    
    setIserror(false);
    setSuccess(false);
    setConfirm(false);

    if(sharedValues.confirm === true) { 
      
      if (header === 'Wysyłka maila') {
        setHeader("");
        handleReportSend();
      } else if (header === dict[lang]['ustawienia_reset']) {
        
        // reset do ustawień startowych
        const newSharedValues = defaultValues;
        setSharedValues(newSharedValues);
        localStorage.clear();
        localStorage.setItem("sharedValues", JSON.stringify(newSharedValues));

        const getDeviceLang = async () => {
          const device_lang = await Device.getLanguageCode();
          console.log('reset lang',device_lang.value);
          localStorage.setItem('lang',device_lang.value);
        };

        getDeviceLang();

        consoleLog('reset ustawień',confirm);
        
        setHeader(dict[lang]['gotowe']);
        setMessage(dict[lang]['ustawienia_zreset']);
        setSuccess(true);
        
        //alert('stop');
        //window.location.reload();
      }
      
      //consoleLog('Reloading app');
      
    }
  }

  const onRaportClick = () => {
    if (raport_class === 'dn') {
      setRaportClass('dn');
    } else {
      setRaportClass('dn');
    }
  }

  const onResetClick = () => {
    //localStorage.clear();
    setHeader(dict[lang]['ustawienia_reset']);
    setMessage(dict[lang]['kontynuowac']);
    setConfirm(true);
    consoleLog('confirm?',confirm);
  }
  
  //consoleLog('config',config);
  let config_str    = JSON.stringify(config, null, 4);
  let platforms_str = JSON.stringify(platforms, null, 4);

  return (
    <IonPage>
      

      <Alerts 
        header={header}
        message={message} 
        showerror={iserror} 
        confirm={confirm} 
        success={success} 
        dismiss={() => onDismiss()}
      />

      {message === 'send start' &&
        <div className="ion-padding ion-text-center">
          <IonLoading 
            isOpen
            /* message={dict[lang]['aktualizacja']} */
            /* duration={5000} */
          />
        </div>
      }
      

      <IonContent fullscreen className="admin ion-padding-page ion-text-left">
        <form>
          
          {/* <h3>{message}</h3>
          <h3>{header}</h3> */}

          <h1 onClick={() => onRaportClick()}>{dict[lang].problem_zglosz}</h1>
            
          <p>{dict[lang].problem_opis}:</p>
          
          <textarea name="info_to_admin" className="opty info_to_admin" onChange={(e) => setInfoToAdmin(e.target.value)} value={info_to_admin}>{info_to_admin}</textarea>
          
          <p>{dict[lang].twoj_email}:</p>
          <IonInput 
            className="email"
            name="email" 
            type="email"
            placeholder=""
            value={email}
            onIonChange={(e) => setEmail(e.detail.value)}
            clearInput
          ></IonInput>

          {imageData && <img alt="PICURE!" src={"data:image/png;base64, "+imageData} />}

          <IonGrid>
            <IonRow>
              <IonCol size="6" size-sm="6" size-xs ="6">
                <IonButton expand="block" onClick={takePicture}>{dict[lang].zdjecie}</IonButton>
              </IonCol>
              <IonCol size="6" size-sm="6" size-xs ="6">
                <IonButton className="success" expand="block" color="success" onClick={() => {handleSendConfirm()}}>{dict[lang].wyslij}</IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
          
          
          <IonTextarea name="raport" className={raport_class}>
            {getLS()}
            {config_str}
            <p>{platforms_str}</p>
            <p>{deviceInfo.model}</p>
            <p>{deviceInfo.osVersion}</p>
            <p></p>
          </IonTextarea>
         

        </form>
        
        <IonGrid>
          <IonRow>
            <IonCol size="12" size-sm="12" size-xs ="12">
              <IonButton className="danger" color="danger" expand="block" onClick={() => onResetClick()}>Reset ustawień aplikacji</IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>

      </IonContent>
      
    </IonPage>
  );

};

export default Admin;
