import React, { useRef, useEffect, useState, useCallback, useContext, createRef } from 'react';
import {
  GoogleMap,
  useLoadScript,
  useJsApiLoader,
  Marker,
  InfoWindow,
  LoadScript,
  useGoogleMap,
  Polyline,
  
} from "@react-google-maps/api";

import AppContext from '../Context';
import AppQRContext from '../ContextQR';

import { paths, consoleLog, startPoints, pathPoints, pathQA, dict, lang } from './Config';

import { Location } from '../models/Location';
import { Geolocation, Position } from '@capacitor/geolocation';

//import "@reach/combobox/styles.css";
import mapStyles from "../theme/mapStyles";
import mapStyles2 from "../theme/mapStyles2";
import { createGesture, Gesture, IonButton, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonItem, IonItemDivider, IonLabel, IonList, IonListHeader, IonMenu, IonMenuButton, IonMenuToggle, IonModal, IonPage, IonRadio, IonRadioGroup, IonRow, IonTitle, IonToast, IonToolbar, isPlatform } from '@ionic/react';
import { useHistory, useParams } from 'react-router';
import GoogleMapDistance from './GoogleMapDistance';
import { caretBack, caretDownOutline, informationCircle, location, play, playBack, qrCode, scan, text } from 'ionicons/icons';


import Alerts from './Alerts';
import { getElement } from 'ionicons/dist/types/stencil-public-runtime';

declare var google:any;

interface Start {
  id: number,
  lat: number,
  lng: number,
  ico: string
}

interface Mark {
  id: number,
  name: string,
  lat: number,
  lng: number,
  opis: string
}

interface QA {
  id: number,
  name: string,
  opis: string,
  odp: [string]
}

const mapContainerStyle = {
  /* height: "100vh",
  width: "100vw", */
};

const PARK_BOUNDS = {
  north: 52.74396521988936,
  south: 52.73110322339124,
  west: 15.234380912412133,
  east: 15.246869277585473,
};

const options = {
  styles: mapStyles2,
  disableDefaultUI: false,
  zoomControl: true,
  minZoom: 17,
  restriction: {
    latLngBounds: PARK_BOUNDS,
    strictBounds: false,
  },
};

const center = {
  lat:52.73701100046155,
  lng:15.241040486539235
};

interface MapProps {
  locations: Location[]
  mapCenter: Location
}

const ParkMap: React.FC = () => {
  
  const {sharedValues, setSharedValues}   = useContext(AppContext);
  const { sharedQR, setSharedQR }         = useContext(AppQRContext);

  const mapRef                            = useRef();
  const mapInst                           = useRef<google.maps.Map>();
  const myMarker                          = useRef<google.maps.Marker>();
  
  const [mapChange, setMapChange]         = useState(true);
  const [mapCenter, setMapCenter]         = useState({lat:52.73701100046155,lng:15.241040486539235});
  const [iconSize, setIconSize]           = useState<number>(1);
  
  const [selected, setSelected]           = useState<Mark | null>(null);
  const [infoWindowID, setInfoWindowID]   = useState<any>();
  const [loc, setLoc]                     = useState<Position | null>();
  const [watchId, setWatchId]             = useState<string>('');
  const [distance, setDistance]           = useState<boolean>(false);
  const [showModal, setShowModal]         = useState<boolean>(false);
  const [showPlanModal, setShowPlanModal] = useState<boolean>(false);
  const [radioSelected, setRadioSelected] = useState<string | boolean>(false);
  const [showToast, setShowToast]         = useState<boolean>(false);
  const [showToastOnce, setShowToastOnce] = useState<boolean>(false);
  const [distMessage, setDistMessage]     = useState<string>("");

  const [iserror, setIserror]             = useState<boolean>(false);
  const [confirm, setConfirm]             = useState<boolean>(false);
  const [success, setSuccess]             = useState<boolean>(false);
  const [header, setHeader]               = useState<string>("");
  const [message, setMessage]             = useState<string>("");

  const [path, setPath]                   = useState<any>("");
  const [point, setPoint]                 = useState<number>(1);
  const [question, setQuestion]           = useState<number>(0);

  //const map = useGoogleMap();
  //let google:any;
  
  /* TODO: useLoadScript
  */


  //const loadError = '';  // index
  //const isLoaded = true; // index

  let googleMapsApiKey:any;
  if (isPlatform('android')) {
    //googleMapsApiKey = 'AIzaSyC3xdZU-ZlcRgqsC8353uXWYYlBsfcbuFk';
    //googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    googleMapsApiKey = 'AIzaSyANd591grf7nPDQ4te03nPCAKoHtbSvIlg';
  } else if (isPlatform('ios')) {
    googleMapsApiKey = 'AIzaSyANd591grf7nPDQ4te03nPCAKoHtbSvIlg';
  } else if (isPlatform('desktop') || isPlatform('tablet') || isPlatform('pwa')) {
    //googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    googleMapsApiKey = 'AIzaSyANd591grf7nPDQ4te03nPCAKoHtbSvIlg';
  } else {
    //googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    googleMapsApiKey = 'AIzaSyANd591grf7nPDQ4te03nPCAKoHtbSvIlg';
  }
  
  /* const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: googleMapsApiKey // ,
    // ...otherOptions
  }); */
  
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: googleMapsApiKey // ,
    // ...otherOptions
  });
  
  

  let history  = useHistory();
  let params   = useParams<{ path:any | undefined, point:any | undefined, question:any | undefined }>();
  let markers:any;
  let questions:any;
  let markerId:any;
  
  markers   = pathPoints[parseInt(params.path)];
  questions = pathQA[params.path];
  markerId  = params.point;

  useEffect(() => {
    
    /* if (params.path === undefined || params.point === undefined) {
      params = {
        path:'1',
        point:'1',
        question:'0'
      }
      consoleLog('Map params undefined',params);
      markers   = pathPoints[parseInt(params.path)];
      questions = pathQA[params.path];
      markerId  = params.point;
    } */

    setTimeout(() => {
      
      //history.push('/')
    
      if ((/* !mapInst ||  */isLoaded === false) && isPlatform('pwa')) {
        console.log('no map');
        //alert('no map ;(');
        //window.location.reload();
      } else {
        //alert('mapa');
        console.log('map');
      }

    },800);
  },[]);

  //consoleLog('Map params:', params);
  //consoleLog('Map startPoints:', startPoints);

  //let question:any;
  //let markId = point;

  

  //consoleLog('Map 0 path point',path+'|'+point);
  //consoleLog('Map questions',path+'|'+JSON.stringify(questions));
  //consoleLog('Map pathPoints',pathPoints);
  
  const PARK_BOUNDS = {
    north: 52.742640219861045,
    south: 52.73381884980738,
    west:  15.234509658444848,
    east:  15.244551848996606,
  };
  

  const points_path_1 = [
    {lat:52.7362298009943,lng:15.239222634960141, rotation:-59},

    {lat:52.7362783345334, lng:15.240020892380471, rotation:-45},
    {lat:52.736259033669725,lng:15.240448739756074, rotation:-90},
    {lat:52.73667194311672, lng:15.241299670566049, rotation:-185},
    {lat:52.736841289117514, lng:15.241011099849636, rotation:-175},
    {lat:52.73730429193976, lng:15.240557704497304, rotation:-145},
    {lat:52.73753002708276, lng:15.240607995916333, /* rotation:-92 */ rotation:0},
    {lat:52.737683290834326, lng:15.240918796885934, /* rotation:-75 */ rotation:0},
    /* {lat:52.73772734137582,lng:15.2413090582976, rotation:-155}, */
    {lat:52.73798291483739,lng:15.240850400556054, rotation:-155},
    
    {lat:52.73839377800793, lng:15.240571450818505, rotation:-107},
    {lat:52.73879123978091, lng:15.241071012247529, rotation:0},
    {lat:52.73883934905503, lng:15.241246361661878, rotation:-175},
    {lat:52.73923660377407, lng:15.240904380012479, rotation:0},
    {lat:52.7395147002221, lng:15.240575809408154, rotation:-184},
    {lat:52.73972174897998, lng:15.240255285430875, /* rotation:-188 */ rotation:0},
    {lat:52.74004023676683, lng:15.239661511410203, rotation:-174},
    {lat:52.740421850680356, lng:15.239413742352452, rotation:140},
    {lat:52.740546280452094, lng:15.238764312494721, rotation:-48}
  ];
  const points_path_arrows_1 = points_path_1.slice(0,points_path_1.length-1);

  const points_path_2 = [
    {lat:52.74141869977199, lng:15.237252552438703, rotation:1},
    {lat:52.741343799868865, lng:15.237340059507813, rotation:5},
    
    {lat:52.74130137683141, lng:15.237371575463738, /* rotation:12 */ rotation:0},
    {lat:52.741032628974416, lng:15.237463105846372, /* rotation:10 */ rotation:0},
    {lat:52.74094250469967, lng:15.237515073646035, /* rotation:-3 */ rotation:0},
    {lat:52.740920785533525, lng:15.2375358607659, /* rotation:-10 */ rotation:0},
    {lat:52.74086029658382, lng:15.237648513544526, /* rotation:-45 */ rotation:0},
    {lat:52.740856236919775, lng:15.237669300664392, /* rotation:-55 */ rotation:0},
    
    {lat:52.740881812797035, lng:15.238190319765534, /* rotation:-38 */ rotation:0},

    {lat:52.74085014742298, lng:15.238477316130128, /* rotation:-35 */ rotation:0},
    
    {lat:52.74077727637862, lng:15.238818627227273, rotation:42},
    {lat:52.740531462569784, lng:15.238687198985543, /* rotation:90 */ rotation:0}, // Kozacza Góra

    {lat:52.740438333783345, lng:15.238451562270301, rotation:-90},
    {lat:52.740533695401616, lng:15.23868753426167, /* rotation:-83 */ rotation:0},
    {lat:52.74054404762088, lng:15.238743525374856, /* rotation:-43 */ rotation:0},
    {lat:52.74053937897331, lng:15.23880085759255, rotation:-34},
    {lat:52.740417182019705, lng:15.239416424561467, /* rotation:4 */ rotation:0},
    {lat:52.74003475611719, lng:15.239663523066964, rotation:32},

    {lat:52.739534593184814, lng:15.239579704035249, /* rotation:37 */ rotation:0},
    {lat:52.73934906050776, lng:15.23951968960854, /* rotation:59 */ rotation:0},
    {lat:52.73912171085513, lng:15.239263873923745, /* rotation:44 */ rotation:0},
    {lat:52.73894043925558, lng:15.239165302742448, /* rotation:33 */ rotation:0},
    {lat:52.73880240438526, lng:15.239176366854634, /* rotation:10 */ rotation:0},
    {lat:52.73850319200379, lng:15.239373844493356, /* rotation:-16 */ rotation:0},
    {lat:52.7384560973306, lng:15.239483144510713, /* rotation:-32 */ rotation:0},
    {lat:52.738429505057326, lng:15.239622619379487, /* rotation:-46 */ rotation:0},
    {lat:52.73842503917778, lng:15.239750024307694, /* rotation:-85 */ rotation:0},
    {lat:52.73844736857099, lng:15.239854965735402, /* rotation:-46 */ rotation:0},

    {lat:52.73843112901321, lng:15.240507077802148, rotation:7}, // 24

    {lat:52.73815586759291, lng:15.240742776919332, /* rotation:13 */ rotation:0},
    {lat:52.73784893687947, lng:15.240879569579091, /* rotation:28 */ rotation:0},
    {lat:52.737658728070876, lng:15.240876887370076, /* rotation:87 */ rotation:0},
    {lat:52.73751987714651, lng:15.240614030886617, /* rotation:39 */ rotation:0},
    {lat:52.73735605684679, lng:15.240556698668923, /* rotation:36 */ rotation:0},
    
    {lat:52.73729901394611, lng:15.240559045601811, rotation:0},
    {lat:52.736994107211025, lng:15.24085945301148, rotation:0},
    {lat:52.736663417002816, lng:15.241306040812459, rotation:89},
    {lat:52.73625375554961, lng:15.240451757241216, rotation:-23},
    {lat:52.736060088685484, lng:15.241066988934007, rotation:-26},
    {lat:52.73591879659427, lng:15.241638970006433, rotation:62},
    {lat:52.734645929091315, lng:15.240685444701638, rotation:-117},

  ];
  const points_path_arrows_2 = points_path_2.slice(0,points_path_2.length-1);

  const points_path_3 = [
    {lat:52.73748516434601, lng:15.238723408807244, rotation:-82},
    {lat:52.73753388405779, lng:15.238999005783525, /* rotation:-85 */ rotation:0},
    {lat:52.73770582360538, lng:15.239726890254941, rotation:-90},
    {lat:52.73776631693317, lng:15.239861335981812, rotation:0},
    {lat:52.737656089095324, lng:15.240023274351087, rotation:0},
    /* {lat:52.73792445177897, lng:15.240249585736718, rotation:40}, */ //21
    {lat:52.73756778482525, lng:15.240192253519025, rotation:-11},
    {lat:52.7374601954728, lng:15.240395095575776, /* rotation:-25 */ rotation:0},
    {lat:52.737383664771585, lng:15.240641188252892, rotation:55}, //E
    {lat:52.73729962294526, lng:15.240559716154065, rotation:0},
    {lat:52.73710697573162, lng:15.240728695322003, rotation:0},
    {lat:52.73699512221689, lng:15.240860123563733, rotation:-73},
    {lat:52.73711509575764, lng:15.241912890602078, /* rotation:-67 */ rotation:0},
    {lat:52.737129102799294, lng:15.242207263041463, /* rotation:-56} */ rotation:0},
    {lat:52.73711448675577, lng:15.242397029329267, rotation:30},
    {lat:52.737027196393676, lng:15.24238730632158, /* rotation:39 */ rotation:0},
    {lat:52.73694936952881, lng:15.242353326282291, /* rotation:70 */ rotation:0}, //C
    {lat:52.73692122971924, lng:15.242306169498887, rotation:60},
    {lat:52.736658138931, lng:15.24206443541142, /* rotation:80 */ rotation:0},
    {lat:52.73661124526729, lng:15.24198531024548, rotation:90},
    {lat:52.736476451078545, lng:15.241554145146337, /* rotation:80 */ rotation:0},
    {lat:52.736424482123375, lng:15.241471667219129, /* rotation:55 */ rotation:0},
    {lat:52.73637271611032, lng:15.241442498196092, /* rotation:35 */ rotation:0},
    {lat:52.736301461615355, lng:15.241447862614121, /* rotation:10 */ rotation:0},
    {lat:52.73622411686076, lng:15.24148541354033, rotation:0},
    {lat:52.736111043048695, lng:15.24163494669291, rotation:20},
    {lat:52.736043645233025, lng:15.241648693014112, /* rotation:25 */ rotation:0},
    {lat:52.735922247699754, lng:15.241633270312276, rotation:-20},
    {lat:52.73580003780138, lng:15.242011126507249, rotation:40},
    {lat:52.73571315082139, lng:15.24198966883513, /* rotation:45 */ rotation:0},
    {lat:52.73557672979312, lng:15.241889756549325, /* rotation:80 */ rotation:0},

    {lat:52.7355253687892, lng:15.241799231995072, rotation:88}, //3 
    {lat:52.735426897677826, lng:15.241577941887522, rotation:88}, //2

    {lat:52.735200757725714, lng:15.241161536801782, /* rotation:78 */ rotation:0},
    {lat:52.73505702672653, lng:15.24096841775271, rotation:1},
    {lat:52.734774029560505, lng:15.24121182822081, /* rotation:8 */ rotation:0},
    {lat:52.73458664950778, lng:15.241333198178735, /* rotation:30 */ rotation:0},
    {lat:52.73445875150118, lng:15.241327498484578, rotation:90},
    {lat:52.73443682609097, lng:15.241246696938004, /* rotation:90 */ rotation:0},
    {lat:52.73429654380708, lng:15.240944613147702, rotation:200},
    {lat:52.73431704819225, lng:15.240938913453546, /* rotation:180 */ rotation:0},
    {lat:52.7343767341694, lng:15.240874875713315, /* rotation:-160 */ rotation:0},
    {lat:52.734563912111895, lng:15.240844030309644, rotation:30},
  ];
  const points_path_arrows_3 = points_path_3.slice(0,points_path_3.length-1);

  const points_path_1_opt = {
    strokeColor:	 '#009900',
    strokeWeight:	 8,
    strokeOpacity: 0.7
  }

  const points_path_2_opt = {
    strokeColor:	 '#663300',
    strokeWeight:	 8,
    strokeOpacity: 0.7
  }

  const points_path_3_opt = {
    strokeColor:	 '#0066ff',
    strokeWeight:	 8,
    strokeOpacity: 0.7
  }

  const drawerRef    = useRef<any>();
  const drawerBtnRef = useRef<any>();

  //let c:any = drawerRef.current!;
    
  
  //const drawerRef = React.createRef<any>();

  // when the page is loaded, we find the element that is the drawer
  // and attach the gesture to it's reference using react `useRef` hook

  /* TODO: toggleDrawer
  */
  useEffect(() => {

    let c:any = drawerRef.current!;
    let b:any = drawerBtnRef.current!;
    
    /* const styles = c.style;
    const styles2:any = window.getComputedStyle(c);

    console.log(styles);
    console.log(styles2); */
    
    const styles2:any = window.getComputedStyle(c);

    const gesture:Gesture = createGesture({
      
      el: c,
      gestureName: "my-swipe",
      direction: "y",
      
      /**
       * when moving, we start to show more / less of the drawer
       */

      onMove: event => {
        
        //if (event.deltaY < -300) return;
        //if (event.deltaY < 2) return;

        // closing with a downward swipe
        if (event.deltaY > 5) {
          c.style.transform = `translateY(${(parseInt(styles2.height)+0)}px) `;
          c.dataset.open = "false";
          b.classList.add("close");
          return;
        }

        // opening with a upward swipe
        if (event.deltaY < -5) {
          c.style.transform = ` `;
          c.dataset.open = "true";
          b.classList.remove("close");
          return;
        }

        c!.style.transform = `translateY(${event.deltaY}px)`;
      },
      /**
       * when the moving is done, based on a specific delta in the movement; in this
       * case that value is -150, we determing the user wants to open the drawer.
       *
       * if not we just reset the drawer state to closed
       */
      onEnd: event => {
        c.style.transition = ".5s ease-out";

        if (event.deltaY < -2 && c.dataset.open !== "true") {
          c.style.transform = '';
          //c.style = '';
          c.dataset.open = "true";
          console.log("in on end");
          b.classList.remove("close");
        }

      }
    });

    // enable the gesture for the item
    gesture.enable();

  }, []);

  /**
   * this function is called when the button on the top of the drawer
   * is clicked.  We are using the data-set attributes on the element
   * to determine the state of the drawer.
   *
   * this could be done using react state if you like.
   */
  const toggleDrawer = () => {
    
    let b:any = drawerBtnRef.current!;
    let c:any = drawerRef.current!;

    const styles = c.style;
    const styles2:any = window.getComputedStyle(c);

    //console.log(styles);
    //consoleLog(styles2.height, styles2.top);

    if (c.dataset.open === "false") {
      
      c.style.transition = ".5s ease-out";
      c.style.transform = "";
      c.dataset.open = "true";
      b.classList.remove("close");
    } else {
      c.style.transition = ".5s ease-in";
      c.style.transform = `translateY(${(parseInt(styles2.height)+5)}px) `;
      c.dataset.open = "false";
      b.classList.add("close");
    }
  };


  const setStateParams = () => {
    if (params.path === undefined || params.point === undefined) {
      params = {
        path:'1',
        point:'1',
        question:'0'
      }
      console.log('Map params undefined',params);
      markers   = pathPoints[parseInt(params.path)];
      questions = pathQA[params.path];
      markerId  = params.point;
      console.log('Map markers',markers);
    }
    setPath(params.path);
    setPoint(parseInt(params.point));
    setQuestion(parseInt(params.question) | 0);
  }

  useEffect(() => {
    //consoleLog('Map props:',params.path+'|'+params.point+'|'+params.question);

    setStateParams();
    
    const newSharedValues = {
      ...sharedValues,
      path: params.path,
      point: parseInt(params.point),
      question: parseInt(params.question) | 0
    }
    setSharedValues(newSharedValues);
    
    /* if(isLoaded && (markerId === undefined || markerId === 0)) {
      consoleLog('Map markerId:',markerId);
      setSelected(null);
      getCurrentPosition();
      setDistance(true);
      showMarker(markerId);
    } */

  },[]);

  
  useEffect(() => {

    setStateParams();
    
    consoleLog('Map params:',params);
    consoleLog('Map point 1:',point);
    consoleLog('Map markers:',markers);
    

    const newSharedValues = {
      ...sharedValues,
      path: params.path,
      point: parseInt(params.point),
      question: parseInt(params.question) | 0
    }
    setSharedValues(newSharedValues);

    showMarker(markers[point-1]);
    setSelected(markers[point-1]);
    //consoleLog('Map marker selected',selected);

  },[params]);

  useEffect(() => {

    //setStateParams();
    const newSharedValues = {
      ...sharedValues,
      path: path,
      point: point
    }
    setSharedValues(newSharedValues);
    
    if(mapInst.current/*  && point */) {
      //consoleLog('Map point 2',sharedValues);
      mapInst.current!.panTo(new window.google.maps.LatLng(markers[point-1].lat,markers[point-1].lng));
    }
    //consoleLog('Map useEffect:','point:'+point);
    setSelected(markers[point-1]);

  },[point]);
 
  
  useEffect(() => {
    if (sharedValues.geoloc === true) {
       startTracking();
       //console.log('useEffect geoloc true');
    } else {
       stopTracking();

       const newSharedValues = {
        ...sharedValues,
        distanceInfo: ''
      }
      setSharedValues(newSharedValues);

       //console.log('useEffect geoloc false');
    }
    
  },[sharedValues.geoloc]);

  useEffect(() => {

    if (sharedValues.distanceInfo !== '') {

      switch (lang) {
        case "pl":
          setDistMessage("<p>Jesteś ok. "+sharedValues.distanceInfo?.rows[0]?.elements[0]?.distance?.text! +" od punktu startowego wybranej ścieżki ("+paths[path]+") w Parku Siemiradzkiego. Piechotą dojdziesz tam w " +  sharedValues.distanceInfo?.rows[0]?.elements[0]?.duration?.text! +".</p>")
        break;
        case "en":
          setDistMessage("<p>You are ca. "+sharedValues.distanceInfo?.rows[0]?.elements[0]?.distance?.text! +" from the start point of the choosen trail  ("+paths[path]+") in Park Siemiradzkiego. On foot you will get there in " +  sharedValues.distanceInfo?.rows[0]?.elements[0]?.duration?.text! +".</p>");
        break;
        case "de":
          setDistMessage("<p>DE Jesteś ok. "+sharedValues.distanceInfo?.rows[0]?.elements[0]?.distance?.text! +" od punktu startowego wybranej ścieżki ("+paths[path]+") w Parku Siemiradzkiego. Piechotą dojdziesz tam w " +  sharedValues.distanceInfo?.rows[0]?.elements[0]?.duration?.text! +".</p>");
        break;
      }

      if (distMessage !== '' && distMessage !== undefined) {
        setShowToast(true);
      }

    } else {
      setShowToast(false);
    }
  },[sharedValues.distanceInfo,distMessage]);


  const onDismiss = () => {
    setIserror(false);
    //setSuccess(false);
    //setConfirm(false);

    // odpowiedź na pytanie
    if(success === true) { 
      setSuccess(false);
      setShowModal(false);
    }

    // koniec gry
    if (confirm === true && header === dict[lang]['zakonczyc_gre']) {
      setConfirm(false);
      if (sharedValues.confirm === true) {
        const newSharedValues = {
          ...sharedValues,
          game: '',
          question: 0
        }
        setSharedValues(newSharedValues);
        history.push('/results');
      }
    }
  }

  /* TODO: onMapLoad
   */
  const onMapLoad = useCallback((map) => {
    
    //consoleLog('Map mapa', map+'|'+params.path);

    mapRef.current  = map;
    mapInst.current = map;
    

  }, []);

  const handleMapChangeEnd = () => {
    consoleLog('onMapChange',mapChange);
    setMapChange(true);
  }
  /* TODO: onMapZoomChanged
   */
  const onMapZoomChanged = () => {
    
    /* let map = new window.google.maps.Map(document.getElementById('parkMap') as HTMLElement);
    google.maps.event.addListener(map, 'zoom_changed', function() {
      let zoomLevel = map.getZoom();
      consoleLog('zoom level:', zoomLevel);
    }); */
    if (mapInst.current) {
      //consoleLog('Map zoom:',mapInst.current!.getZoom());
      let zoomLevel = mapInst.current!.getZoom();
      //consoleLog('Map zoomLevel', zoomLevel+'|'+markers[point-1]);
     // setSelected(markers[point-1]); // nie zadziała current loc
      if (zoomLevel! <= 14) {
        setIconSize(0.5);
      } else if (zoomLevel! <= 17) {
        setIconSize(0.8);
      } else {
        setIconSize(1.5);
      }
    }

  }
   
  const showParkMapInfoWindow = () => {
    //let newSharedValues;
    if (sharedValues.showParkMapInfoWindow === 'true') {
      const newSharedValues = {
        ...sharedValues,
        showParkMapInfoWindow: 'false'
      }
      setSharedValues(newSharedValues);
    } else {
      const newSharedValues = {
        ...sharedValues,
        showParkMapInfoWindow: 'true'
      }
      setSharedValues(newSharedValues);
    }
    //console.log('showInfoWindow function fired');
    
  }

  const getCurrentDistance = () => {
    setDistance(true);
  }
  
  const startTracking = (async () => {
    if (watchId !== '') {
      return
    }

    //console.log('start tracking');
    setDistance(true);
    setSelected(null);
    
    
    const watch = await Geolocation.watchPosition({
      enableHighAccuracy: true,
      maximumAge: 3000,
      timeout: 10000
    }, result => {
      //console.log('startTracking - coords 1:',result);
      //console.log('startTracking - watch:',watch);
      setWatchId(watch);
      setLoc(result);

      /* new window.google.maps.Marker({
        position: new window.google.maps.LatLng(result!.coords.latitude, result!.coords.longitude),
        map: map.current!,
        title: 'new'
      }); */

      if (result !== null && result!.coords.latitude) {
        
        if(mapInst.current) {
          mapInst.current!.panTo(new window.google.maps.LatLng(result!.coords!.latitude,result!.coords!.longitude));
        }

        if(myMarker.current) {
          myMarker.current!.setPosition(new window.google.maps.LatLng(result!.coords!.latitude,result!.coords!.longitude));
        } else {
          myMarker.current = new window.google.maps.Marker({
            position: new window.google.maps.LatLng(result!.coords!.latitude,result!.coords!.longitude),
            map: mapRef.current!,
            title: 'MyMarker',
            animation: google.maps.Animation.DROP,
            icon: {
              /* url: '/assets/icon/park_duck.png',
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(48, 48), */
              url: '/assets/icon/my_position.png',
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(48, 48),
              scaledSize: new window.google.maps.Size(96, 96),
            }
          });
        }
      }
      
    });

    /* const newSharedValues = {
      ...sharedValues,
      point: '0'
    }
    setSharedValues(newSharedValues); */
    
  });

  const stopTracking = (() => {
    setDistance(false);
    if (watchId !== null && watchId !== '') {
      Geolocation.clearWatch({id: watchId});
      //console.log('stop tracking:',watchId);
      setWatchId('');
    }
  });



  const getCurrentPosition = useCallback(async () => {
    
    //consoleLog('Map getCurrentPosition', 'start');

    try {
      const coordinates = await Geolocation.getCurrentPosition({
        enableHighAccuracy: true,
        maximumAge: 3000,
        timeout: 10000
      });
      
      setLoc(coordinates);
      setSelected(null);
      setShowToast(true);

      consoleLog('Map getCurrentPosition - current position',coordinates);
      //console.log('Map getCurrentPosition - myMarker',myMarker.current);

      if (myMarker.current === undefined) {
        //myMarker.current!.setMap(null); // remove
        myMarker.current = new window.google.maps.Marker({
          position: new window.google.maps.LatLng(coordinates.coords.latitude, coordinates.coords.longitude),
          map: mapRef.current!,
          title: 'MyMarker',
          animation: google.maps.Animation.DROP,
          icon: {
            url: '/assets/icon/park_duck.png',
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(15, 15),
            scaledSize: new window.google.maps.Size(48, 48),
          }
        });

        //console.log('getCurrentPosition - undefined MyMarker');

        if(mapInst.current) {
          mapInst.current!.setZoom(18);
          mapInst.current!.panTo(new window.google.maps.LatLng(coordinates.coords.latitude,coordinates.coords.longitude));
        }

      } else {
        
        if(mapInst.current) {
          mapInst.current!.setZoom(18);
          mapInst.current!.panTo(new window.google.maps.LatLng(coordinates.coords.latitude,coordinates.coords.longitude));
        }
        if(myMarker.current) {
          myMarker.current!.setVisible(true);
          myMarker.current!.setPosition(new window.google.maps.LatLng(coordinates.coords.latitude,coordinates.coords.longitude));
        }

      }

    } catch(error) {
      consoleLog('Map getCurrentPosition error:', error);
    }
  }, []);

  function setMapOnAll(map: google.maps.Map | null) {
    for (let i = 0; i < markers.length; i++) {
      markers[i].setMap(map);
    }
  }
  /* TODO: showMarker
   */
  const showMarker = (id:any) => {

    let thisMarker:any;
    
    //setThisMarker(undefined);
  
    if (id.markerId) {
      let idInt =  parseInt(id.markerId);
      thisMarker = markers.find((marker:Mark) => marker.id === idInt);
      //setThisMarker(markers.find((marker:Mark) => marker.id === idInt));
    } else {
      thisMarker = markers.find((marker:Mark) => marker.id === id);
      //setThisMarker(markers.find((marker:Mark) => marker.id === id));
    }

    /* let currMarker = new window.google.maps.Marker({
      position: new window.google.maps.LatLng(thisMarker.lat,thisMarker.lng),
      map: mapRef.current!,
      title: 'Current Marker',
      animation: google.maps.Animation.DROP,
      icon: {
        url: '/assets/icon/park_duck.png',
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(15, 15),
        scaledSize: new window.google.maps.Size(48, 48),
      }
    }); */

    //currMarker.setMap(null);
    //consoleLog('Map showMarker - thisMarker',thisMarker);
    //console.log('thisMarkerId',id);
    //console.log('id.markerId',id.markerId);
    
    

    if(thisMarker === undefined) {
      //consoleLog('Map showMarker - undefined marker','');
      return;
    } else {
      //consoleLog('Map showMarker - setSelected',mapInst.current);
      //markerId.current = undefined;

      setSelected(thisMarker);
      
      setMapCenter({
        lat:thisMarker.ltd,
        lng:thisMarker.lng
      });
      consoleLog('Map markers',markers);

      
      //setMapOnAll(null);
      

      for (let i = 0; i < markers.length; i++) {
        //googleMarkers[i].setMap(null);
      }
          
      if (point === parseInt(markers[thisMarker.id-1].id)) {

        /* markers[thisMarker.id-1].setIcon({
          
            url: '/assets/icon/park_duck.pnp',
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(15, 15),
            scaledSize: new window.google.maps.Size(30, 30),
          
        }) */
        //currMarker.setMap(null);
        //currMarker.setVisible(true);
        //googleMarkers[thisMarker.id-1].setVisible(true);
      } else {
        //currMarker.setMap(null);
      }

      if(mapInst.current) {
        mapInst.current!.setZoom(16);
        mapInst.current!.panTo(new window.google.maps.LatLng(thisMarker.lat,thisMarker.lng));
      }
      
    }
  };

  //const map = mapInst;
  /* 
  useEffect(() => {
    if (mapInst) {
      mapInst.current!.panTo(new window.google.maps.LatLng(markers[markerId].lat,markers[markerId].lng));
    }
  }, [mapInst]); */
        
  useEffect(() => {
    if(isLoaded && (markerId === undefined || markerId === 0)) {
      //consoleLog('Map markerId:',markerId);
      setSelected(null);
      getCurrentPosition();
      setDistance(true);
      showMarker(markerId);
    }
  },[]);

  useEffect(() => {
    //consoleLog('Map useEffect 1: start 1',loc);
    if(isLoaded) {
      //consoleLog('Map useEffect 1:',path+'-'+markerId);
      //setSelected(null);
      setSelected(markers[point-1]);
      //getCurrentPosition();
      //setDistance(true);
      showMarker(point);
    }
    //console.log('marker start');
  },[markerId,isLoaded,path,point,question,window.location.pathname]);

  useEffect(() => {
    //consoleLog('Map useEffect 2: start 1',loc);
    if(isLoaded) {
      //consoleLog('Map useEffect 2:','start 2');
      //setSelected(null);
      //getCurrentPosition();
      setDistance(true);
      //stopTracking();
      //showMarker({markerId});
    }
    //console.log('marker start');
  },[loc]);
  
  /* TODO: addMarker
   */
  function addMarker(map:any) {
    
    let mk:any;
    mk = new window.google.maps.Marker({
      position: new window.google.maps.LatLng(52.73704100046255,15.241440486539335),
      map: map,
      title: 'MyMarker',
      animation: google.maps.Animation.DROP,
      draggable:true,
      icon: {
        /* url: '/assets/icon/park_duck.png',
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(15, 15),
        scaledSize: new window.google.maps.Size(48, 48), */

        url: '/assets/icon/my_position.png',
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(48, 48),
        scaledSize: new window.google.maps.Size(96, 96),

      }
    })

    /* google.maps.event.addListener(mk, 'click', function() {
      consoleLog('Map pos', 'lat:'+mk.getPosition().lat()+',lng:'+mk.getPosition().lng());
    });   
    google.maps.event.addListener(mk, 'dragstart', function() {
      consoleLog('pos', 'lat:'+mk.getPosition().lat()+',lng:'+mk.getPosition().lng());
    });
    google.maps.event.addListener(mk, 'drag', function() {
      consoleLog('pos', 'lat:'+mk.getPosition().lat()+',lng:'+mk.getPosition().lng());
    }); */

    google.maps.event.addListener(mk, 'dragend', function() {
      consoleLog('Map pos', 'lat:'+mk.getPosition().lat()+',lng:'+mk.getPosition().lng());
    });
  }

  /*  className={sharedQR.scanQR ? "scan" : ""} */
  
  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return (
    <IonPage>
    <div id="parkMapCont">
      
      {/* {sharedValues.intro === true &&
        <Intro />
      } */}

      {/* <Locate panTo={panTo} /> */}
      {/* mapId='1f0b3fdfa9cdb9e4' */}

      {/* <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}> */}

      {/* <LoadScript googleMapsApiKey='AIzaSyANd591grf7nPDQ4te03nPCAKoHtbSvIlg'> */}
      
      
      {/* FIXME: GoogleMap
        */}

      {isLoaded &&
        <GoogleMap
          id="parkMap"
          mapContainerStyle={mapContainerStyle}
          zoom={17}
          center={center}
          options={options}
          onLoad={onMapLoad}
          onDragStart={() => setMapChange(false)}
          onCenterChanged={() => setMapChange(false)}
          onZoomChanged={() => {
            setMapChange(false);
            onMapZoomChanged();
          }}
          onDragEnd={handleMapChangeEnd}
          onIdle={() => {
            handleMapChangeEnd();
          }}
          onClick={() => {
            //addMarker(mapRef.current); // lokalizacja punktów
            setSelected(null);
            toggleDrawer();
          }}
        >
          {/* {isLoaded && mapChange === true && startPoints.map((start:Start) => (
            <Marker
              key={`${start.lat}-${start.lng}`}
              zIndex={3}
              position={{ lat: start.lat, lng: start.lng }}
              //onLoad={() => {console.log('start '+start.id+ ' loaded!')}}
              icon={{
                url: start.ico,
                anchor: new window.google.maps.Point(10, 10),
              }}
            />
          ))} */}

          {/* {isLoaded && markers.map((duck:Mark) => (
            <Marker
              key={`${duck.lat}--${duck.lng}`}
              position={{ lat: duck.lat, lng: duck.lng }}
              //onLoad={() => {console.log('start '+start.id+ ' loaded!')}}
              icon={{
                url: '/assets/icon/park_duck.png',
              }}
            />
          ))}  */}


          {mapChange === true && params.path === '1' &&
            <Polyline
              path={points_path_1}
              options={points_path_1_opt}
            />
          }
          {mapChange === true && params.path === '2' &&
            <Polyline
              path={points_path_2}
              options={points_path_2_opt}
            />
          }
          {mapChange === true && params.path === '3' &&
            <Polyline
              path={points_path_3}
              options={points_path_3_opt}
            />
          }

          

          {params.path === '1' && mapChange === true && 
           points_path_arrows_1.map((arrow:any) => {  
            
            if (arrow.rotation !== 0) {
              return (
                <Marker
                  key={`${arrow.lat}---${arrow.lng}`}
                  position={{ lat: arrow.lat, lng: arrow.lng }}
                  zIndex={1}
                  icon={{
                    path: "M23.386,30.262l-6.003-1.26l-1.787-2.738l4.402,0.924l-3.476-5.328l1.938-1.264l3.477,5.327l0.927-4.401 l1.786,2.738L23.386,30.262z M13.718,15.488c-0.615,0.401-0.788,1.225-0.387,1.84c0.401,0.616,1.225,0.789,1.84,0.387 c0.615-0.401,0.788-1.225,0.387-1.84C15.157,15.26,14.333,15.086,13.718,15.488z",
                    /* fillColor: '#009900', */
                    fillColor: '#cc0000',
                    fillOpacity: 1.0,
                    anchor: new google.maps.Point(15,17),
                    strokeWeight: 0,
                    scale: iconSize,
                    rotation: arrow.rotation
                  }}
                />
              );
            }
          })}

          {params.path === '2' && mapChange === true && points_path_arrows_2.map((arrow:any) => {
            if (arrow.rotation !== 0) {
              return (
                <Marker
                  key={`${arrow.lat}---${arrow.lng}`}
                  position={{ lat: arrow.lat, lng: arrow.lng }}
                  zIndex={1}
                  icon={{
                    path: "M23.386,30.262l-6.003-1.26l-1.787-2.738l4.402,0.924l-3.476-5.328l1.938-1.264l3.477,5.327l0.927-4.401 l1.786,2.738L23.386,30.262z M13.718,15.488c-0.615,0.401-0.788,1.225-0.387,1.84c0.401,0.616,1.225,0.789,1.84,0.387 c0.615-0.401,0.788-1.225,0.387-1.84C15.157,15.26,14.333,15.086,13.718,15.488z",
                    fillColor: '#cc0000',
                    fillOpacity: 1.0,
                    anchor: new google.maps.Point(15,17),
                    strokeWeight: 0,
                    scale: iconSize,
                    rotation: arrow.rotation
                  }}
                />
              )
            }
          })}

          {/* {isLoaded && params.path === '2' && mapChange === true && points_path_arrows_2.map((arrow:any) => (
            <Marker
              key={`${arrow.lat}---${arrow.lng}`}
              position={{ lat: arrow.lat, lng: arrow.lng }}
              zIndex={1}
              icon={{
                path: "M23.386,30.262l-6.003-1.26l-1.787-2.738l4.402,0.924l-3.476-5.328l1.938-1.264l3.477,5.327l0.927-4.401                l1.786,2.738L23.386,30.262z M13.718,15.488c-0.615,0.401-0.788,1.225-0.387,1.84c0.401,0.616,1.225,0.789,1.84,0.387                c0.615-0.401,0.788-1.225,0.387-1.84C15.157,15.26,14.333,15.086,13.718,15.488z",
                fillColor: '#cc0000',
                fillOpacity: 1.0,
                anchor: new google.maps.Point(15,17),
                strokeWeight: 0,
                scale: iconSize,
                rotation: arrow.rotation
              }}
            />
          ))} */}

          {params.path === '3' && mapChange === true && points_path_arrows_3.map((arrow:any) => {
            if (arrow.rotation !== 0) {
              return (
                <Marker
                  key={`${arrow.lat}---${arrow.lng}`}
                  position={{ lat: arrow.lat, lng: arrow.lng }}
                  zIndex={1}
                  //onLoad={() => {console.log('start '+start.id+ ' loaded!')}}
                  icon={{
                    path: "M23.386,30.262l-6.003-1.26l-1.787-2.738l4.402,0.924l-3.476-5.328l1.938-1.264l3.477,5.327l0.927-4.401 l1.786,2.738L23.386,30.262z M13.718,15.488c-0.615,0.401-0.788,1.225-0.387,1.84c0.401,0.616,1.225,0.789,1.84,0.387 c0.615-0.401,0.788-1.225,0.387-1.84C15.157,15.26,14.333,15.086,13.718,15.488z",
                    fillColor: '#cc0000',
                    fillOpacity: 1.0,
                    anchor: new google.maps.Point(15,17),
                    strokeWeight: 0,
                    scale: iconSize,
                    rotation: arrow.rotation
                  }}
                />
              )
            }
          })}

          

          {mapChange === true && markers.map((marker:Mark) => (
            
            <Marker
              key={`${marker.lat}-${marker.lng}`}
              position={{ lat: marker.lat, lng: marker.lng }}
              label={{
                text: ' ',
                /* text: marker.opis ,*/
                fontFamily: "Arial",
                fontSize: "14px",
                fontWeight: "bold",
              }}
              zIndex={2}
              onClick={() => {
                setSelected(marker);
                mapInst.current!.panTo(new window.google.maps.LatLng(marker.lat,marker.lng));
              }}

              onLoad={() => {
                //consoleLog('Map marker:',marker.id+ ' loaded!');
                if(marker.id === point) {
                  //markers.setIcon({url:'/assets/icon/onebit_26.png'});
                  //consoleLog('Map current marker',marker);
                  //markers[marker.id].setIcon({url:'/assets/icon/park_duck.png'});
                }
              }}
              
              icon={{
                /* url: '/assets/icon/onebit_26.png', 
                anchor: new window.google.maps.Point(15, 15),*/
                url: '/assets/icon/ico_red_32x44.png',
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(16, 44), // 16, 26
                scaledSize: new window.google.maps.Size(32, 44),
                labelOrigin: new window.google.maps.Point(10,-10),
              }}
            />
            
          ))}

          {mapChange === true && markers[point-1].id === point &&
            <Marker
              key={`${markers[point-1].lat}--${markers[point-1].lng}`}
              position={{ lat: markers[point-1].lat, lng: markers[point-1].lng }}
              zIndex={0}
              onLoad={() => {
                //console.log('MAP Marker '+ markers[point-1].id + ' start loading!');
                setInfoWindowID(point-1);
                //consoleLog('Map infoWindowID',infoWindowID);
              }}
              icon={{
                url: '/assets/icon/current_point.png',
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(12, 15),
              }}
            />
          }

          {/* TODO: InfoWindow 
          */}
          {selected ? (
            <InfoWindow
              position={{ lat: selected.lat, lng: selected.lng }}
              onCloseClick={() => {
                setSelected(null);
              }}
              onUnmount={() => {
                //alert('dismiss');
              }}
              /* onContentChanged={() => {
                
              }}
              onLoad={() => {
                consoleLog('Map markers',markers);
                consoleLog('Map selected marker',markers[selected.id]);
                
              }} */
            
            >
              <div className="info_window">

                <p>{paths[path]} {/* | {path} */}</p>

                <h2>
                  {selected.name}
                  {/* ({selected.id} | {markId}) */}
                  {/* {sharedValues.answers[markId]} */}
                </h2>

                <p>{selected.opis}{/* {selected.id} */}</p>

                {((!sharedValues.answers[params.path][point]
                  && sharedValues.game === 'start'
                  && sharedValues.gamePath === params.path
                  && sharedValues.path === params.path
                  && point === question)
                  || sharedValues.debug === true) &&
                  <IonButton 
                    expand="block"
                    onClick={() => {
                      const newSharedValues = {
                        ...sharedValues,
                        point: selected.id
                      }
                      setSharedValues(newSharedValues);

                      setPoint(selected.id);
                      setShowModal(true);
                      setRadioSelected(sharedValues.answers[path][point]);

                      if (document.fullscreenElement) {
                        document.exitFullscreen();
                      }
                      
                    }}>{dict[lang]['pytanie']}</IonButton>
                }

                {((sharedValues.answers[path][point] === undefined && sharedValues.game === 'start'
                 && (question === NaN || question !== point))
                  || sharedValues.debug === true) &&
                  <IonButton className="pointScanBtn" expand="block" routerLink="/QR" slot="start"
                    onClick={() => {
                      const newSharedQR = {
                        ...sharedQR,
                        scanQR: true
                      }
                      setSharedQR(newSharedQR);
                    }}>
                    {dict[lang]['skanuj_QR']} &nbsp;
                    <IonIcon icon={qrCode} size="large"></IonIcon>
                  </IonButton>
                  
                }

                {/* {consoleLog('sharedValues.answers[path][point] | {question',sharedValues.answers[path][point]+'|'+{question})} */}
                
                {sharedValues.debug === true &&
                  <p className="info_window_debug">{sharedValues.answers[path][point]}|{question}</p>
                }
                
              </div>

            </InfoWindow>
          ) : null}

          
      
          <IonButton id="path_point_quest" slot="start">
            {paths[path]} 
            
            {sharedValues.debug === true &&
              <>
              ({path}|{point}|{question}) {markers.length}
              </>
            }
          </IonButton>

          <IonImg id="compass" src="/assets/1522086783.svg" />
          

        </GoogleMap>
      }

      {distance === true && loc &&
        <GoogleMapDistance currLat={loc?.coords.latitude} currLng={loc?.coords.longitude} />
      }

      {sharedValues.debug === true &&
        <IonButton id="btn_park_map_info" slot="icon-only" onClick={() => showParkMapInfoWindow()}>
        <IonIcon icon={informationCircle}></IonIcon>
        </IonButton>
      }

      <IonButton id="btn_park_plan" onClick={() => setShowPlanModal(true)}>
        plan
      </IonButton>


      <IonGrid id="footerBtn" className="bottom-drawer" ref={drawerRef} data-open="true" style={{transition: "all 0.5s ease-out 0s"}}>



      {/* TODO: footerBtnToggle
      */}

      <IonButton 
        id="footerBtnToggle" 
        ref={drawerBtnRef}
        color="success"
        /* slot='icon-only' */
        /* style={{ height: 10 }} */
        onClick={toggleDrawer}
        ><IonIcon icon={caretDownOutline}></IonIcon>
      </IonButton>

      <IonRow>
        {(sharedValues.path === sharedValues.gamePath && point > 1) &&
          <IonCol size="6" size-sm="6" size-xs ="6">
            <IonButton expand="block" 
              onClick = {() => {
                
                mapInst.current!.panTo(new window.google.maps.LatLng(markers[point-1].lat,markers[point-1].lng));
                
                if (point > 1) {
                  setPoint(point - 1);
                }
              }}
            >
              <IonIcon slot="start" icon={play} className="prev" />
              {dict[lang]['poprzedni']}
            </IonButton>
          </IonCol>
        }
        
        {/* {consoleLog('Map point 3', sharedValues.point)}
        {consoleLog('Map markers.length', markers.length)} */}

        {/* {((sharedValues.path === sharedValues.gamePath 
        && sharedValues.point < markers.length)
        || (sharedValues.path === sharedValues.gamePath 
        && sharedValues.point < markers.length && sharedValues.debug === true)) &&  */}
        {((path === sharedValues.gamePath 
        && point < markers.length)
        || (path === sharedValues.gamePath 
        && point < markers.length && sharedValues.debug === true)) && 
          <IonCol size="6" size-sm="6" size-xs ="6">
            <IonButton expand="block" 
              onClick = {() => {
                
                mapInst.current!.panTo(new window.google.maps.LatLng(markers[point-1].lat,markers[point-1].lng));

                if (point < markers.length) {
                  setPoint(point+1);
                }
              }}
            >
              <IonIcon slot="end" icon={play} />
              {point === 0 &&
                <>pierwszy punkt</>
              }
              {point > 0 &&
                <>{dict[lang]['nastepny']}</>
              }
              {consoleLog('dict', dict)}
            </IonButton>
          </IonCol>
        }

      </IonRow>

      <IonRow className="location_close">
        <IonCol size="6" size-sm="6" size-xs ="6">
          <IonButton className={sharedValues.geoloc === true ? 'geolocOn' : ''} onClick={() => {
              
              /* stopTracking(); 
              getCurrentPosition();
              const newSharedValues = {
                ...sharedValues,
                intro: false
              }
              setSharedValues(newSharedValues); */
              
              const newSharedValues = {
                ...sharedValues,
                geoloc: !sharedValues.geoloc,
                distanceInfo: ''
              }
              setSharedValues(newSharedValues);
              //setShowToast(true);
              setShowToastOnce(true);
              
            }} expand="block"
            
            >
          <IonIcon slot="start" icon={location} />
          {dict[lang]['gdzie_jestem']}
          </IonButton>
        </IonCol>
        
        {sharedValues.game !== 'start' &&
          <IonCol size="6" size-sm="6" size-xs ="6">
            <IonButton className="danger" color="danger" onClick={() => {
                const newSharedValues = {
                  ...sharedValues,
                  game: 'start',
                  gamePath: path,
                  answers: {
                    ...sharedValues.answers,
                    [path]:{}
                  },
                  point: 1
                }
                setSharedValues(newSharedValues);
                //consoleLog('Map sV',sharedValues);
                /* let randomString = Math.random().toString(36);
                localStorage.setItem('game_token',randomString); */
              }} expand="block">
            <IonIcon slot="start" icon={location} />
            {dict[lang]['zacznij_gre']}
            </IonButton>
          </IonCol>
        }

        {sharedValues.game === 'start' 
        && sharedValues.gamePath === sharedValues.path &&
        
          <IonCol size="6" size-sm="6" size-xs ="6">
            <IonButton className="success" color="success" onClick={() => {
                const newSharedValues = {
                  ...sharedValues,
                  /* game: '', */
                  geoloc: false,
                  path: sharedValues.gamePath
                }
                setSharedValues(newSharedValues);
                setHeader(dict[lang]['zakonczyc_gre']);
                setMessage(dict[lang]['zakonczyc_gre_msg']);
                setConfirm(true);
                consoleLog('confirm',confirm);
              }} 
              expand="block">
              <IonIcon slot="start" icon={location} />
              {dict[lang]['zakoncz_gre']}
            </IonButton>
          </IonCol>
        }

        {sharedValues.game === 'start' 
        && sharedValues.gamePath !== sharedValues.path &&
        
          <IonCol size="6" size-sm="6" size-xs ="6">
            <IonButton routerLink={"/start/"+sharedValues.gamePath+"/1"} onClick={() => {
                const newSharedValues = {
                  ...sharedValues,
                  path: sharedValues.gamePath
                }
                setSharedValues(newSharedValues);
              }}
              expand="block">
              <IonIcon slot="start" icon={location} />
              {dict[lang]['wroc_do_gry']}
              {/* <span>&nbsp;({paths[sharedValues.gamePath]})</span> */}
            </IonButton>
          </IonCol>
        }
        
      </IonRow>

      </IonGrid>
      
      {/* </LoadScript> */}

      <Alerts 
        header={header}
        message={message} 
        showerror={iserror} 
        confirm={confirm} 
        success={success} 
        dismiss={() => onDismiss()}
      />

      {/* TODO: pytania
       */}    
      {markerId !== undefined &&
      
        <IonModal 
          isOpen={showModal} 
          cssClass='QA'
          onDidDismiss={() => {
            setShowModal(false);

            if (point === markers.length) {
              const newSharedValues = {
                ...sharedValues,
                confirm: false,
                path: sharedValues.gamePath
              }
              setSharedValues(newSharedValues);
              setHeader(dict[lang]['zakonczyc_gre']);
              setMessage(dict[lang]['ostatni_punkt']);
              setConfirm(true);
            }
            
          }}
        >
          <IonContent>
            
            {questions![point-1] !== undefined && questions![point-1].img! !== undefined &&
              <div>
                <img src={'/assets/'+questions![point-1].img!} />
              </div>
            }

            {questions![point-1] !== undefined &&
              <IonList className="opty-padding-page">
                <IonRadioGroup value={radioSelected} onIonChange={e => setRadioSelected(e.detail.value)}>
                  
                  <IonListHeader>
                    <IonLabel><h2>{questions[point-1].name}</h2></IonLabel>
                  </IonListHeader>

                  {questions[point-1].odp && questions[point-1].odp.map((odp:any,index:any) => (
                    <IonItem key={index}>
                      <IonLabel>{odp}</IonLabel>
                      <IonRadio slot="start" value={index} />  
                    </IonItem>
                    ))
                  }
                  
                </IonRadioGroup>
              </IonList>
            }
          
            {/* console.log('radioSelected:',radioSelected) */}
            
            
            <IonButton className="scan-close-btn" onClick={() => {
              setShowModal(false);
              }}>X</IonButton>

            
                <IonGrid id="footerModalBtn">

                  <IonRow>
                    
                    {/* <IonCol size="6" size-sm="6" size-xs ="12">
                      <IonButton expand="block" onClick={() => {
                        setShowModal(false);
                        }}>zamknij</IonButton>
                    </IonCol> */}

                    <IonCol size="12" size-sm="12" size-xs ="12">

                      {radioSelected !== false && radioSelected !== undefined && 
                        <IonButton expand="block" onClick={() => {

                          if (sharedValues.point < markers.length) {
                            setHeader(dict[lang]['odp_zapisana']);
                            setMessage(dict[lang]['przejdz_do_nast_punktu']);
                            setSuccess(true);
                          }
                          
                          /* const newSharedValues = {
                            ...sharedValues,
                            answers: {
                              ...sharedValues.answers,
                              [markId]: radioSelected
                            }
                          } */

                          const newSharedValues = {
                            ...sharedValues,
                            answers: {
                              ...sharedValues.answers,
                              [path]:{
                                ...sharedValues.answers[path],
                                [point]: radioSelected
                              }
                            }
                          }
                          setSharedValues(newSharedValues);
                          setRadioSelected(false)

                          if (sharedValues.point == markers.length) {
                            setShowModal(false);
                          }

                          }}>{dict[lang]['odp_zapisz']}
                        </IonButton>
                      }

                    </IonCol>

                  </IonRow>

                </IonGrid>
              
          </IonContent>

        </IonModal>

      }
    
    {/* TODO: Modal parkPlan
     */}
    <IonModal 
      isOpen={showPlanModal} 
      cssClass='parkPlan'
      onDidDismiss={() => {
        setShowPlanModal(false);
      }}
    >
      <IonContent>

        <IonButton className="scan-close-btn" onClick={() => {
          setShowPlanModal(false);
        }}>X</IonButton>

        <img src={'/assets/parkPlan.png'} />

        <IonContent className="ion-padding">
        <h3>{paths[1]}:</h3>
        <ul>
          <li>17. Miłorząb dwuklapowy</li>
          <li>18. Dąb czerwony</li>
          <li>25. Brzoza brodawkowata</li>
          <li>28. Lipa szerokolistna</li>
          <li>31. Topola euroamerykańska</li>
        </ul>

        <h3>{paths[2]}:</h3>
        <ul>
          <li>30. Jesion wyniosły</li>
          <li>24. Klon jawor</li>
          <li>D. Irga Dammera</li>
          <li>4. Kasztanowiec pospolity</li>
          <li>1. Topola biała</li>
        </ul>

        <h3>{paths[3]}:</h3>
        <ul>
          <li>21. Wiąz szypułkowy</li>
          <li>E. Bez czarny</li>
          <li>C. Porzeczka alpejska</li>
          <li>3. Lipa krymska</li>
          <li>2. Grab pospolity</li>
        </ul>

        {/* <ul>
          <li>&nbsp;1.Topola biała</li>
          <li>&nbsp;2.Grab pospolity</li>
          <li>&nbsp;3.Lipa krymska</li>
          <li>&nbsp;4.Kasztanowiec pospolity</li>
          <li>Klon pospolity</li>
          <li>Modrzew europekjski</li>
          <li>Robinia biała</li>
          <li>Sosna czarna</li>
          <li>Buk pospolity</li>
          <li>Daglezja zielona</li>
          <li>Dąb szypułkowy</li>
          <li>Wiąz szypułkowy</li>
          <li>Klon pospolity odm. Szwedlera</li>
          <li>Daglezja zielona odm. sina</li>
          <li>Buk pospolity odm. czerwonolistna</li>
          <li>Platan klonolistny</li>
          <li>17.Miłorząb dwuklapowy</li>
          <li>18.Dąb czerwony</li>
          <li>Buk pospolity</li>
          <li>Klon pospolity</li>
          <li>Wiąz szypułkowy</li>
          <li>Klon pospolity</li>
          <li>Buk pospolity</li>
          <li>Klon jawor</li>
          <li>Brzoza brodawkowata</li>
          <li>Lipa drobnolistna</li>
          <li>Świerk pospolity</li>
          <li>Lipa szerokolistna</li>
          <li>Modrzew europejski</li>
          <li>Jesion wyniosły</li>
          <li>Topola euroamerykańska</li>
          <li>Świerk pospolity</li>
          <li>Klon polny</li>
          <li>Sosna wejmutka</li>
          <li>Wiąz szypułkowy</li>
          
        </ul>
        <ul className="upperLatin">
          <li>Śnieguliczka biała</li>
          <li>Bluszcz</li>
          <li>Porzeczka alpejska</li>
          <li>Irga Dammera</li>
          <li>Bez czarny</li>
        </ul> */}
        </IonContent>

      </IonContent>

    </IonModal>

    {/* FIXME: Admin Window
     */}
    {sharedValues.showParkMapInfoWindow === 'true' && sharedValues.debug === true &&
      <div id="parkMapInfo">
        <p>Twoja pozycja:</p>
        <p>Lat: {loc?.coords.latitude}<br />
           Lng: {loc?.coords.longitude}</p>

           
          <IonButton onClick={() => {
            addMarker(mapRef.current); // lokalizacja punktów
          }} expand="block">lokalizacja punktu</IonButton>
        <IonButton onClick={() => {
            stopTracking(); 
            getCurrentPosition();
            const newSharedValues = {
              ...sharedValues,
              intro: false
            }
            setSharedValues(newSharedValues);
          }} expand="block">Moja lokalizacja</IonButton>
        <IonButton onClick={() => {
            getCurrentPosition();
            getCurrentDistance(); 
          }} expand="block">
          Odległość od Parku
        </IonButton>
        <IonButton onClick={startTracking} expand="block">Start tracking</IonButton>
        <IonButton onClick={stopTracking} expand="block">Stop tracking</IonButton>

        {/* <IonButton onClick={() => {showMarker(1)}} expand="block">
          Punkt 1
        </IonButton> */}
        {/* <IonButton onClick={getDistance}>
          distance
        </IonButton> */}

      
        {watchId &&
          <p>tracking... ({watchId})</p>
        }

        {sharedValues.distanceInfo && sharedValues.distanceInfo !== '' &&
          <div>
            <p>Jesteś w odległości ok. {sharedValues.distanceInfo?.rows[0]?.elements[0]?.distance?.text!} od Parku Siemiradzkiego.</p>
            <p>Piechotą dojdziesz tam w {sharedValues.distanceInfo?.rows[0]?.elements[0]?.duration?.text!}.</p>
          </div>
        }

      </div>
    }
    
    {sharedValues.distanceInfo !== undefined && sharedValues.distanceInfo !== '' && showToastOnce === true &&
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => {
          setShowToast(false);
          const newSharedValues = {
            ...sharedValues,
            distanceInfo: ''
          }
          setSharedValues(newSharedValues);
          setShowToastOnce(false);
        }}
        message={distMessage}
        position="bottom"
        animated={true}
        buttons={[
          {
            text: dict[lang]['zamknij'],
            role: 'cancel',
            handler: () => {
              //console.log('Cancel clicked');
            }
          }
        ]}
      />
    }
    </div>
    </IonPage>
  );
}
export default ParkMap;





