export const menuEN = {
  poczatek: 'Start game',
  skanuj: 'Scan QR code',
  zasady: 'Game rules',
  gry: 'My games',
  wyniki: 'Current score',
  najgry: 'Recent scores',
  henryk: 'Henryk Siemiradzki',
  historia: 'History of the Park',
  news: 'News',
  info: 'About the application',
  galeria: 'Photo gallery',
  error: 'Report a bug',
  zielony: 'Green number'
}

export const pathsEN = {
  1: 'Botanical trail',
  2: 'Historical trail',
  3: 'Sensory path'
}

export const pointsEN = [
  {},
  {
    1: 'Point 1',
    2: 'Point 2',
    3: 'Point 3',
    4: 'Point 4',
    5: 'Point 5'
  },
  {
    1: 'Point 1',
    2: 'Point 2',
    3: 'Point 3',
    4: 'Point 4',
    5: 'Point 5'
  },
  {
    1: 'Point 1',
    2: 'Point 2',
    3: 'Point 3',
    4: 'Point 4',
    5: 'Point 5'
  }
];

export const pointsNamesEN = [
  {},
  {
    1: 'Maidenhair tree',
    2: 'Red oak',
    3: 'Silver birch',
    4: 'Large-leaved linden',
    5: 'Euro-American poplar'
  },
  {
    1: 'European ash',
    2: 'Sycamore maple',
    3: 'Bearberry cotoneaster',
    4: 'Horse chestnut',
    5: 'Silver poplar'
  },
  {
    1: 'European white elm',
    2: 'Elderberry',
    3: 'Alpine currant',
    4: 'Crimean linden',
    5: 'Hornbeam'
  }
];
