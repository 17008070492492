import React from 'react';
import { IonButton, IonContent, IonImg, IonPage } from '@ionic/react';
import { dict, lang } from '../components/Config';

const Instrukcja: React.FC = () => {

  return(
    <IonPage>
      
      <IonContent className="ion-padding-page">
        <img src="/assets/420-Park-Siemiradzkiego-scaled.jpg" />
        
        {(lang === "pl" || lang === "pl-PL") &&
          <div>
            <h1>„Mój Park Siemiradzkiego”</h1>
            <p>Aplikacja mobilna "Mój Park Siemiradzkiego" to gra miejska z elementami edukacyjnymi.</p>
            <p>Gra polega na przejściu ścieżki wyznaczonej pomiędzy charakterystycznymi punktami Parku (punktami widokowymi, zabytkowymi obiektami itp.) w możliwie najkrótszym czasie.</p>
            
            <IonImg src="/assets/mapa-park-siemiradzkiego.png"></IonImg>
            
            <p>Po drodze uczestnik gry poznaje charakterystyczne punkty Parku i odpowiada na przypisane do każdego punktu pytania.</p>
            <p>Przy każdym punkcie znajduje się tabliczka z kodem QR. Należy go zeskanować, aby aktywować guzik umożliwiający otwarcie okna z pytaniem.</p>

            <IonImg src="/assets/park_tabliczka.jpg"></IonImg>

            <p>Po przejściu całej ścieżki można grę zakończyć i zapisać jej wynik (zdobyte punkty i czas przejścia ścieżki).</p>
            <p>Grać można indywidualnie lub w zespołach (np. szkolne klasy, zastępy harcerskie i zuchowe itp.).</p>
            <p>W momencie uruchomienia gry włączany jest stoper mierzący czas przejścia ścieżki. </p>
            {/* <p>Aplikacja informuje również o użytkownikach, którzy już przystąpili do gry (nazwy zespołów, godzina rozpoczęcia gry, ilość zaliczonych etapów itd.).</p> */}
            <IonImg src="/assets/geoloc.jpg"></IonImg>
            <p>Aktualna pozycja grającego (opcjonalnie) może być pokazywana na mapce (włączenie geolokalizacji może powodować szybsze zużycie baterii).</p>
            <p>Korzystając z opcji "Gdzie jestem?" nalezy pamiętać, że dokładność geolokalizacji Google to - w dobrych warunkach - kilka-kilkanaście, czasem nawet ponad 20 m.</p>
          </div>
        }
        
        {(lang === "de" || lang === "de-DE") &&
          <div>
            <h1>„Mein Siemiradzki-Park”</h1>
            <p>Die mobile App " Mein Siemiradzki-Park" ist ein Stadtspiel mit Bildungselementen.</p>
            <p>Bei dem Spiel heißt es, den zwischen den charakteristischen Parkpunkten (Aussichtspunkten, historischen Objekten u.ä .) markierten Weg in möglichst kürzester Zeit zu gehen.</p>
            <p>Spielen kann man individuell bzw. teamweise (z. B. Schulklassen,  Pfadfinder- und Wölflingsgruppen u. ä.).</p>
            <p>Bei jedem Punkt befindet sich eine Tafel mit dem QR-Code. Dieser ist zu scannen, um eine Taste zum Öffnen des Fensters mit der Frage zu aktivieren. </p>
            <p>Ist der ganze Pfad zurückgelegt, so kann das Spiel beendet und das Ergebnis (erlangte Punkte und die Zeit für die Wegzurücklegung) gespeichert werden.</p>
            <p>Die neu gespeicherten Ergebnisse sind im Bookmark "Die neuesten Ergebnisse" sichtbar.</p>
            <p>Die aktuelle Position des Spielers (optional) wird auf einer Karte gezeigt (aktivierte Geoortung kann den erhöhten Akkuverbrauch zur Folge haben). Bei der Option "Wo bin ich?" wird darauf hingewiesen, dass die Genauigkeit der Google-Geoortung - unter guten Bedingungen - mehrere, manchmal sogar über 20 m ist.</p>
          </div>
        }

        {(lang === "en" || lang === "en-EN") &&
          <div>
            <h1>My Siemiradzki Park</h1>

            <p>The 'Mój Park Siemiradzkiego' mobile application is an urban game with educational elements.</p>
            <p>The game involves walking the path between the characteristic points of the Park (viewpoints, historical buildings, etc.) in the shortest possible time.</p>
            <IonImg src="/assets/mapa-park-siemiradzkiego.png"></IonImg>
            <p>At each point there is a plaque with a QR code. Scan the code to activate the button to open the question window.</p>
            <IonImg src="/assets/park_tabliczka.jpg"></IonImg>
            <p>Once you have completed the entire path, you can quit the game and save your score (points scored and time for the path).</p>
            <p>The latest saved scores are visible in the "Latest scores" tab.</p>
            <p>The game can be played individually or in teams (e.g. school classes, scout teams etc.).</p>
            <p>When the game starts, a stopwatch is activated, measuring time of completing a path.</p>
            <IonImg src="/assets/geoloc.jpg"></IonImg>
            <p>The current position of the player (optional) is shown on a map (geolocalisation may cause increased battery consumption).</p>
            <p>When using the "Where am I?" option, please note that accuracy of Google geolocation is - in good conditions - sometimes even more than 20 meters.</p>
          </div>
        }
        
        
        {(lang === "pl" || lang === "pl-PL") &&
          <div>
            
          </div>
        }
        
        {(lang === "de" || lang === "de-DE") &&
          <div>
            
          </div>
        }

        {(lang === "en" || lang === "en-EN") &&
          <div>
            
          </div>
        }

        
        
        <IonButton expand="full" routerLink="/">{dict[lang]['zacznij_gre']}</IonButton>
        
        <div className="footer"><img src="/assets/stopka-600x100.png" /></div>
      </IonContent>
    </IonPage>
  );
}
export default Instrukcja;