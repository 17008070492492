import React, { Suspense, useContext, useEffect, useState } from 'react';
import { IonApp, IonSpinner, IonRouterOutlet, IonSplitPane, isPlatform } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route, useHistory } from 'react-router-dom';
import Menu from './components/Menu';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Swiper */
import 'swiper/swiper-bundle.min.css';
import '@ionic/react/css/ionic-swiper.css';

/* Theme variables */
import './theme/variables.css';

/* Park css */
import './pages/Page.css';

import Alerts from './components/Alerts';
import AppContext from './Context';
import AppQRContext from './ContextQR';
//import {AppTimerProvider} from './ContextTimer';
import { config, consoleLog } from './components/Config';
import { App } from '@capacitor/app';
import { Network } from '@capacitor/network';
import { SplashScreen } from '@capacitor/splash-screen';
import Header from './components/Header';

import ParkMap from './components/ReactGoogleMap';
import Welcome from './components/Welcome';
import Slides from './components/Swiper';
import QRcodeReader from './components/QRcodeReader';
import Intro from './components/Intro';

import Henryk from './pages/Henryk';
import Instrukcja from './pages/Instrukcja';
import Results from './pages/Results';
import Admin from './pages/Admin';
import News from './pages/News';
import MyGames from './pages/MyGames';
import Historia from './pages/Historia';
import BestGames from './pages/BestGames';
import Offline from './pages/Offline';
import Info from './pages/Info';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import MyCamera from './components/Camera';
import Polityka from './pages/Polityka';
import Zielony from './pages/Zielony';


const FCM_PWA    = React.lazy(() => import('./components/FCM_PWA'));
const FCM_MOBILE = React.lazy(() => import('./components/FCM_mobile'));

const FCMComponent = () => {
  
  return (
    <Suspense fallback={<div><IonSpinner /></div>}>
      {(!isPlatform('hybrid')) && <FCM_PWA apiURL={config.apiURL} />}
      {(isPlatform('hybrid')) && <FCM_MOBILE />}
    </Suspense>
  );

}


const ParkApp: React.FC = () => {
  
  const {sharedValues, setSharedValues} = useContext(AppContext);
  const { sharedQR, setSharedQR }       = useContext(AppQRContext);
  const [isOnline, setIsOnline]         = useState<boolean>(true);
  
  let history = useHistory();

  //SplashScreen.hide();

  /* SplashScreen.show({
    showDuration: 2000,
    autoHide: true
  }); */

  /* Network.addListener('networkStatusChange', (status) => {
    consoleLog("App Network status changed", status);
    if (status.connected === false) {
      setIsOnline(false);
    } else {
      setIsOnline(true);
    }
  }); */

  /* document.addEventListener('ionBackButton', (ev:any) => {
    
    const newSharedQR = {
      ...sharedQR,
      scanQR: false
    }
    setSharedQR(newSharedQR);

    consoleLog('App back button',window.location.pathname);
    
    
    
    ev.detail.register(-1, () => {
      if (window.location.pathname === "/" || window.location.pathname === "/login") {
        (navigator as any).app.exitApp();
      }
    });
  }); */

  /* App.addListener('appStateChange', ({isActive}) => {
    consoleLog('App state changed. Is active?', isActive);
    if (isActive === true) {
      window.location.reload();
    }
  }); */

  
  /* Network.addListener('networkStatusChange', (status) => {
    consoleLog("Network status changed", status);
    if (status.connected === false) {
      let newSharedValues = {
        ...sharedValues,
        online: false
      }
      setSharedValues(newSharedValues);
    } else {
      let newSharedValues = {
        ...sharedValues,
        online: true
      }
      setSharedValues(newSharedValues);
      //window.location.reload();
    }
  }); */
  
  
  useEffect(() => {
    const logCurrentNetworkStatus = async () => {
      const status = await Network.getStatus();
      if (status.connected === true) {
        let newSharedValues = {
          ...sharedValues,
          online: true
        }
        setSharedValues(newSharedValues);
      }
      //consoleLog('Network status:', status);
    };
    logCurrentNetworkStatus();
  },[]);
  
  return (
    <IonApp>

      <IonReactRouter>
        
        <Offline />

        <Header />
        
        <IonSplitPane contentId="main">
          
          <Menu />
          
          <IonRouterOutlet id="main">
            <Route path="" exact={true}>
              <Welcome />
            </Route>
            <Route path="/" exact={true}>
              <Welcome />
            </Route>
            <Route path="/start/" exact={true}>
              <ParkMap />
            </Route>
            {/* <Route path="/start/:path" exact={true}>
              <ParkMap />
            </Route> */}
            <Route path="/start/:path?/:point?/:question?">
              <ParkMap />
            </Route>
            <Route path="/intro/:path" exact={true}>
              <Intro />
            </Route>
            <Route path="/instrukcja" exact={true}>
              <Instrukcja />
            </Route>
            <Route path="/games" exact={true}>
              <MyGames />
            </Route>
            <Route path="/results" exact={true}>
              <Results />
            </Route>
            <Route path="/bestgames" exact={true}>
              <BestGames />
            </Route>
            <Route path="/henryk" exact={true}>
              <Henryk />
            </Route>
            <Route path="/historia" exact={true}>
              <Historia />
            </Route>
            <Route path="/slides" exact={true}>
              <Slides />
            </Route>
            <Route path="/news" exact={true}>
              <News />
            </Route>
            <Route path="/punkty/:markerId" exact={true}>
              <ParkMap />
            </Route>
            <Route path="/QR" exact={true}>
              <QRcodeReader />
            </Route>
            <Route path="/admin" exact={true}>
              <Admin />
            </Route>
            <Route path="/info" exact={true}>
              <Info />
            </Route>
            <Route path="/zielony" exact={true}>
              <Zielony />
            </Route>
            <Route path="/camera" exact={true}>
              <MyCamera />
            </Route>
            <Route path="/polityka-prywatnosci" exact={true}>
              <Polityka />
            </Route>
            {/* <Route component={Welcome} /> */}
            {/* <Route render={() => <Redirect to="/" />} /> */}
          </IonRouterOutlet>
        </IonSplitPane>

        {sharedValues.online !== false &&
          FCMComponent()
        }
        
      </IonReactRouter>
    </IonApp>
  );
};

export default ParkApp;
