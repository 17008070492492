import React, { createContext, useEffect, useState } from 'react';
import { consoleLog } from './components/Config';

const AppQRContext = createContext<any>(undefined);
      AppQRContext.displayName = 'AppQRContext';
export default AppQRContext;

type Props = {
  children: React.ReactNode;
};

export const AppQRProvider = ({ children }: Props) => {
  const [sharedQR, setSharedQR] = useState<any>({scanQR:false});

  let localQRState:any = [];

  useEffect(() => {

    if (localStorage.getItem("scanQR") !== null) {

      localQRState = JSON.parse(localStorage.getItem("scanQR") || '');
      
      //consoleLog('contextQR localQRState 1',localQRState);
    } else {
      localQRState = 
        {
          scanQR: false
        }
      //consoleLog('contextQR localQRState 2',localQRState);
    }

    setSharedQR(localQRState);

  },[]);

  useEffect(() => {
    if (sharedQR) {
      const sharedToLocal = {
        ...sharedQR
      }
      //consoleLog('contextQR sharedQR:',sharedToLocal);
      localStorage.setItem("sharedQR", JSON.stringify(sharedToLocal));
    }
  },[sharedQR]);

  let QRState = {
    sharedQR, setSharedQR
  }
  
  return (
    <AppQRContext.Provider value={QRState}>{children}</AppQRContext.Provider>
  );
};