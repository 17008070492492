import React, { useEffect, useState, useContext } from 'react';
import { IonButton } from '@ionic/react';
import AppContext from '../Context';
import AppTimerContext from '../ContextTimer';
import { consoleLog } from './Config';

const Timer: React.FC = () => {

  const { sharedValues, setSharedValues } = useContext(AppContext);
  const { sharedTimer, setSharedTimer }   = useContext(AppTimerContext);
  
  const [currentTime, setCurrentTime]     = useState<number | string | undefined>();
  const [stoper, setStoper]               = useState<boolean>(false);
  const [startTime, setStartTime]         = useState<Date | undefined>();
  const [stoperTime, setStoperTime]       = useState<number | string | undefined>('0:00');
  
  function addZero(i:any) {
    if (i < 10) {i = "0" + i}
    return i;
  }

  useEffect(() => {
    setStoper(false);
    const newSharedValues = {
      ...sharedValues,
      game: ''
    }
    setSharedValues(newSharedValues);
    //consoleLog('Timer','reload');
  },[window.location.reload])

  useEffect(() => {
    
    if (sharedValues.game === 'start') {
      setStoper(true);
    } else {
      
      /* const newSharedValues = {
        ...sharedValues,
        timer: stoperTime
      }
      setSharedValues(newSharedValues); */

      setStoper(false);
      setStoperTime('0:00');
    }

    if (stoper === true || sharedValues.game === 'start') {
      
      //setStoperTime('0:00');
      const currTime = new Date();
      let hms        = currTime.getHours()+':'+addZero(currTime.getMinutes())+':'+addZero(currTime.getSeconds());
      setCurrentTime(hms);
      setStartTime(currTime);

      const timer = window.setInterval(() => {
        
        //console.log('stoper 1', stoper);
        //console.log('startTime 1', startTime);

        if (sharedValues.game === 'start') {
          setStartTime(currTime);
        }

        //console.log('currentTime', currentTime+'|'+startTime);

        if (startTime !== undefined && (stoper === true || sharedValues.game === 'start')) {
          //console.log('stoper 2', stoper);
          //console.log('startTime 2', startTime);
          let timer = Math.floor((new Date().getTime() - startTime!.getTime()) / 1000);
          let stoperTime = addZero(timer);
          
          //consoleLog('Timer: stoperTime', stoperTime);

          /* const newSharedValues = {
            ...sharedValues,
            timer: timer
          }
          setSharedValues(newSharedValues); */

          const newSharedTimer = {
            ...sharedTimer,
            timer: timer
          }
          setSharedTimer(newSharedTimer);

          // stoperTime format
          if (stoperTime > 60) {
            const stoperTimeMin    = Math.floor(stoperTime / 60);
            const stoperTimeSec    = addZero(stoperTime - (stoperTimeMin * 60));
            const stoperTimeFormat = stoperTimeMin+':'+stoperTimeSec;
            setStoperTime(stoperTimeFormat);
          } else {
            setStoperTime('0:'+stoperTime);
          }
          
        }

        //console.log('startTime', startTime);

      }, 1000);

      return () => {
        window.clearInterval(timer);
        //console.log('stoper 3', stoper);
      };

    } else {
      setStoper(false);
    }
  },[stoper, sharedValues.game]);

  return(
    <div id="timer">{stoperTime}
        
      {/* <IonButton fill="default" color="secondary" onClick={() => {
        if (!stoper) {
          setStoper(true); 
          setStartTime(new Date());
        } else {
          setStoper(false); 
          setStartTime(undefined); 
          setStoperTime('0:00');
          const newSharedValues = {
            ...sharedValues,
            game: ''
          }
          setSharedValues(newSharedValues);

        }
      }}>{stoperTime}</IonButton> */}
      
    </div>
  );
}
export default Timer;