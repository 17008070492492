import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../Context';
import AppTimerContext from '../ContextTimer';
import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonItem, IonPage, IonRow, IonSelect, IonSelectOption } from '@ionic/react';
import { config, consoleLog, dict, lang, langs, pathQA, paths, timerFormat } from '../components/Config';
import { happy, happyOutline, happySharp, paperPlane, sad, sadOutline, sadSharp } from 'ionicons/icons';
import Alerts from '../components/Alerts';
import Timer from '../components/Timer';
import { useHistory } from 'react-router';

//import { useIsFocused } from '@react-navigation/native';

/* import { pathsPL } from '../local/pl-PL';
import { pathsDE } from '../local/de-DE';
import { pathsEN } from '../local/en-EN';

let pathsLng;

const lang = window.localStorage.getItem('lang');

switch (lang!) {
  case "pl-PL":
    pathsLng  = pathsPL!;
  break;
  case "de-DE":
    pathsLng  = pathsDE!;
  break;
  case "en-EN":
    pathsLng  = pathsEN!;
  break;
}

const paths:any  = pathsLng; */

const Results: React.FC = () => {
  const { sharedTimer, setSharedTimer }   = useContext(AppTimerContext);
  const { sharedValues, setSharedValues } = useContext(AppContext);
  
  const [name, setName]         = useState<any>('');
  const [pathsArr, setPathsArr] = useState<any>([]);
  const [iserror, setIserror]   = useState<boolean>(false);
  const [confirm, setConfirm]   = useState<boolean>(false);
  const [success, setSuccess]   = useState<boolean>(false);
  const [header, setHeader]     = useState<string>("");
  const [message, setMessage]   = useState<string>("");
  const [result, setResult]     = useState<any>(0);
  
  //const [score, setScore] = useState<number>(0);

  //localStorage.setItem('FCM_mobile_token','dLnVYJdyS8KN2eLitBBka0:APA91bEe8b7KYDNef6KaA3TTp1jGiAC37YWMi7ySgrDOLFJ39t5g4GxCvDrrv0L14nDwUDKHr67n47Myc3svhMvUtFUEJDChD23Jj01xXM_YYmmRVsQlWAerUrEanr-Q-syg0eWKScTh');

  let history = useHistory();
  
  let i = 0;
  useEffect(() => {
    const paths_arr = Object.keys(paths).map(function(key, index) {
      consoleLog('Results path',paths[key]);
      return paths[key]
    });
    setPathsArr(paths_arr);
  },[]);

  useEffect(() => {
    pathQA[parseInt(sharedValues.path)].forEach((QA:any,index:any) => {
      if (sharedValues.answers[parseInt(sharedValues.path)][QA.id] !== undefined && pathQA[parseInt(sharedValues.path)][index].odpOk === sharedValues.answers[parseInt(sharedValues.path)][QA.id]) {
        i++
      }
      consoleLog('Results QA',i)
      setResult(i);
    });
    
    //consoleLog('Results pathQA',pathQA);
    //consoleLog('Results parseInt',parseInt(sharedValues.path));
    //consoleLog('Results - path change',sharedValues.path+'|'+i)
    //consoleLog('Results paths:',paths);
    //consoleLog('Results sharedTimer:',sharedTimer);

    //setResult(i);
  },[sharedValues.path, sharedValues.answers, sharedValues.game]);

  /* useEffect(() => {
    setResult(i);
  },[i]); */

  /* pathQA[parseInt(sharedValues.path)].map((QA:any,index:any) => (
    if(sharedValues.answers[parseInt(sharedValues.path)][QA.id-1] !== undefined && pathQA[parseInt(sharedValues.path)][index].odpOk === sharedValues.answers[parseInt(sharedValues.path)][QA.id-1]) {

    }
  )) */

  
  const writeResult = () => {
    //setScore(i);
    //alert('zapisz: '+score+' | '+i);
    
    let FCM_token:any;
    if (localStorage.getItem('FCM_mobile_token') !== null) {
      FCM_token  = localStorage.getItem('FCM_mobile_token');  
    } else if (localStorage.getItem('FCM_PWA_token') !== null) {
      FCM_token  = localStorage.getItem('FCM_PWA_token');
    }
    const game_token:any = localStorage.getItem('game_token');

    const formData = new FormData(); 
          formData.append('user_name', sharedValues.user_name);
          formData.append('path', sharedValues.path);
          formData.append('game_token', game_token);
          formData.append('score', result);
          formData.append('score_time', sharedTimer.timer);
          formData.append('device_FCM_token', FCM_token);
          
    fetch(config.apiURL +'/write_results.php?uid='+i, {
      method: "POST",
      body: formData
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Wrong Network response');
      }
      return response.json();
    })
    .then(json => {
      console.log("Write results:", json);
      
      /* setHeader("Wszystkie odpowiedzi zostaną usunięte!");
      onDismiss(); */
      
    })
    .then(() => {
      setHeader("Gotowe!");
      setMessage("Gra została zapisane.");
      setSuccess(true);

      const newSharedValues = {
        ...sharedValues,
        answers: {
          ...sharedValues.answers,
          [sharedValues.path]:{}
        },
        game: '',
        question: 0
      }
      setSharedValues(newSharedValues);

      const newSharedTimer = {
        ...sharedTimer,
        timer: 0
      }
      setSharedTimer(newSharedTimer);

    })
    .catch(err => console.log("Error (write results): ",err));
  }

  const cancelResult = () => {
    setHeader("Wszystkie odpowiedzi zostaną usunięte!");
    setMessage("Kontynuować?");
    setConfirm(true);
  }

  const writeResultConfirm = () => {
    setHeader("Wszystkie odpowiedzi zostaną usunięte!");
    setMessage("Kontynuować?");
    setConfirm(true);
  }

  const onDismiss = () => {
    
    
    if(sharedValues.confirm === true && header === "Wszystkie odpowiedzi zostaną usunięte!") { 
      
      consoleLog('onDismiss',result);

      const newSharedValues = {
        ...sharedValues,
        answers: {
          ...sharedValues.answers,
          [sharedValues.path]:{}
        }
      }
      setSharedValues(newSharedValues);
      
      const newSharedTimer = {
        ...sharedTimer,
        timer: 0
      }
      setSharedTimer(newSharedTimer);
      
    }
    setIserror(false);
    setSuccess(false);
    setConfirm(false);

    if(header === "Gotowe!") { 
      history.push('/games');
    }

  }

    
  /* paths!.map((path:any,index:any) => {
    consoleLog('path:',path)
    }
  ); */


  

  return(
    <IonPage>
      {/* <Timer /> */}
      <Alerts 
        header={header}
        message={message} 
        showerror={iserror} 
        confirm={confirm} 
        success={success} 
        dismiss={() => onDismiss()}
      />

      <IonContent className="ion-padding-page">
        
      <h1>{dict[lang]['aktualny_wynik']}
        <span className="result">
          {result}<span> pkt.</span>
          <span className="timer">
          {timerFormat(sharedTimer.timer)} <span>min.</span>
          </span>
        </span>
      </h1>
      
      
      <IonItem>
        <IonSelect 
          name="path" 
          value={sharedValues.path}
          placeholder={paths[sharedValues.path]}
          cancelText={dict[lang]['zamknij']}
          onIonChange={(e) => {
            //window.localStorage.setItem('path',e.detail.value);
            const newSharedValues = {
              ...sharedValues,
              path: e.detail.value
            }
            setSharedValues(newSharedValues);
            //setResult(0);
          }}>
            
            {pathsArr.map((path:any,index:any) => 
              <IonSelectOption value={(index+1)} key={index}>{path}</IonSelectOption>  
            )}


        </IonSelect>
      </IonItem>
      
      {/* <p>({paths![sharedValues.path] || ''})</p> */}

        <ul id="results">
        
          {pathQA !== undefined && pathQA[parseInt(sharedValues.path)].map((QA:any,index:any) => (

            <li key={index}>
              
              <b>{QA.name}</b>
              
              {sharedValues.answers[parseInt(sharedValues.path)][QA.id] !== undefined &&
                <p>{/* sharedValues.answers[parseInt(sharedValues.path)][QA.id-1] */} {QA.odp[sharedValues.answers[parseInt(sharedValues.path)][QA.id]]}</p>
              }
              
              {sharedValues.answers[parseInt(sharedValues.path)][QA.id] !== undefined && 
              pathQA[parseInt(sharedValues.path)][index].odpOk === sharedValues.answers[parseInt(sharedValues.path)][QA.id] &&
                
                <p><IonIcon slot="start" ios={happyOutline} md={happyOutline} size="large" className="happy" /> 
                    {dict[lang]['dobrze']} 
                  
                  {/* {(() => {
                    (i++)
                  })()} */}

                </p>
                
              }
              
              {sharedValues.answers[parseInt(sharedValues.path)][QA.id] !== undefined && 
              pathQA[parseInt(sharedValues.path)][index].odpOk !== sharedValues.answers[parseInt(sharedValues.path)][QA.id] &&

                <p><IonIcon slot="start" ios={sadOutline} md={sadOutline} size="large" className="sad"/>

                    {dict[lang]['zle']}
                      
                  <IonButton fill="clear" slot="icon-only" routerLink={"/start/"+sharedValues.path+"/"+QA.id} onClick={() => {
                    const newSharedValues = {
                      ...sharedValues,
                      point: QA.id
                    }
                    setSharedValues(newSharedValues);
                  }}>
                    
                  {sharedValues.game !== '' && 
                    <IonIcon icon={paperPlane}></IonIcon>
                  }
                  
                  </IonButton>
                </p>
              }
              
              {/* {console.log('answerOK: '+pathQA[parseInt(sharedValues.path)][index].odpOk)}
              {console.log('answer: '+sharedValues.answers[parseInt(sharedValues.path)][QA.id-1])} */}
              
              
              {sharedValues.answers[parseInt(sharedValues.path)][QA.id] === undefined &&
                <p>{dict[lang]['brak_odp']}
                  <IonButton fill="clear" slot="icon-only" routerLink={"/start/"+sharedValues.path+"/"+QA.id} onClick={() => {
                    const newSharedValues = {
                      ...sharedValues,
                      point: QA.id
                    }
                    setSharedValues(newSharedValues);
                  }}><IonIcon icon={paperPlane}></IonIcon>
                  </IonButton>
                  
                  
                                  
                  {/* {(() => setScore(i))()} */}
                  {/* {(() => alert('i:'+i))()} */}
                </p>
              }

            </li>
          ))}

        </ul>
        
        {result > 0 &&
          <div>
          <p><b>{dict[lang]['aktualny_wynik']}: {result} pkt.</b></p>

          {(lang === "pl") &&
            <>
              <p>Wynik gry można zapisać na serwerze. Będzie wówczas widoczny dla innych graczy.</p>
              <p>Twój wynik zostanie zapisany pod nazwą:</p>
            </>
          }

          {(lang === "en") &&
            <>
              <p>The game result can be saved on the server. It will then be visible to other players.</p>
              <p>Your score will be saved under the name:</p>
            </>
          }

          {(lang === "de") &&
            <>
              <p>DE Wynik gry można zapisać na serwerze. Będzie wówczas widoczny dla innych graczy.</p>
              <p>Twój wynik zostanie zapisany pod nazwą:</p>
            </>
          }


          <IonInput 
            className="email"
            name="name" 
            type="text"
            placeholder=""
            value={localStorage.getItem('game_token')}
            disabled
          ></IonInput>

          {/* <IonInput 
            className="email"
            name="name" 
            type="text"
            placeholder=""
            value={sharedValues.user_name}
            onIonChange={(e) => {
              setName(e.detail.value);
              const newSharedValues = {
                ...sharedValues,
                user_name: e.detail.value
              }
              setSharedValues(newSharedValues);
            }}
            clearInput
          ></IonInput> */}
          </div>
        }
        
        <IonGrid id="resultsFooter">

        {sharedValues.game !== '' && sharedValues.gamePath &&
          <IonRow>

            <IonCol size="12" size-sm="12" size-xs ="12">
              <IonButton expand="block" routerLink={"/start/"+sharedValues.gamePath+"/1"} onClick={() => {}}>
                {dict[lang]['wroc_do_gry']}
                <span>&nbsp;({paths[sharedValues.gamePath]})</span>
              </IonButton>
            </IonCol>

          </IonRow>
        }
        
        {/* {consoleLog("answers",sharedValues.answers[parseInt(sharedValues.path)])} */}

        {Object.keys(sharedValues.answers[parseInt(sharedValues.path)]).length !== 0 &&
          <IonRow>

            <IonCol size="6" size-sm="6" size-xs ="12">
              <IonButton expand="block"
                onClick={() => {
                  writeResult();
                }}
              >
                {dict[lang]['zakoncz_zapisz']}
              </IonButton>
            </IonCol>

            <IonCol size="6" size-sm="6" size-xs ="12">
              <IonButton expand="block"
                onClick={() => {
                  cancelResult();
                }}
              >
                {dict[lang]['wyczysc_odp']}
              </IonButton>
            </IonCol>

          </IonRow>
        }

        </IonGrid>
        
        
        <div className="footer"><img src="/assets/stopka-600x100.png" /></div>
      </IonContent>
    </IonPage>
  );
}
export default Results;