import React, { useContext, useEffect, useState } from 'react';
import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonModal, IonPage, IonRow, isPlatform } from '@ionic/react';
import AppContext from '../Context';
import { Route, useHistory } from 'react-router';
import { documentText } from 'ionicons/icons';

import { paths, consoleLog, lang, dict } from './Config';
import { Network } from '@capacitor/network';
import AppQRContext from '../ContextQR';
/* import { pathsPL } from '../local/pl-PL';
import { pathsDE } from '../local/de-DE';
import { pathsEN } from '../local/en-EN'; */




const Welcome: React.FC = () => {

  const { sharedValues, setSharedValues } = useContext(AppContext);
  const { sharedQR, setSharedQR } = useContext(AppQRContext);
  const [showWelcome, setShowWelcome]     = useState(true);
  
  let history = useHistory();
  
  /* let paths:any;

  const lang = window.localStorage.getItem('lang');

  switch (lang) {
    case "pl-PL":
      paths  = pathsPL;
    break;
    case "de-DE":
      paths  = pathsDE;
    break;
    case "en-EN":
      paths  = pathsEN;
    break;
  } */

  //const paths:any  = pathsLng;

  useEffect(() => {
    /* const newSharedValues = {
      ...sharedValues,
      point: 0
    }
    setSharedValues(newSharedValues); */
    setShowWelcome(true);
    consoleLog('Welcome point',sharedValues.point);
  },[]);

  const showIntro = () => {
    const newSharedValues = {
      ...sharedValues,
      intro: true
    }
    setSharedValues(newSharedValues);
  }
  /* useEffect(() => {
    setShowWelcome(true);
  },[showWelcome]); */
  
  /* className={sharedQR.scanQR ? "scan" : ""} */

  return(
    <IonPage>
      {/* <IonModal 
        isOpen={showWelcome} 
        cssClass='welcome'
        onDidDismiss= {() => {history.push('/start/')}}
      > */}
        
        <IonContent>
          
          <div id="welcomeHeader">
            <img src="/assets/Punkt-Widokowy-1.jpg" />
            <div className="ion-padding">
              
              {lang === 'pl' &&
                <h1>Witamy w grze plenerowej<br />
                „Mój Park Siemiradzkiego”</h1>
              }
              {lang === 'en' &&
                <h1>Welcome to the urban game<br />
                „Mój Park Siemiradzkiego”</h1>
              }
              {lang === 'de' &&
                <h1>Willkommen im Spiel<br />
                „Mein Siemiradzki-Park”</h1>
              }

            </div>
          </div>  
          
          <IonGrid id="welcomeBtn">

            <IonRow>
              <IonCol size="6" size-sm="6" size-xs ="12">
                <IonButton className="zasady" expand="full" routerLink="/instrukcja" routerDirection="forward"><span>{dict[lang]['zasady_gry']}</span>
                  <IonIcon slot="start" icon={documentText} className="zasady" />
                </IonButton>
              </IonCol>
              <IonCol size="6" size-sm="6" size-xs ="12">
                <IonButton className="path_1" expand="full" onClick={() => showIntro()} routerLink="/intro/1" routerDirection="forward"><span  className="nr">1</span><span>{paths[1]}</span></IonButton>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="6" size-sm="6" size-xs ="12">
                <IonButton className="path_2" expand="full" onClick={() => showIntro()} routerLink="/intro/2" routerDirection="forward"><span className="nr">2</span><span>{paths[2]}</span></IonButton>
              </IonCol>
              <IonCol size="6" size-sm="6" size-xs ="12">
                <IonButton className="path_3" expand="full" onClick={() => showIntro()} routerLink="/intro/3" routerDirection="forward"><span className="nr">3</span><span>{paths[3]}</span></IonButton>
              </IonCol>
            </IonRow>

          </IonGrid>
          
        </IonContent>
        
      {/* </IonModal> */}
    </IonPage>
  );
}
export default Welcome;