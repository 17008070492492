import React, { useEffect, useState } from 'react';
import { config, consoleLog, dict, lang } from '../components/Config';
import { 
    IonLoading,
    IonContent,
    IonPage,
    IonCardContent,
    IonCardHeader,
    IonList,
    IonItem,
    IonButton,
    IonImg} from '@ionic/react';
//import Footer from '../components/Footer';
//import Header from '../components/Header';
import AppContext from '../Context';
import Refresher from '../components/Refresher';

interface News { 
  news: {
    news_id:string,
    news_img:string,
    news_date:string,
    news_title:string,
    news_short:string
  }
}

const NewsCard: React.FC<News> = ({news}) => {
  return (
    <IonCardContent>
      <IonImg src={news.news_img}></IonImg>
      <IonCardHeader>{news.news_title}</IonCardHeader>
      <p>{news.news_date}</p>
      <div dangerouslySetInnerHTML={{__html : news.news_short}}></div>
    </IonCardContent>
  );
}

const News: React.FC = () => {
  
  const { sharedValues, setSharedValues }  = React.useContext(AppContext);
  const [isLoaded, setisLoaded] = useState<boolean>(false);
  const [news, setNews]         = useState<any[]>([]);
  
  const getNews = () => {
      
    fetch(config.apiURL + "/news.php?uid=&lang=" + lang)
    .then(response => {
      
      const newSharedValues = {
        ...sharedValues,
        refresh: '0'
      }
      setSharedValues(newSharedValues);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(json => {
      consoleLog("News:", json);
      setisLoaded(true);
      setNews(json.news);

    })
    .catch(err => consoleLog("Error:",err));
  }

  useEffect(() => {
    getNews();
  },[]);

  useEffect(() => {
    if (sharedValues.refresh === '1') {
      getNews();
    }
  },[sharedValues.refresh]);
  
  if(!isLoaded) {

    return (
      <div className="ion-padding ion-text-center">
        <IonLoading 
          isOpen
          message={dict[lang]['aktualizacja']}
          duration={5000}
        />
      </div>
    );
    
  } else {


      return(
        <IonPage>
          
          <IonContent>

            <Refresher />

            <h1 className='ion-padding'>{dict[lang]['aktualnosci']}</h1>

            <IonList id="news">
            

              {news && news.map(news =>
                <IonItem key={news.news_id} lines="inset">
                  <NewsCard news={news} />
                </IonItem>
              )}

              {!news &&
                <h3 className="ion-text-center">
                  brak nowych informacji...
                </h3>
              }
                
            </IonList>
          </IonContent>
          {/* <Footer /> */}
        </IonPage>
      )

  }
}

export default News;