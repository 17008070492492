import React from 'react';
import { IonContent, IonPage, IonicSwiper } from '@ionic/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

//SwiperCore.use([IonicSwiper]);
SwiperCore.use([IonicSwiper, Navigation, Pagination]);

const Slides: React.FC = () => {
  return (
    <IonPage>
      <IonContent className="ion-padding-page">
        <Swiper
          pagination={{
            clickable: true
          }}
          navigation
        >
          <SwiperSlide><img src="/assets/gallery/1280px-Semiradsky_Christ_and_Sinner.jpg" /></SwiperSlide>
          <SwiperSlide><img src="/assets/gallery/420-Park-Siemiradzkiego-scaled.jpg" /></SwiperSlide>
          <SwiperSlide><img src="/assets/gallery/1280px-Semiradsky_Christ_and_Sinner.jpg" /></SwiperSlide>
        </Swiper>
      </IonContent>
    </IonPage>
  );
};
export default Slides;