import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Storage } from '@capacitor/storage';
import { Capacitor } from '@capacitor/core';
import { useCamera, availableFeatures } from '@capacitor-community/camera-react';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { IonButton, IonContent, IonPage } from '@ionic/react';
import AppContext from '../Context';
import AppTimerContext from '../ContextTimer';
import { consoleLog } from './Config';

const MyCamera: React.FC = () => {

  const { sharedValues, setSharedValues } = useContext(AppContext);
  const [imageData, setImageData] = useState<any>('');
  
  useEffect(() => {
    defineCustomElements(window);
  },[])

  //const { photo, getPhoto } = useCamera();
  
  /* const triggerCamera = useCallback(async () => {
    getPhoto({
        quality: 90,
        allowEditing: true,
        resultType: CameraResultType.DataUrl
      })
  }, [getPhoto]); */

  const takePicture = async () => {
    const image = await Camera.getPhoto({
      quality: 80,
      width: 480,
      height: 360,
      /* allowEditing: false, */
      source: CameraSource.Camera,
      /* resultType: CameraResultType.Uri */
      resultType: CameraResultType.Base64,
      saveToGallery: false
      
    });
  
    // image.webPath will contain a path that can be set as an image src.
    // You can access the original file using image.path, which can be
    // passed to the Filesystem API to read the raw data of the image,
    // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
    //let imageUrl = image.webPath;
    let imageUrl = image.base64String;
    consoleLog('image',JSON.stringify(imageUrl));
    setImageData(imageUrl);
    // Can be set to the src of an image now
    //let imageElement:any;
    //imageElement.src = imageUrl;
  };

  return(
    <IonPage>
      <IonContent id="camera">
      
      {/* <IonButton onClick={triggerCamera}>get picture</IonButton> */}
      <IonButton onClick={takePicture}>take picture</IonButton>

      {/* <div>{photo && <img alt="" src={photo.dataUrl} />}</div> */}
      
      <div>{imageData && <img alt="PICURE!" src={"data:image/png;base64, "+imageData} />}</div>

      </IonContent>
    </IonPage>
  );
}
export default MyCamera;