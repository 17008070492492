import React from 'react';
import { IonRefresher, IonRefresherContent } from '@ionic/react';
import { RefresherEventDetail } from '@ionic/core';
import AppContext from '../Context';
import {
  chevronDownCircleOutline
} from 'ionicons/icons';
import { consoleLog } from './Config';


const Refresher: React.FC = () => {

  const { sharedValues, setSharedValues } = React.useContext(AppContext);
  
  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    const newSharedValues = {
      ...sharedValues,
      refresh: '1'
    }
    setSharedValues(newSharedValues);
    consoleLog('Begin async operation','');
    setTimeout(() => {
      consoleLog('Async operation has ended','');
      event.detail.complete();
    }, 2000);
  }
  
  return(
    <IonRefresher slot="fixed"
      pullMin={100}
      pullMax={100}
      onIonRefresh={doRefresh}>
      <IonRefresherContent
        pullingIcon={chevronDownCircleOutline}
        pullingText="Przeciągnij w dół, aby odświeżyć"
        refreshingSpinner="circles"
        refreshingText="Odświeżanie..."
        >
      </IonRefresherContent>
    </IonRefresher>
  );
}

export default Refresher;