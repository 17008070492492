import React, { useEffect, useState } from 'react';
import { config, consoleLog, dict, lang, paths, timerFormat } from '../components/Config';
import { 
    IonLoading,
    IonContent,
    IonPage,
    IonCardContent,
    IonCardHeader,
    IonList,
    IonItem,
    IonButton} from '@ionic/react';
//import Footer from '../components/Footer';
//import Header from '../components/Header';
import AppContext from '../Context';
import Refresher from '../components/Refresher';

interface Game { 
  game: {
    id:string,
    user_name:string,
    path:string,
    game_token:string,
    score_date:string,
    score_time:string,
    score:string,
    device_FCM_token:string
  }
}

const GameCard: React.FC<Game> = ({game}) => {
  return (
    <IonCardContent>
      {/* <IonCardHeader>{game.id}</IonCardHeader> */}
      <p>{paths![game.path]}</p>
      <p>{dict[lang]['data']}: {game.score_date}</p>
      <div>{dict[lang]['wynik']}: <b>{game.score} pkt.</b>, {dict[lang]['czas']}: <b>{timerFormat(game.score_time)} min.</b></div>
    </IonCardContent>
  );
}

const MyGames: React.FC = () => {
  
  const { sharedValues, setSharedValues }  = React.useContext(AppContext);
  const [isLoaded, setisLoaded] = useState<boolean>(false);
  const [games, setGames]       = useState<any[]>([]);
  
  const getGames = () => {
      
    let FCM_token:any;
    if (localStorage.getItem('FCM_mobile_token') !== null) {
      FCM_token  = localStorage.getItem('FCM_mobile_token');  
    } else if (localStorage.getItem('FCM_PWA_token') !== null) {
      FCM_token  = localStorage.getItem('FCM_PWA_token');
    }
    const game_token:any = localStorage.getItem('game_token');

    const formData = new FormData(); 
          formData.append('path', sharedValues.path);
          formData.append('game_token', game_token);
          formData.append('device_FCM_token', FCM_token);

    fetch(config.apiURL + "/read_results.php?uid=", {
      method: "POST",
      body: formData
    })
    .then(response => {
      
      const newSharedValues = {
        ...sharedValues,
        refresh: '0'
      }
      setSharedValues(newSharedValues);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(json => {
      consoleLog("Games:", json);
      setisLoaded(true);
      setGames(json);

    })
    .catch(err => consoleLog("Error:",err));
  }
  
  useEffect(() => {
    getGames();
  },[]);
  
  useEffect(() => {
    //if (sharedValues.refresh === '1') {
      getGames();
    //}
  },[sharedValues.refresh,sharedValues.answers]);
  
  //useEffect(() => {getGames()},[sharedValues.refresh]);

  if(!isLoaded) {

    return (
      <div className="ion-padding ion-text-center">
        <IonLoading 
          isOpen
          message={dict[lang]['aktualizacja']}
          duration={5000}
        />
      </div>
    );
    
  } else {

    return(
      <IonPage>
        
        <IonContent>

          <Refresher />  
        

          <h1 className='ion-padding'>{dict[lang]['moje_gry']}</h1>

          <IonList id="news">
          
            {games && games.map(game =>
              <IonItem key={game.id} lines="inset">
                <GameCard game={game} />
              </IonItem>
            )}

            {!games &&
              <h3 className="ion-text-center">
                {dict[lang]['brak_zapisanych']}
              </h3>
            }
              
          </IonList>
        </IonContent>
        {/* <Footer /> */}
      </IonPage>
    )

  }
}

export default MyGames;