import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { lang } from '../components/Config';

const Info: React.FC = () => {

  return(
    <IonPage>
      
      <IonContent className="ion-padding-page">
        
      {(lang === "pl" || lang === "pl-PL") &&
        <div>
          <h1>Informacje o aplikacji</h1>

          <p>Aplikacja powstała w ramach zadania inwestycyjnego „Bezgraniczny trójdźwięk - natury, kultury i edukacji w Trebnitz, Witnicy i Gorzowie”.</p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
        </div>
      }

      {(lang === "en" || lang === "en-EN") &&
        <div>
          <h1>About the application</h1>

          <p>The application was developed as part of the investment project „Bezgraniczny trójdźwięk - natury, kultury i edukacji w Trebnitz, Witnicy i Gorzowie”.</p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
        </div>
      }

      {(lang === "de" || lang === "de-DE") &&
        <div>
          <h1>App-Info</h1>

          <p>Die App entstand im Rahmen einer Investitionsaufgabe „Grenzenloser Dreiklang – Natur, Kultur und Bildung in Trebnitz, Witnica und Gorzów”.</p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
        </div>
      }

        <div className="footer"><img src="/assets/stopka-600x100.png" /></div>
      </IonContent>
    </IonPage>
  );
  
}
export default Info;