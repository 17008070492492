import React, { useContext, useEffect, useState } from 'react';
import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonImg, IonModal, IonPage, IonRouterLink, IonRow, isPlatform } from '@ionic/react';
import AppContext from '../Context';
import { consoleLog, dict, lang, paths } from './Config';
import { useHistory, useParams } from 'react-router';
import { qrCode } from 'ionicons/icons';
import AppQRContext from '../ContextQR';



const Intro: React.FC = () => {

  const { sharedValues, setSharedValues } = useContext(AppContext);
  const { sharedQR, setSharedQR }         = useContext(AppQRContext);

  let points_names:any = [];
  
  switch (lang) {
    case "pl":
    case "pl-PL":
    default:
      points_names = [
        {},
        {
          1: 'Miłorząb dwuklapowy',
          2: 'Dąb czerwony',
          3: 'Brzoza brodawkowata',
          4: 'Lipa szerokolistna',
          5: 'Topola euroamerykańska'
        },
        {
          1: 'Jesion wyniosły',
          2: 'Klon jawor',
          3: 'Irga Dammera',
          4: 'Kasztanowiec',
          5: 'Topola biała'
        },
        {
          1: 'Wiąz szypułkowy',
          2: 'Bez czarny',
          3: 'Porzeczka alpejska',
          4: 'Lipa krymska',
          5: 'Grab pospolity'
        }
      ];
    break;
    case "de":
    case "de-DE":
      points_names = [
        {},
        {
          1: 'Ginkgo',
          2: 'Roteiche',
          3: 'Hänge-Birke',
          4: 'Sommerlinde',
          5: 'Euroamerikanische Pappel'
        },
        {
          1: 'Gewöhnliche Esche',
          2: 'Berg-Ahorn',
          3: 'Teppich-Zwergmispel',
          4: 'Rosskastanien',
          5: 'Silber-Pappel'
        },
        {
          1: 'Flatter-Ulme',
          2: 'Schwarze Holunder',
          3: 'Alpen-Johannisbeere',
          4: 'Krim-Linde',
          5: 'Hainbuche'
        }
      ];
    break;
    case "en":
    case "en-EN":
    case "en-GB":
    case "en-gb":
    case "en-us":
      points_names = [
        {},
        {
          1: 'Maidenhair tree',
          2: 'Red oak',
          3: 'Silver birch',
          4: 'Large-leaved linden',
          5: 'Euro-American poplar'
        },
        {
          1: 'European ash',
          2: 'Sycamore maple',
          3: 'Bearberry cotoneaster',
          4: 'Horse chestnut',
          5: 'Silver poplar'
        },
        {
          1: 'European white elm',
          2: 'Elderberry',
          3: 'Alpine currant',
          4: 'Crimean linden',
          5: 'Hornbeam'
        }
      ];
    break;
  }
  //const [punktySciezki, setPunktySciezki] = useState('');

  let history = useHistory();
  
  let { path } = useParams<{ path:any | undefined }>();
  if (path === undefined) {
    path = '1';
  }
  
  /* {(isPlatform('pwa')) &&
    <IonRouterLink href="/start/1/1"><IonImg src="/assets/points/17.jpg" /></IonRouterLink>
  }
  {(!isPlatform('pwa')) &&
    <IonRouterLink routerLink="/start/1/1"><IonImg src="/assets/points/17.jpg" /></IonRouterLink>
  } */
  const punktySciezki_1:any = 
  <>
    <p>1. {points_names[1][1]} (<b>17</b>)</p>
    <IonRouterLink routerLink="/start/1/1"><IonImg src="/assets/points/17.jpg" /></IonRouterLink>
    <p>2. {points_names[1][2]} (<b>19</b>)</p>
    <IonRouterLink routerLink="/start/1/2"><IonImg src="/assets/points/18.jpg" /></IonRouterLink>
    <p>3. {points_names[1][3]} (<b>25</b>)</p>
    <IonRouterLink routerLink="/start/1/3"><IonImg src="/assets/points/25.jpg" /></IonRouterLink>
    <p>4. {points_names[1][4]} (<b>28</b>)</p>
    <IonRouterLink routerLink="/start/1/4"><IonImg src="/assets/points/28.jpg" /></IonRouterLink>
    <p>5. {points_names[1][5]} (<b>31</b>)</p>
    <IonRouterLink routerLink="/start/1/5"><IonImg src="/assets/points/31.jpg" /></IonRouterLink>
  </>;

  const punktySciezki_2:any = 
  <>
    <p>1. {points_names[2][1]} (<b>30</b>)</p>
    <IonRouterLink routerLink="/start/2/1"><IonImg src="/assets/points/Kozacza_Gora.jpg" /></IonRouterLink>
    <p>2. {points_names[2][2]} (<b>24</b>)</p>
    <IonRouterLink routerLink="/start/2/2"><IonImg src="/assets/points/wieza_cisnien.jpg" /></IonRouterLink>
    <p>3. {points_names[2][3]} (<b>D</b>)</p>
    <IonRouterLink routerLink="/start/2/3"><IonImg src="/assets/points/D.jpg" /></IonRouterLink>
    <p>4. {points_names[2][4]} (<b>4</b>)</p>
    <IonRouterLink routerLink="/start/2/4"><IonImg src="/assets/points/4.jpg" /></IonRouterLink>
    <p>5. {points_names[2][5]} (<b>1</b>)</p>
    <IonRouterLink routerLink="/start/2/5"><IonImg src="/assets/points/mural.jpg" /></IonRouterLink>
  </>;

  const punktySciezki_3:any = 
  <>
    <p>1. {points_names[3][1]} (<b>21</b>)</p>
    <IonRouterLink routerLink="/start/3/1"><IonImg src="/assets/points/21.jpg" /></IonRouterLink>
    <p>2. {points_names[3][2]} (<b>E</b>)</p>
    <IonRouterLink routerLink="/start/3/2"><IonImg src="/assets/points/E.jpg" /></IonRouterLink>
    <p>3. {points_names[3][3]} (<b>C</b>)</p>
    <IonRouterLink routerLink="/start/3/3"><IonImg src="/assets/points/C.jpg" /></IonRouterLink>
    <p>4. {points_names[3][4]} (<b>3</b>)</p>
    <IonRouterLink routerLink="/start/3/4"><IonImg src="/assets/points/3.jpg" /></IonRouterLink>
    <p>5. {points_names[3][5]} (<b>2</b>)</p>
    <IonRouterLink routerLink="/start/3/5"><IonImg src="/assets/points/2.jpg" /></IonRouterLink>
  </>;

  
  useEffect(() => {

    /* if (sharedValues.path !== path) {
      pathChanged = true;
      gamePath = sharedValues.path;
    } else {
      pathChanged = false;
      gamePath = path;
    }

    consoleLog('intro path changed', pathChanged);
    consoleLog('intro gamePath', gamePath); */

    const newSharedValues = {
      ...sharedValues,
      path: path,
      point: 1
    }
    setSharedValues(newSharedValues);

    consoleLog('intro:',sharedValues.path+'|'+path);
    consoleLog('intro sV:',sharedValues);


  },[path]);

  //consoleLog('sw',sharedValues);
  //consoleLog('intro sw:',sharedValues);
  /* className={sharedQR.scanQR ? "scan" : ""} */
  return(
    <IonPage>
      <IonContent id="intro">
      {/* <IonModal 
        isOpen={sharedValues.intro} 
        cssClass='intro'
        onDidDismiss={() => consoleLog('intro closed','')}
      > */}
      
      {/* <IonButton className="scan-close-btn" onClick={() => {
        history.goBack();
        const newSharedValues = {
          ...sharedValues,
          intro: false
        }
        setSharedValues(newSharedValues);
        
      }} expand="block">X</IonButton> */}

        {(path === '1') &&
          <div>
            <img src="/assets/sciezki-i-lawki-10-1.jpg" />

            <div className="opty-padding-page">
            {(lang === "pl" || lang === "pl-PL") &&
              <div>
                <h1>{paths[1]}</h1>
                <p>Ścieżka prowadzi nas między najciekawszymi okazami flory Parku.</p>
                
                <p>Skanuj kody QR <IonIcon icon={qrCode} /> znajdujące się na tabliczkach w wyznaczonych punktach Parku, aby poznać pytanie związane z punktem.</p>

                <h3>Punkty ścieżki:</h3>
                {punktySciezki_1}
              </div>
            }
            
            {(lang === "de" || lang === "de-DE") &&
              <div>
                <h1>{paths[1]}</h1>
                <p>Der Pfad führt uns unter die interessantesten Exemplare der Park-Flora.</p>
                
                <p>QR-Codes <IonIcon icon={qrCode} /> sind auf den an markierten Punkten im Park aufgestellten Tafeln angebracht. Diese sind zu scannen, um eine Frage betreffend diesen Punkt kennenzulernen.</p>

                {punktySciezki_1}

              </div>
            }

            {(lang === "en" || lang === "en-EN") &&
              <div>
                <h1>{paths[1]}</h1>
                <p>The path leads you between the most interesting specimens of the Park's flora.</p>
                
                <p>Scan the QR codes <IonIcon icon={qrCode} /> on the plaques at designated points in the Park to find out the question associated with the point.</p>

                {punktySciezki_1}

              </div>
            }

            </div>
          </div>
        }
        
        {(path === '2') &&
          <div>
            <img src="/assets/420-Park-Siemiradzkiego-scaled.jpg" />
            
            <div className="opty-padding-page">
            {(lang === "pl" || lang === "pl-PL") &&
              <div>
                <h1>{paths[2]}</h1>
                <p>Park powstał na początku XX wieku, ale historia tego miejsca sięga znacznie dalej w przeszłość. Podobnie jak sama postać Henryka Siemiradzkiego, który tworzył swoje dzieła w drugiej połowie XIX wieku.</p>
                <p>Przy punktach tej ścieżki należy odpowiedzieć na pytania związane z historią Parku i twórczością Siemiradzkiego.</p>

                <p>Skanuj kody QR <IonIcon icon={qrCode} /> znajdujące się na tabliczkach w wyznaczonych punktach Parku, aby poznać pytanie związane z punktem.</p>

                <h3>Punkty ścieżki:</h3>

                {punktySciezki_2}

              </div>
            }
            
            {(lang === "de" || lang === "de-DE") &&
              <div>
                <h1>{paths[2]}</h1>
                <p>Der Park entstand Anfang des XX. Jahrhunderts, aber die Geschichte dieses Ortes greift weiter auf die Vergangenheit zurück.  Ähnlich wie selbst die Person von Henryk Siemiradzki, der seine Werke in der zweiten Hälfte des XIX. Jahrhunderts schuf.</p>
                <p>An den Punkten dieses Pfads sind die Fragen bzgl. der Parkgeschichte und des Schaffens von  Siemiradzki zu beantworten.</p>

                <p>QR-Codes <IonIcon icon={qrCode} /> sind auf den an markierten Punkten im Park aufgestellten Tafeln angebracht. Diese sind zu scannen, um eine Frage betreffend diesen Punkt kennenzulernen.</p>

                {punktySciezki_2}

              </div>
            }

            {(lang === "en" || lang === "en-EN") &&
              <div>
                <h1>{paths[2]}</h1>
                <p>The Park was created at the beginning of the 20th century, but its history goes much further back in time. Just like the figure of Henryk Siemiradzki, who created his works in the second half of the 19th century.</p>
                <p>At the points along the path you must answer questions related to the history of the Park and Siemiradzki's work.</p>
                <p>Scan the QR codes <IonIcon icon={qrCode} /> on the plaques at the designated points of the Park to find out the question related to the point.</p>

                {punktySciezki_2}

              </div>
            }
              
            </div>
          </div>
        }

        {(path === '3') &&
          <div>
            <img src="/assets/techniczna.jpg" />
            <div className="opty-padding-page">
            {(lang === "pl" || lang === "pl-PL") &&
              <div>
                <h1>{paths[3]}</h1>
                <p>Park Siemiradzkiego po rewitalizacji w 2020 roku został wyposażony m.in. w szereg instalacji edukacyjnych.</p>
                <p>Pytania, które zadajemy na tej ścieżce są związane z m.in. z zasadami działania tych urządzeń oraz wykorzystywanymi przez te urządzenia zjawiskami fizycznymi.</p>

                <p>Skanuj kody QR <IonIcon icon={qrCode} /> znajdujące się na tabliczkach w wyznaczonych punktach Parku, aby poznać pytanie związane z punktem.</p>

                <h3>Punkty ścieżki:</h3>
                
                {punktySciezki_3}
                
              </div>
            }
            
            {(lang === "de" || lang === "de-DE") &&
              <div>
                <h1>{paths[3]}</h1>
                <p>Der Siemiradzki-Park wurde nach Sanierung im Jahre 2020  u. a. mit einer Reihe von Bildungsanlagen ausgestattet.</p>
                <p>Fragen, die wir auf diesem Pfad stellen, hängen u. a. mit den Funktionsgrundsätzen dieser Anlagen als  auch mit den von diesen Anlagen beanspruchten physikalischen Phänomenen zusammen.</p>

                <p>QR-Codes <IonIcon icon={qrCode} /> sind auf den an markierten Punkten im Park aufgestellten Tafeln angebracht. Diese sind zu scannen, um eine Frage betreffend diesen Punkt kennenzulernen.</p>

                {punktySciezki_3}

              </div>
            }

            {(lang === "en" || lang === "en-EN") &&
              <div>
                <h1>{paths[3]}</h1>
                <p>After its revitalisation in 2020, Siemiradzki Park has been equipped with, among other things, a number of educational installations.</p>
                <p>The questions asked on this path are related to, among others, the principles of operation of these devices and the physical phenomena they use.</p>

                <p>Scan the QR codes <IonIcon icon={qrCode} /> on the plaques at designated points in the Park to find out the question related to the point.</p>

                {punktySciezki_3}

              </div>
            }
              
            </div>
          </div>
        }
        
        <IonGrid id="footerModalBtn">

          <IonRow>
            
          {/* {consoleLog('path',path)} */}
          
            {sharedValues.game !== 'start' && path !== undefined &&
              <IonCol size="12" size-sm="12" size-xs ="12">
                <IonButton expand="block" /* routerLink={"/start/"+path+"/1"} */ onClick={() => {
                  const newSharedValues = {
                    ...sharedValues,
                    game: 'start',
                    gamePath: path,
                    answers: {
                      ...sharedValues.answers,
                      [path]:{}
                    },
                    point: 1
                  }
                  setSharedValues(newSharedValues);
                  consoleLog('Intro:',sharedValues);

                  history.push("/start/"+path+"/1/0");
                  //history.push("/start/");

                }}>{dict[lang]['zacznij_gre']}</IonButton>
              </IonCol>
            }
            
            {consoleLog('gamePath',sharedValues.gamePath)}
            
            {sharedValues.game === 'start' && sharedValues.gamePath !== undefined &&
              <IonCol size="12" size-sm="12" size-xs ="12">
                <IonButton expand="block" routerLink={"/start/"+sharedValues.gamePath+"/1/0"} onClick={() => {
                  const newSharedValues = {
                    ...sharedValues,
                    intro: false
                  }
                  setSharedValues(newSharedValues);
                }}>{dict[lang]['wroc_do_gry']}
                {sharedValues.gamePath &&
                  <span>&nbsp;({paths[sharedValues.gamePath]})</span>
                }  
                </IonButton>
              </IonCol>
            }

          </IonRow>
        </IonGrid>
      
      {/* </IonModal> */}
      </IonContent>
      
    </IonPage>
  );
}
export default Intro;