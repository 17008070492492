export const menuPL = {
  poczatek: 'Początek gry',
  skanuj: 'Skanuj kod QR',
  zasady: 'Zasady gry',
  gry: 'Moje gry',
  wyniki: 'Aktualny wynik',
  najgry: 'Najnowsze wyniki',
  henryk: 'Henryk Siemiradzki',
  historia: 'Historia Parku',
  news: 'Aktualności',
  info: 'O aplikacji',
  galeria: 'Galeria zdjęć',
  error: 'Zgłoś błąd',
  zielony: 'Zielony numer alarmowy'
}

export const pathsPL = {
  1: 'Ścieżka botaniczna',
  2: 'Ścieżka historyczna',
  3: 'Ścieżka sensoryczna'
}

export const pointsPL = [
  {},
  {
    1: 'punkt 1',
    2: 'punkt 2',
    3: 'punkt 3',
    4: 'punkt 4',
    5: 'punkt 5'
  },
  {
    1: 'punkt 1',
    2: 'punkt 2',
    3: 'punkt 3',
    4: 'punkt 4',
    5: 'punkt 5'
  },
  {
    1: 'punkt 1',
    2: 'punkt 2',
    3: 'punkt 3',
    4: 'punkt 4',
    5: 'punkt 5'
  }
];

export const pointsNamesPL = [
  {},
  {
    1: 'Miłorząb dwuklapowy',
    2: 'Dąb czerwony',
    3: 'Brzoza brodawkowata',
    4: 'Lipa szerokolistna',
    5: 'Topola euroamerykańska'
  },
  {
    1: 'Jesion wyniosły',
    2: 'Klon jawor',
    3: 'Irga Dammera',
    4: 'Kasztanowiec',
    5: 'Topola biała'
  },
  {
    1: 'Wiąz szypułkowy',
    2: 'Bez czarny',
    3: 'Porzeczka alpejska',
    4: 'Lipa krymska',
    5: 'Grab pospolity'
  }
];

