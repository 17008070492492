import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../Context';
import AppTimerContext from '../ContextTimer';
import AppQRContext from '../ContextQR';

import { BarcodeScanner, SupportedFormat } from '@capacitor-community/barcode-scanner';
import QrReader from 'react-qr-reader';

import { IonButton, IonCol, IonContent, IonGrid, IonHeader, IonImg, IonModal, IonPage, IonRow, isPlatform } from '@ionic/react';
import { consoleLog, dict, lang, pathPoints, paths, points } from './Config';
import { platform } from 'os';
import { useHistory } from 'react-router';


const QRcodeReader: React.FC = () => {

  const { sharedValues, setSharedValues } = useContext(AppContext);
  const { sharedQR, setSharedQR }         = useContext(AppQRContext);

  const [QRcode, setQRcode]       = useState<string | undefined>('');
  const [pointNr, setPointNr]     = useState<string | undefined>('');
  const [QRpath, setQRpath]       = useState<string>('');
  const [path, setPath]           = useState<string>('');
  const [point, setPoint]         = useState<number>(1);
  const [pointName, setPointName] = useState<string>('');
  const [QRempty, setQRempty]     = useState<boolean>(false);

  const [scanQR, setScanQR]       = useState<boolean>(true);

  const [PWAQR, setPWAQR]         = useState<any>('');

  let history = useHistory();
  
  // PWA
  const handleScan = (data:any) => {
    if (data) {

      /* const QR = {
        ...PWAQR,
        hasContent:'yes',
        content:data
      } */

      setPWAQR(data);
      startScan();
    }
  }
  const handleError = (err:any) => {
    console.error(err)
  }


  useEffect(() => {

    //consoleLog('QR scanner loading','');
    
    if (isPlatform('hybrid')) {
      didUserGrantPermission();
    }
    
    //sessionStorage.setItem('scanQR','true');
    
    //JSON.parse(sessionStorage.getItem('scanQR'))

    /* const newSharedValues = {
      ...sharedValues,
      scanQR: true
    }
    setSharedValues(newSharedValues); */

    /* const newSharedTimer = {
      ...sharedTimer,
      scanQR: true
    }
    setSharedTimer(newSharedTimer); */

    const newSharedQR = {
      ...sharedQR,
      scanQR: true
    }
    setSharedQR(newSharedQR);

    //askUser();
    
    return () => {
      // Anything in here is fired on component unmount.

      if (isPlatform('hybrid')) {
        BarcodeScanner.showBackground();
        BarcodeScanner.stopScan();
      }

      const newSharedQR = {
        ...sharedQR,
        scanQR: false
      }
      setSharedQR(newSharedQR);
      
      //consoleLog('QR unmount QR reader','')
    }
  },[]);

  /* useEffect(() => {
    setScanQR(sessionStorage.getItem('scanQR') === 'true');
  },[sessionStorage.getItem('scanQR')]); */

  /* useEffect(() => {

    if (sharedValues.scanQR === true) {
      consoleLog('QR scanner loading','scanQR');
      askUser();
    }

  },[sharedValues.scanQR]); */

  useEffect(() => {
    
    //consoleLog('QR scanner loading','scanQR');
    
    setQRpath('');
    setQRcode('');
    setPath('');
    setPointName('');
    setQRempty(false);
   
    if (sharedQR.scanQR === true) {
      askUser();
    }

  },[sharedQR.scanQR, window.location.pathname]);

  const didUserGrantPermission = async () => {

    // check if user already granted permission
    const status = await BarcodeScanner.checkPermission({ force: false });
    
    //consoleLog('QR status:',status);
    //console.log('camera perm:',JSON.stringify(status));
    
    if (status.granted) {
      // user granted permission
      return true;
    }
  
    if (status.denied) {
      // user denied permission
      // redirect user to app settings if they want to grant it anyway
      const c = window.confirm(
        'W celu skanowania kodów QR aplikacja potrzebuje dostępu do aparatu fotograficznego (0).',
      );
      if (c) {
        BarcodeScanner.openAppSettings();
      }
      // return false;
    }
  
    if (status.asked) {
      // system requested the user for permission during this call
      // only possible when force set to true
    }
  
    if (status.neverAsked) {
      // user has not been requested this permission before
      // it is advised to show the user some sort of prompt
      // this way you will not waste your only chance to ask for the permission
      const c = window.confirm(
        'W celu skanowania kodów QR aplikacja potrzebuje dostępu do aparatu fotograficznego.',
      );
      if (!c) {
        return false;
      }
    }
  
    if (status.restricted || status.unknown) {
      // ios only
      // probably means the permission has been denied
      return false;
    }
  
    // user has not denied permission
    // but the user also has not yet granted the permission
    // so request it
    const statusRequest = await BarcodeScanner.checkPermission({ force: true });
  
    if (statusRequest.asked) {
      // system requested the user for permission during this call
      // only possible when force set to true
    }
  
    if (statusRequest.granted) {
      // the user did grant the permission now
      return true;
    }
  
    // user did not grant the permission, so he must have declined the request
    return false;
  };
  
  //didUserGrantPermission();

  const prepare = () => {
    BarcodeScanner.prepare();
  };
  
  const startScan = async () => {

    let result:any;

    if(isPlatform('hybrid')) {
      BarcodeScanner.hideBackground();
      result = await BarcodeScanner.startScan();
    } else {
      result = {
        content:PWAQR,
        hasContent: 'yes'
      };
      consoleLog('result.content',result.content);
    }

    if (result.hasContent && result.content !== undefined) {
      
      consoleLog('QR value:',result.content);
      consoleLog('QR value nr:',parseInt(result.content!));

      //let pointNr = result.content!.match(/^\/([0-9]+)$\.html/);
      //consoleLog('QR point nr:',pointNr);

      const www = result.content;
      let pointNr = www!.replace('http://parksiemiradzkiego.pl/qr/','');
          pointNr = pointNr.replace('.html','');
      consoleLog('QR pointNr',pointNr);

      setPointNr(pointNr);
      setQRcode(result.content);


      // szukamy w pathPoints
      let thisQR2Point:any = {};
      let i:any = 0;
      
      pathPoints.map((singlePath,index) => {
        
        i++;

        thisQR2Point = singlePath.find((point:any) => point.QR === result.content);
        
        //console.log('singlePath',singlePath+' | '+index);

        if (thisQR2Point !== undefined) {

          consoleLog('QR thisQR2Point',thisQR2Point);

          setQRpath(i.toString()+'/'+thisQR2Point.id);
          consoleLog('QR QRpath',QRpath+' - '+i+' - '+thisQR2Point.id);
          setQRempty(true);
          setPath(i.toString());
          //consoleLog('QR path, i, point',path+' - '+i+' - '+thisQR2Point.id);
          setPoint(thisQR2Point.id);
          setPointName(points[i.toString()][thisQR2Point.id]);
          
          //setPointName(points[path][thisQR2Point.id]);
          //consoleLog('QR pointName',pointName);
          
          consoleLog('QR path - sharedValues.path - i',path+'-'+sharedValues.path+'-'+i.toString());

          //if (sharedValues.path === path) {
          if (sharedValues.path === i.toString()) {
            const newSharedValues = {
              ...sharedValues,
              path: i.toString(),
              point: thisQR2Point.id,
              question: thisQR2Point.id
            }
            setSharedValues(newSharedValues);
          }
          
          if (sharedValues.debug === false) {
            
            /* setTimeout(() => {
              
              console.log('after scan',sharedValues);
              
              setQRcode('');
              setQRpath('');
              setPointName('');
              setQRempty(false);
              stopScan();

              const newSharedQR = {
                ...sharedQR,
                scanQR: false
              }
              setSharedQR(newSharedQR);
              
              history.push('/start/'+i.toString()+'/'+thisQR2Point.id);
              
            }, 2000); */

          }
          //consoleLog('ppp:',path);
          //setPointName(points[path][thisQR2Point.id]);
        }
      });

      document.body.classList.remove("contrast");
      window.localStorage.setItem('contrast','');

    } else {
      consoleLog('QR result:',result);
    }
  };
  
  const stopScan = () => {
    
    //setQRcode('');
    
    //if (sharedValues.debug === true) {
      /* const newSharedValues = {
        ...sharedValues,
        scanQR: false
      }
      setSharedValues(newSharedValues); */
    //}
    

    consoleLog('QR stop scan','');

    if(!isPlatform('hybrid')) {
      consoleLog('QR PWA?',platform)
      /* setQRcode('');
      //setQRpath('');
      setPath('');
      setPointName('');
      setQRempty(false);

      const newSharedQR = {
        ...sharedQR,
        scanQR: false
      }
      setSharedQR(newSharedQR); */



      /* if(QRpath !== '') {
        history.push('/start/'+QRpath);
      } else {
        //history.push('/');
        history.goBack();
      }
      resetValuesOnExit();

      return; */
    }
    
    /* setQRcode('');
    //setQRpath('');
    setPath('');
    setPointName('');
    setQRempty(false);

    const newSharedQR = {
      ...sharedQR,
      scanQR: false
    }
    setSharedQR(newSharedQR); */

    if(isPlatform('hybrid')) {
      BarcodeScanner.showBackground();
      BarcodeScanner.stopScan();
    }
    
    resetValuesOnExit();

    if(QRpath !== '') {
      history.push('/start/'+QRpath+'/'+point);
    } else {
      //history.push('/');
      history.goBack();
    }

  };
  
  const askUser = () => {
    
    if(!isPlatform('hybrid')) {
      //localStorage.setItem('newPath','2');
      //localStorage.setItem('newPoint','1');
      
      /* consoleLog('PWA?',platform);
      setQRempty(true);
      setQRpath('2/4/4');
      setPath('2');
      
      consoleLog('PWA QRpath 0',QRpath);

      setPointName(points[2][4]);
      consoleLog('pointName',pointName);

      const www = 'https://parksiemiradzkiego.pl/qr/d.html';
      let pointNr = www.replace('https://parksiemiradzkiego.pl/qr/','');
          pointNr = pointNr.replace('.html','');
      consoleLog('pointNr',pointNr);
      setPointNr(pointNr);

      setQRcode('24');
      const newSharedValues = {
        ...sharedValues,
        path: '2',
        point: 4,
        question: 4
      }
      setSharedValues(newSharedValues);

      consoleLog('PWA QRpath 1',QRpath); */

      /* setTimeout(() => {
  
        console.log('PWA sharedValues after scan',sharedValues);

        if (sharedValues.debug === false) {
          
          setQRcode('');
          const newSharedValues = {
            ...sharedValues,
            question: 3
          }
          setSharedValues(newSharedValues);

          console.log('PWA after scan',sharedValues);
          console.log('PWA QRpath 2',QRpath);
          history.push('/start/'+QRpath);
          
        }

      },1000); */

      return;
    }

    prepare();
  
    //const c:boolean = window.confirm('Skanować kod QR?');
    
    const c:boolean = true;
    
    if (c) {
      //didUserGrantPermission();
      startScan();
    } else {
      stopScan();
    }
    
  };
  
  const resetValuesOnExit = () => {
    setQRcode('');
    setPath('');
    setPointName('');
    setQRempty(false);

    const newSharedQR = {
      ...sharedQR,
      scanQR: false
    }
    setSharedQR(newSharedQR);
    
    /* if (sharedValues.gamePath) {
      const newSharedValues = {
        ...sharedValues,
        path: sharedValues.gamePath
      }
      setSharedValues(newSharedValues);
    } */
  }

  {/* <IonModal 
        isOpen={sharedQR.scanQR} 
        cssClass={sharedQR.scanQR ? "scan" : "qr"}
        onDidDismiss={() => {
          
        }}
      > */}

  return(
    <IonPage>
      {/* {sharedValues.scanQR === true && */}
      
      {sharedQR.scanQR === true &&
      
      <IonContent id="QR" className="ion-padding trans">

        <IonButton className="scan-close-btn" /* routerLink={"/start/"+QRpath} */ onClick={() => {
          
          /* setQRcode('');
          setQRpath('');
          setPath('');
          setPointName('');
          setQRempty(false); */

          resetValuesOnExit();
          stopScan();

          //setScanQR(false);
          
          /* const newSharedValues = {
            ...sharedValues,
            scanQR: false
          }
          setSharedValues(newSharedValues); */

          /* const newSharedQR = {
            ...sharedQR,
            scanQR: false
          }
          setSharedQR(newSharedQR); */
          
          }} expand="block">X</IonButton>
        
        <p className="qr">{/* QRcode */}<br /><br /><br />
        
        {/* QRpath: {QRpath}, QRempty: {JSON.stringify(QRempty)} */}</p>
        
        
        
        {/* {consoleLog('QR paths.QRpath',paths)}
        {consoleLog('QR paths.QRpath',paths[path]+path)} */}

        {paths[path] !== '' &&
          <>
            <h3 className="pointName">{paths[path]}</h3>
          </>
        }

        {pointName !== '' &&
          <>
            <h3 className="pointName">{pointName}</h3>
          </>
        }

        {QRpath !== '' &&
          <>
            {/* <h3 className="pointName">{QRpath}</h3> */}
          </>
        }
        
        {sharedValues.gamePath === sharedValues.path && QRpath !== '' && 
          <>
            <p className="pointName">{dict[lang]['przejdz_do_punktu_i_odp']}</p>
          </>
        }

        {sharedValues.game && sharedValues.gamePath && sharedValues.gamePath !== path && QRempty !== false &&
          <div>
            
            {lang === 'pl' &&
              <>
                <h3 className="pointName">To jest punkt spoza ścieżki,<br />na której prowadzisz grę!</h3>
                <p className="pointName">Aby rozpocząć nową grę należy najpierw zakończyć grę na obecnej ścieżce<br />
                (<span className='ion-text-lowercase'>{paths[sharedValues.path]}</span>).</p>
              </>
            }
            
            {lang === 'en' &&
              <>
                <h3 className="pointName">This is a point off the trail<br />on which you are playing!</h3>
                <p className="pointName">To start a new game, you must first end the game on the current trail<br />
                (<span className='ion-text-lowercase'>{paths[sharedValues.path]}</span>).</p>
              </>
            }

            {lang === 'de' &&
              <>
                <h3 className="pointName">DE To jest punkt spoza ścieżki,<br />na której prowadzisz grę!</h3>
                <p className="pointName">Aby rozpocząć nową grę należy najpierw zakończyć grę na obecnej ścieżce<br />
                (<span className='ion-text-lowercase'>{paths[sharedValues.path]}</span>).</p>
              </>
            }
            <IonButton /* routerLink={"/start/"+sharedValues.gamePath} */ onClick={() => {
                
                /* const newSharedValues = {
                  ...sharedValues,
                  path: sharedValues.gamePath
                }
                setSharedValues(newSharedValues); */

                resetValuesOnExit();
                history.push("/start/"+sharedValues.gamePath+"/1");

              }}
              expand="block">przejdź do gry, aby ją zakończyć
            </IonButton>

          </div>
        }

        {QRpath !== '' && QRempty === true &&
          <IonImg src={"/assets/points/"+pointNr?.toUpperCase()+".jpg"} />
        }

        <IonGrid fixed={true} className="scan-btn">

          <IonRow>
            
            {QRcode === '' && sharedValues.debug === true &&
              <IonCol size="6" size-sm="6" size-xs ="12">
                <IonButton onClick={askUser} expand="block">skanuj kod QR</IonButton>
              </IonCol>
            }
            
            {QRpath !== '' && QRempty === true &&
              
              <IonCol size="12" size-sm="12" size-xs ="12">

                <IonButton /* routerLink={"/start/"+QRpath} */ onClick={() => {
                    resetValuesOnExit();
                    stopScan();
                  }} 
                  expand="block">
                  {dict[lang]['przejdz_do_punktu']}
                </IonButton>

              </IonCol>
              
            }

          </IonRow>
          
        </IonGrid>
        
        {/* {consoleLog('pointName',pointName+'|'+typeof(pointName))} */}
        
        {isPlatform('hybrid') && pointName !== undefined && pointName === '' &&
          <div className="scan-box">
            {QRcode !== '' && QRpath === '' && QRempty === false &&
              <h3 className="pointName"><br />Ten punkt nie jest<br />opisany w grze!</h3>
            }
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        }
        
        {!isPlatform('hybrid') && pointName !== undefined && pointName === '' &&
        
          <div>
            <QrReader
              delay={300}
              onError={handleError}
              onScan={handleScan}
              style={{ width: '100%', height:'100%' }}
            />
            <p>{PWAQR}</p>
          </div>

        }
      </IonContent>
      
    }

    {/* </IonModal> */}

    {/* {sharedValues.scanQR !== true &&
      <IonButton expand="block"
      onClick={() => {
        const newSharedValues = {
          ...sharedValues,
          scanQR: true
        }
        setSharedValues(newSharedValues);
      }}>skanuj kod QR</IonButton>
    } */}

    
    </IonPage>
  );
}
export default QRcodeReader;

/*  */