import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { lang } from '../components/Config';

const Historia: React.FC = () => {

  return(
    <IonPage>
      
      <IonContent className="ion-padding-page">
        <img src="/assets/420-Park-Siemiradzkiego-scaled.jpg" />
        
        {(lang === "pl" || lang === "pl-PL") &&
          <div>
            <h1>Historia Parku Siemiradzkiego</h1>

            <p>Położony w centrum Gorzowa Park Siemiradzkiego powstał - dzięki staraniom Towarzystwa Upiększania Miasta - w latach 1869-1908.</p>

            <p>Swoją aktualną nazwę park otrzymał w 1945 roku. Przed II wojną światowa był to Quilitzpark (Friedrich Quilitz był jednym z fundatorów parku).</p>
          </div>
        }

        {(lang === "en" || lang === "en-EN") &&
          <div>
            <h1>History of Siemiradzki Park</h1>

            <p>Located in the center of Gorzow, Siemiradzki Park was created between 1869 and 1908 thanks to the efforts of the City Beautification Society.</p>

            <p>The park received its current name in 1945. Before World War II it was called Quilitzpark (Friedrich Quilitz was one of the founders of the park).</p>
          </div>
        }

        {(lang === "de" || lang === "de-DE") &&
          <div>
            <h1>Geschichte des Siemiradzki-Parks</h1>

            <p>Gelegen im Standzentrum von Gorzów entstand der Siemiradzki-Park - dank der Bemühungen von der Stadtbeschönerungsgesellschaft - in den Jahren 1869-1908.</p>

            <p>Seinen aktuellen Namen erhielt der Park im Jahre 1945. Vor dem II. Weltkrieg war es der Quilitzpark (Friedrich Quilitz war einer der Parkstifter).</p>
          </div>
        }

        <div className="footer"><img src="/assets/stopka-600x100.png" /></div>
      </IonContent>
    </IonPage>
  );
}
export default Historia;