import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { lang } from '../components/Config';

const Henryk: React.FC = () => {

  return(
    <IonPage>
      
      <IonContent className="ion-padding-page">
        <img src="/assets/Henryk_Siemiradzki.jpg" />

        {lang === 'pl' &&
        <div>
          <address>(Henryk Siemiradzki, Wikipedia, domena publiczna)</address>

          <h1>Henryk Siemiradzki</h1>
          

          <p>Jego rodzicami byli Hipolit i Michalina z Pruszyńskich (siostra Anny Ciundziewickiej). Ojciec Henryka był wysokim oficerem armii Imperium Rosyjskiego, jednak dzieci zostały wychowane w tradycji polskiej, katolickiej. Rodzina posiadała w Charkowie dom, od którego wzięła nazwę ulica, później nazywana Siemiradzką, obecnie Siemigradzką. Na życzenie ojca ukończył studia na Wydziale Matematyczno-Przyrodniczym Uniwersytetu Charkowskiego, uzyskując stopień kandydata nauk przyrodniczych na podstawie dysertacji O instynkcie owadów. Następnie rozpoczął studia malarskie na Cesarskiej Akademii Sztuk Pięknych w Petersburgu. W 1871 ukończył studia i uzyskał 6-letnie stypendium rządowe. Wyjechał do Monachium, odwiedzając po drodze po raz pierwszy w życiu Kraków. W Monachium spotkał wielu polskich malarzy, a wśród nich Józefa Brandta i Stanisława Witkiewicza.</p>

          <p>W 1872 wyjechał do Włoch. Zwiedził Wenecję, Florencję, Neapol, miał okazję zobaczyć wybuch Wezuwiusza, trafił w końcu do Rzymu, gdzie zamieszkał w pobliżu pl. Hiszpańskiego. W 1873 ożenił się ze swoją 18-letnią kuzynką Marią Pruszyńską z Koroleszczewicz nad Świsłoczą. Mieli czworo dzieci. W 1876 rodzina Siemiradzkich zamieszkała we własnej willi. W 1884 kupił dworek w Strzałkowie koło Radomska, gdzie przez wiele lat spędzał, wraz z rodziną, letnie wakacje.</p>

          <p>W 1901 zachorował, stracił mowę. Jesienią spotkał się jeszcze w Łodzi z Henrykiem Sienkiewiczem. Na krótko przed śmiercią, za zgodą lekarzy, wyjechał do Strzałkowa i tam zmarł. Pochowany został na cmentarzu Powązkowskim (kwatera 67-6-1/2). 24 września 1903 r. prochy przeniesiono do krypty zasłużonych na krakowskiej Skałce.</p>

          <p>Był członkiem wielu akademii europejskich. Tematykę swych dzieł czerpał z antyku. Malował monumentalne, teatralne kompozycje. W 1879 r. ofiarował obraz Pochodnie Nerona miastu Kraków jako pierwszy obraz do krakowskiego Muzeum Narodowego.</p>

          <p>Był autorem wielu obrazów, a także kurtyn Teatru Miejskiego w Krakowie (1894) i Teatru Wielkiego we Lwowie (1900).</p>

          <p>Był członkiem honorowym Poznańskiego Towarzystwa Przyjaciół Nauk] i kawalerem Legii Honorowej, Orderu Korony Włoskiej, Orderu św. Stanisława III klasy, komandorem Orderu Świętych Maurycego i Łazarza. (żródło: Wikipedia)</p>
          
          <p><i>Henryk Siemiradzki [online]. Wikipedia : wolna encyklopedia, 2021-11-30 17:51Z [dostęp: 2021-12-06 20:35Z]. Dostępny w Internecie: //pl.wikipedia.org/w/index.php?title=Henryk_Siemiradzki&oldid=65488281</i> </p>
        </div>
        }

        {lang === 'en' &&
        <div>
          <address>(From Wikipedia, the free encyclopedia)</address>
          
          <h1>Henryk Siemiradzki</h1>
          
          <p>Henryk Hektor Siemiradzki (24 October 1843 – 23 August 1902) was a Polish painter based in Rome, best remembered for his monumental academic art. He was particularly known for his depictions of scenes from the ancient Greek-Roman world and the New Testament, owned by many national galleries of Europe.</p>

          <p>Many of his paintings depict scenes from antiquity, often the sunlit pastoral scenes or compositions presenting the lives of early Christians. He also painted biblical and historical scenes, landscapes, and portraits. His best-known works include monumental curtains for the Lviv (Lwów) Theatre of Opera and for the Juliusz Słowacki Theatre in Kraków.</p>

          <p>Siemiradzki was born to Hipolit Siemiradzki, a Polish noble and an officer of the Imperial Russian Army (made general in 1871) and Michalina (née Prószyńska) in Belgorod (now Chuhuiv Raion, Kharkiv oblast, Ukraine), near the city of Kharkiv, where his father's regiment was stationed. The family had origins in Radom land and derived its name from the village of Siemiradz. One of the branches settled near Navahrudak (Nowogródek) in the late 17th century. Henryk's grandfather held the post of podkomorzy in Nowogródek powiat. His parents were close friends with Adam Mickiewicz's family. He studied at Kharkiv Gymnasium where he first learned painting under the local schoolteacher, D.I. Bezperchy, a former student of Karl Briullov. He entered the physics-mathematics school of Kharkiv University and studied natural sciences there with great interest, but also continued to paint.</p>

          <p>After graduating from university with a Kandidat degree he abandoned his scientific career and moved to Saint Petersburg to study painting at the Imperial Academy of Arts from 1864 to 1870. Upon his graduation he was awarded a gold medal. In 1870–1871 he studied under Karl von Piloty in Munich on a grant from the Academy. In 1872 he moved to Rome and later built a studio there on Via Gaeta, while spending summers at his estate in Strzałków near Częstochowa.</p>

          <p>In 1873 he received the title of Academician of the Imperial Academy of Arts for his painting Christ and a Sinner, based on a verse Sinner written by Aleksey Tolstoy. In 1878 he received the French National Order of the Legion of Honour and a gold medal at the Paris World's Fair for the painting Flower Vase. In 1876–1879 Siemiradzki worked on frescoes for the Cathedral of Christ the Saviour (Moscow) among his other large-scale projects. In 1879 he offered one of his best-known works, the enormous Pochodnie Nerona (Nero's torches), painted around 1876, to the newly formed Polish National Museum. The artwork is on display at the Siemiradzki Room of the Sukiennice Museum in the Kraków Old Town, the most popular branch of the museum. Around 1893 Siemiradzki worked on two large paintings for the State Historical Museum (Moscow) and in 1894 produced his monumental curtain for the Juliusz Słowacki Theatre in Kraków.</p>

          <p>He died in Strzałków in 1902 and was buried originally in Warsaw, but later his remains were moved to the national Pantheon on Skałka in Kraków.</p>

          <p><i>Wikipedia contributors. (2021, December 26). Henryk Siemiradzki. In Wikipedia, The Free Encyclopedia. Retrieved 12:59, December 27, 2021, from https://en.wikipedia.org/w/index.php?title=Henryk_Siemiradzki&oldid=1062194632</i></p>
        </div>
        }

        {lang === 'de' &&
        <div>
          <h1>Henryk Siemiradzki</h1>

          <p>Siemiradzki wurde in dem kleinen Dorf Nowobelgorod (heute die Siedlung städtischen Typs Petschenihy 50 km östlich von Charkiw) geboren. Als er vier Jahre alt war, zog seine Familie mit ihm nach Charkiw.</p>

          <p>Im Jahre 1860 studierte Siemiradzki auf Wunsch seines Vaters an der physikalischen und mathematischen Fakultät der Universität Charkiw. Im Jahr 1864 schloss er das Studium mit der Diplomarbeit zum Thema „Über die Instinkte der Insekten“ ab. In seiner Studienzeit sammelte Siemiradzki zahlreich Schmetterlinge, welche ihn bis an sein Lebensende interessierten. Während seines Studiums hielt er Kontakt zu Dmytro Bezperchy und nahm Unterricht bei ihm.</p>

          <p>Er bildete sich auf der Akademie zu Petersburg weiter, ging 1870 nach Frankreich und Deutschland, wo er sich 1871 in München aufhielt und hier Schüler Karl Theodor von Pilotys war, und ließ sich 1872 in Rom nieder.</p>

          <p>Im Jahr 1870 kam der Künstler letztmals nach Charkiw, um den Familienbesitz zu verkaufen, darunter einige Gemälde, die heute im Kunstmuseum der Stadt Charkiw ausgestellt sind.</p>

          <p>Er wählte die Motive zu seinen Geschichts- und Genrebildern vorzugsweise aus dem griechischen und römischen Altertum, gelegentlich auch aus dem Neuen Testament. Höchsten Glanz und Reichtum der Farbe suchte er mit genauer Nachbildung des Stofflichen zu verbinden. Er strebte zumeist nach sinnlicher Wirkung, die er durch das Spiel des Sonnenlichts noch zu erhöhen suchte.</p>

          <p>Vor dem Wollust- und Grauenerregenden schreckte er nicht zurück, wofür besonders sein Hauptwerk, Die lebenden Fackeln des Nero (1876, Verbrennung christlicher Märtyrer vor Nero und seinem Hof), bezeichnend ist. Theodor Fontane lässt einen der Protagonisten seines Romans Die Poggenpuhls dieses Bild folgendermaßen beschreiben:</p>

              <p>„[…] das war ein Bild, so groß wie die Segelleinwand von einem Spreekahn oder wohl eigentlich noch größer, und rechts an der Seite, ja, da war ja nun das, was die Gelehrten die ‚Fackeln des Nero‘ nennen, und ein paar brannten auch schon und die andern wurden eben angesteckt. Und was glaubst du nun wohl […], was diese Fackeln eigentlich waren? Christenmenschen waren es, Christenmenschen in Pechlappen einbandagiert, und sahen aus wie Mumien oder wie große Wickelkinder, und dieser Nero, der Veranstalter von all dieser Grässlichkeit, der lag ganz gemütlich auf einem goldnen Wagen, und zwei goldfarbne Löwen davor und der dritte Löwe lag neben ihm, und er kraulte ihn in seiner Mähne, als ob es ein Pudel wäre.“</p>

          <p>Von seinen übrigen Werken sind zu nennen: Alexanders Vertrauen zu seinem Arzt Philippus (1870), Christus und die Ehebrecherin (1871), Das Weib oder die Vase und Der bettelnde Schiffbrüchige (zwei Genrebilder aus dem altrömischen Leben, 1879), Der Schwertertanz (1880), Christus bei Maria und Martha (1886), Glühwürmchen, ein Liebespaar in Pompeji, und Phryne (1888). </p>

          <p><i>Seite „Henryk Siemiradzki“. In: Wikipedia – Die freie Enzyklopädie. Bearbeitungsstand: 1. November 2021, 20:30 UTC. URL: <a href="https://de.wikipedia.org/w/index.php?title=Henryk_Siemiradzki&oldid=216885640">https://de.wikipedia.org/w/index.php?title=Henryk_Siemiradzki&oldid=216885640</a></i></p>
        </div>
        }

        <div className="footer"><img src="/assets/stopka-600x100.png" /></div>
      </IonContent>
    </IonPage>
  );
}
export default Henryk;