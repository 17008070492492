import React, { useEffect, useState } from 'react';
import { IonButton, IonContent, IonPage, isPlatform } from '@ionic/react';
import { Network } from '@capacitor/network';
import { consoleLog } from '../components/Config';

const Offline: React.FC = () => {
  const [isOnline, setIsOnline] = useState<boolean>(true);
  
  Network.addListener('networkStatusChange', (status) => {
    consoleLog("Offline Network status changed", status);
    if (status.connected === false) {
      setIsOnline(false);
    } else {
      setIsOnline(true);
    }
  });

  useEffect(() => {
    const logCurrentNetworkStatus = async () => {
      const status = await Network.getStatus();
      if (status.connected === true) {
        setIsOnline(true);
      } else {
        setIsOnline(false);
      }
      consoleLog('Offline Network status:', status);
    };
    logCurrentNetworkStatus();
  },[]);
  
  return(
    <IonPage id="offlinePage" className={isOnline !== true ? "isOffline" : ""}>
      
        {isOnline !== true &&
          <div id="offline">
            
            <h3>
              Mój Park Siemiradzkiego<br /><br />
              Brak połączenia<br />z Internetem!<br /><br />

              {isPlatform('hybrid') &&
                <IonButton id="back_online" expand="block" onClick={() => window.location.reload()}>
                  <span className="">Odśwież</span>
                </IonButton>
              }
            </h3>

          </div>
        }
      
    </IonPage>
  );
}
export default Offline;