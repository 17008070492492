import { Device } from '@capacitor/device';
import React, { createContext, useEffect, useState } from 'react';
import { consoleLog } from './components/Config';

const AppContext = createContext<any>(undefined);
      AppContext.displayName = 'AppContext';
export default AppContext;

type Props = {
  children: React.ReactNode;
};

export const defaultValues = {
  confirm: true,
  debug: false,
  refresh: '0',
  path: '1',
  point: 1,
  answers: {
    1:{},
    2:{},
    3:{}
  }
}

export const AppProvider = ({ children }: Props) => {

  const [sharedValues, setSharedValues] = useState(defaultValues);

  let localState:any = [];

  const getDeviceLang = async () => {
    const device_lang = await Device.getLanguageCode();
    consoleLog('device_lang',device_lang.value);
    if(device_lang.value) {
      localStorage.setItem('lang',device_lang.value);
    } else {
      localStorage.setItem('lang','pl');
    }
  };
  
  useEffect(() => {

    if (localStorage.getItem('lang') === null) {
      localStorage.setItem('lang','pl');
      getDeviceLang();
    }
    if (localStorage.getItem('game_token') === null) {
      let randomString = Math.random().toString(36);
      localStorage.setItem('game_token',randomString);
    }

    if (localStorage.getItem("sharedValues") !== null) {

      localState = JSON.parse(localStorage.getItem("sharedValues") || '');
      consoleLog('Context localState 0',localState);

      if (localState.path === undefined) {
        localState.path = 1;
      }
    
      if (localState.answers === undefined) {
        localState.answers = {
          1:{},
          2:{},
          3:{},
        }
      }

      /* if (window.location.pathname !== '/QR') {
        localState.scanQR = false;
      } else if (window.location.pathname === '/QR') {
        localState.scanQR = true;
      } */
      
  
      //consoleLog('localState',typeof(localState));
      consoleLog('Context localState 1',localState);
    } else {
      localState = defaultValues;
        
      //localState = [];
      consoleLog('Context localState 2',localState);
    }

    setSharedValues(localState);
  },[]);

  useEffect(() => {
    if (sharedValues) {
      const sharedToLocal = {
        ...sharedValues
      }
      //delete sharedToLocal.isAuthed;
      //consoleLog('context sharedValues:',sharedValues);
      consoleLog('Context sharedToLocal:',sharedToLocal);
      localStorage.setItem("sharedValues", JSON.stringify(sharedToLocal));
    }
  },[sharedValues]);

  let state = {
    sharedValues, setSharedValues
  }
  
  return (
    <AppContext.Provider value={state}>{children}</AppContext.Provider>
  );
};
