import React from 'react';
import { IonContent, IonPage } from '@ionic/react';

const Polityka: React.FC = () => {

  return(
    <IonPage>
      
      <IonContent className="ion-padding-page">
        
        <h1>Polityka prywatności</h1>
        

        <p>Urząd Miasta Gorzowa nie gromadzi żadnych danych opr&oacute;cz tych, kt&oacute;re są konieczne do prawidłowego funkcjonowania aplikacji &quot;M&oacute;j Park Siemiradzkiego&quot;.</p>

        <p>Aplikacja może korzystać z następujących uprawnień:</p>

        <p><strong>Lokalizacja</strong> &ndash; umożliwia (opcjonalnie) wyświetlenie pozycji użytkownika na mapie Parku. Aplikacja do pokazania pozycji użytkownika na mapie Parku korzysta z Google Maps API.</p>

        <p><strong>Dostęp do pamięci urządzenia</strong> &ndash; uprawnienie umożliwia aplikacji zapisywanie danych w pamięci urządzenia. Dzięki temu możliwe jest wykorzystanie części funkcjonalności aplikacji w trybie offline.</p>

        <p><strong>Pełny dostęp do sieci</strong> &ndash; wymagany jest do aktualizacji oraz wyświetlania danych podczas funkcjonowania aplikacji w trybie online.</p>

        <p><strong>Powiadomienia</strong> &ndash; serwer aplikacji może wysyłać użytkownikowi powiadomienia, o ile użytkownik zgodzi się na ich otrzymywanie. W aplikacji za otrzymywanie powiadomień odpowiada zewnętrzna usługa - Firebase Cloud Messaging (polityka prywatności FCM - <a href="https://firebase.google.com/support/privacy">https://firebase.google.com/support/privacy</a>). Identyfikatory urządzeń nadane przez FCM podczas instalacji aplikacji i umożliwiające odpowiednie adresowanie powiadomień, zapisywane są anonimowo na serwerze Urzędu Miasta Gorzowa Wlkp. i nie są udostępniane żadnym podmiotom zewnętrznym.</p>

        <p>Z poziomu aplikacji użytkownik może wywołać usługę wybierania numeru &ndash; dotyczy to tzw. zielonego numeru alarmowego.</p>

        <p><strong>Dostęp do aparatu</strong> &ndash; uprawnienie to potrzebne jest w aplikacji do skanowania kod&oacute;w QR oraz wykonywania zdjęć do wysłania mailem.</p>

        <p><strong>Dostęp do galerii zdjęć</strong> &ndash; wykorzystywany alternatywnie w stosunku do dostępu do aparatu.</p>

        <p>Za pomocą aplikacji użytkownik może wysyłać maile. Podany przez użytkownika aplikacji adres zwrotny jest wykorzystywany jedynie w celu wysłania odpowiedzi i nie jest trwale przechowywany.</p>

        <p>W razie jakichkolwiek pytań lub niejasności prosimy o kontakt na adres: <a href="mailto:kancelaria@um.gorzow.pl">kancelaria@um.gorzow.pl</a></p>
        
        <div className="footer"><img src="/assets/stopka-600x100.png" /></div>
      </IonContent>
    </IonPage>
  );
}
export default Polityka;