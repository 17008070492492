import { useLoadScript } from '@react-google-maps/api';
import React, { useEffect, useContext } from 'react';
import AppContext from '../Context';
import { consoleLog } from './Config';

interface Distance {
  currLat: any
  currLng: any
}

interface Start {
  lat: any
  lng: any
}

const GoogleMapDistance: React.FC<Distance> = ({currLat,currLng}) => {

  const { sharedValues, setSharedValues } = useContext(AppContext);

  /* const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY!
    // ...otherOptions
  }); */

  /* const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyC3xdZU-ZlcRgqsC8353uXWYYlBsfcbuFk'
  }); */

  //console.log('Distance Key',process.env.REACT_APP_GOOGLE_MAPS_DISTANCE_API_KEY);

  //const map = useGoogleMap();

  const getDistance = () => {
    
    // initialize services
    //const geocoder = new window.google.maps.Geocoder();
    const service = new window.google.maps.DistanceMatrixService();

    //const service = map!.DistanceMatrixService();
    //console.log('DistanceMatrixService',process.env.REACT_APP_GOOGLE_MAPS_API_KEY);

    let start_path:Start = {
      lat:52.7362783345334, 
      lng:15.240020892380471
    };

    switch (sharedValues.path) {
      case "1":
        start_path = {
          lat:52.7362783345334, 
          lng:15.240020892380471
        }
      break;
      case "2":
        start_path = {
          lat:52.740531462569784,
          lng:15.238687198985543
        }
      break;
      case "3":
        start_path = {
          lat:52.73792445177897, 
          lng:15.240249585736718
        }
      break;
    }

    let start = start_path;

    /* const start:Start = {
      lat:52.73701100046155,
      lng:15.241040486539235
    }; */

    

    // build request
    /* const origin1      = new window.google.maps.LatLng(currLat, currLng);
    const origin2      = "Greenwich, England";
    const destinationA = "Stockholm, Sweden";
    const destinationB = { lat: 52.73701100046155, lng: 15.241040486539235 }; */

    const origin      = { lat: currLat, lng: currLng };
    const destination = { lat: start.lat, lng: start.lng };
    const request = {
      //origins: [origin1, origin2],
      //destinations: [destinationA, destinationB],

      origins: [origin],
      destinations: [destination],

      travelMode: google.maps.TravelMode.WALKING,
      unitSystem: google.maps.UnitSystem.METRIC,
      avoidHighways: false,
      avoidTolls: false,
    };

    // put request on page
    //(document.getElementById("request") as HTMLDivElement).innerText = JSON.stringify(request, null, 2);

    // get distance matrix response
    service.getDistanceMatrix(request).then((response:any) => {
      
      const newSharedValues = {
        ...sharedValues,
        distanceInfo: response
      }
      setSharedValues(newSharedValues);

      consoleLog('Distance: DISTANCE:',response);

      // put response
      //(document.getElementById("response") as HTMLDivElement).innerText = JSON.stringify(response, null, 2);

      // show on map
      const originList      = response.originAddresses;
      const destinationList = response.destinationAddresses;

      consoleLog('Distance: distance:',originList+' | '+destinationList);
    });
  }

  useEffect(() => {
    getDistance();
    consoleLog('Distance: getDistance start','');
  },[currLat]);

  return(
    <div id="getDistance">
      <div>
        {/* <h3>Request</h3>
        <pre id="request"></pre>
        <h3>Response</h3>
        <pre id="response"></pre> */}
      </div>
    </div>
  );
}
export default GoogleMapDistance;