import React, { Suspense, useContext, useEffect, useState } from 'react';
import { isPlatform, IonIcon, IonLoading, IonMenuToggle, IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import { 
  IonButtons,
  IonButton,
  IonSpinner,
  IonHeader, 
  IonToolbar,
  IonBackButton,
  IonMenuButton
 } from '@ionic/react';

import { cloudOffline, reload, informationCircle, scan, contrast, contrastOutline } from 'ionicons/icons';

import { config, consoleLog, langs } from '../components/Config';

import AppContext from '../Context';
import AppTimerContext from '../ContextTimer';
import AppQRContext from '../ContextQR';

import { useHistory, useLocation } from 'react-router';
import packageJson from "../../package.json";
import { info } from 'console';
import Stoper from './Stoper';
import Timer from './Timer';
import { Network } from '@capacitor/network';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';

let isMobile = false;
// device detection
if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) { 
    isMobile = true;
}

let isWebOnMobile = false;
    isWebOnMobile = (
      document.URL.indexOf('http://localhost') === -1 && 
      document.URL.indexOf('ionic') === -1 && 
      document.URL.indexOf('https://localhost') === -1);

//consoleLog('document.URL',document.URL);
//consoleLog('Mobile?',isMobile);
//consoleLog('isWebOnMobile?',isWebOnMobile);

//consoleLog('Web 1:',document.URL.indexOf('http://localhost') === -1);
//consoleLog('Web 2:',document.URL.indexOf('ionic') === -1);
//consoleLog('Web 3:',document.URL.indexOf('https://localhost') === -1);
// this.isApp = (!document.URL.startsWith(http') || document.URL.startsWith('http://localhost:8080'));
/* {(!isMobile || isPlatform('pwa') || (isMobile && isWebOnMobile)) && <FCM_PWA apiURL={config.apiURL} />}
      {(isMobile && !isPlatform('pwa') && !isWebOnMobile) && <FCM_MOBILE />} */


/* const FCM_PWA    = React.lazy(() => import('./FCM_PWA'));
const FCM_MOBILE = React.lazy(() => import('./FCM_mobile'));

const pwaComponent = () => {
  
  return (
    <Suspense fallback={<div>Loading FCM...<IonSpinner /></div>}>
      {(!isPlatform('hybrid')) && <FCM_PWA apiURL={config.apiURL} />}
      {(isPlatform('hybrid')) && <FCM_MOBILE />}
    </Suspense>
  );

} */

const Header: React.FC = () => {

  const { sharedValues, setSharedValues } = useContext(AppContext);
  const { sharedTimer, setSharedTimer }   = useContext(AppTimerContext);
  const { sharedQR, setSharedQR }         = useContext(AppQRContext);

  const [isOnline, setIsOnline]           = useState<boolean>(true);
  const [refresh, setRefresh]             = useState<boolean>(false);
  const [showLoading, setShowLoading]     = useState<boolean>(true);
  
  let history        = useHistory();
  const locationPath = useLocation();

  const theme = window.localStorage.getItem('theme');
  const [mode, setMode] = useState<any>(theme);

  const contrastTheme = window.localStorage.getItem('contrast');
  const [contrast, setContrast] = useState<any>(contrastTheme);

  useEffect(() => {
    document.addEventListener('ionBackButton', (ev:any) => {
      
      const newSharedQR = {
        ...sharedQR,
        scanQR: false
      }
      setSharedQR(newSharedQR);

      //consoleLog('Header back button',locationPath.pathname);
      
      if (locationPath.pathname === '/QR') {
        //consoleLog('Header location QR',locationPath.pathname);
        
        if (isPlatform('hybrid')) {
          BarcodeScanner.showBackground();
          BarcodeScanner.stopScan();
        }
        
        //history.push('/start/'+sharedValues.path+'/'+sharedValues.point); 
        //history.push('/start/2/1'); 
        //history.goBack(); 
        //history.go(-2); 
        history.replace('/start/'+sharedValues.path+'/'+sharedValues.point);
        return;
      }
      
      /* ev.detail.register(-1, () => {
        if (locationPath.pathname === "/" || locationPath.pathname === "/login") {
          (navigator as any).app.exitApp();
        }
      }); */

    });
  },[]);
  
  
  const changeModeHandler = () => {
    if (mode === "dark") {
      setMode("");
      document.body.classList.remove("dark");
      window.localStorage.setItem('theme','');
    } else {
      setMode("dark");
      document.body.classList.add("dark");
      window.localStorage.setItem('theme','dark');
    }
  };

  const changeContrastHandler = () => {
    if (contrast === "contrast") {
      setContrast("");
      document.body.classList.remove("contrast");
      window.localStorage.setItem('contrast','');
    } else {
      setContrast("contrast");
      document.body.classList.add("contrast");
      window.localStorage.setItem('contrast','contrast');
    }
  };

  
  /* Network.addListener('networkStatusChange', (status) => {
    consoleLog("Network status changed", status);
    if (status.connected === false) {
      setIsOnline(false);
    } else {
      setIsOnline(true);
    }
  }); */

  useEffect(() => {
    //const contrastTheme = window.localStorage.getItem('contrast');
    if (contrastTheme === "contrast") {
      document.body.classList.add("contrast");
    }
  },[])

  useEffect(() => {
    //consoleLog('Header locationPath.pathname:',locationPath.pathname);
    if (locationPath.pathname !== '/QR') {
      const newSharedQR = {
        ...sharedQR,
        scanQR: false
      }
      setSharedQR(newSharedQR);
      
      if (isPlatform('hybrid')) {
        BarcodeScanner.showBackground();
        BarcodeScanner.stopScan();
      }
      
      //consoleLog('Header','scanQR - false');
    } else {
      const newSharedQR = {
        ...sharedQR,
        scanQR: true
      }
      setSharedQR(newSharedQR);
      //consoleLog('Header','scanQR - true');
    }
  },[locationPath.pathname,sharedValues.question]);


  useEffect(() => {
    if (sharedQR.scanQR === false) {
      document.body.classList.remove("scan");
      //consoleLog('Header','scan - remove');
    } else {
      document.body.classList.add("scan");
      //consoleLog('Header','scan - add');
    }
  },[sharedQR.scanQR]);

  /* Platform.pause.subscribe(async () => {
    consoleLog('PLATFORM','pause');
  });
  Platform.resume.subscribe(async () => {
    consoleLog('PLATFORM','resume');
  }); */

  async function checkNetworkAndReload() {
    try {
      const response = await fetch('.');
      if(response.status >= 200 && response.status < 500) {
        window.location.reload();
        return;
      }
    } catch {
      // still offline
    }
    window.setTimeout(checkNetworkAndReload,10000);
  }

  const checkInternetConnection = () => {

    //consoleLog('Header navigator.onLine:', navigator.onLine);
    //consoleLog('Header sharedValues.online:', sharedValues.online);
    
    //setIsOnline(navigator.onLine);
    setIsOnline(sharedValues.online);

    window.ononline = () => {
      //consoleLog("Header Back Online",'');
      setIsOnline(true);
      const newSharedValues = {
        ...sharedValues,
        refresh: '0',
        online: true
      }
      setSharedValues(newSharedValues);
      window.location.reload();
    };
    
    window.onoffline = () => {
      //consoleLog("Header Connection Lost",'')
      setIsOnline(false);
      const newSharedValues = {
        ...sharedValues,
        online: false
      }
      setSharedValues(newSharedValues);
      checkNetworkAndReload();
      history.push('/');
    };
  }

  /* useEffect(() => {
    checkInternetConnection();
  },[isOnline]); */
  
  //let history = useHistory();

  /* const [showPopover, setShowPopover] = useState<{open: boolean, event: Event | undefined}>({
    open: false,
    event: undefined,
  }); */
  
  /* const logout = () => {
    const newSharedValues = {
      ...sharedValues,
      isAuthed: ''
    }
    setSharedValues(newSharedValues);
    setSharedValues('');
    localStorage.removeItem('token');
    localStorage.removeItem('FCM_mobile_token');
    localStorage.removeItem('FCM_PWA_token');
    localStorage.removeItem('sharedValues');
    setTimeout(() => {
      history.push('/')
    },800);
  }; */
  
  const refreshView = () => {
    const newSharedValues = {
      ...sharedValues,
      refresh: '1'
    }
    setSharedValues(newSharedValues);
    setRefresh(true);
    setShowLoading(true);
  };
  /*  className={sharedQR.scanQR ? "scan" : ""} */
  return (

    <IonHeader>
      <IonToolbar>
        
          {/* <Stoper /> */}
        
          <IonButton id="btn_home" routerLink="/" routerDirection="back"> </IonButton>
          
          

          {/* <IonMenuToggle autoHide={false}>
          </IonMenuToggle> */}
          
          <IonButtons id="btn_menu" slot="end">
          
          {/* {isOnline &&
            <IonButton slot="icon-only">
              <IonIcon icon={wifi}></IonIcon>
            </IonButton>
          } */}
          
          <IonButton slot="icon-only" onClick={changeContrastHandler}>
            <IonIcon icon={contrastOutline}></IonIcon>
          </IonButton>

          {/* () => {
            const newSharedValues = {
              ...sharedValues,
              contrast: !sharedValues.contrast
            }
            setSharedValues(newSharedValues);
          } */}

          {(isOnline === false) &&
            <IonButton slot="icon-only">
              <IonIcon icon={cloudOffline}></IonIcon>
            </IonButton>
          }

          
          {/* {(sharedValues.online !== false) &&
            <IonButton slot="icon-only" routerLink="/QR" routerDirection="forward"
              onClick={() => {
                const newSharedValues = {
                  ...sharedValues,
                  scanQR: true
                }
                setSharedValues(newSharedValues);
              }}>
              <IonIcon icon={scan}></IonIcon>
            </IonButton>
          }  */} 
          
            {/* <IonItem>
              <IonSelect 
              name="lang" 
              value={sharedValues.lang}
              cancelText="zamknij" 
              onIonChange={(e) => {
                const newSharedValues = {
                  ...sharedValues,
                  lang: e.detail.value
                }
                setSharedValues(newSharedValues);
                }}>
                
                {langs.map((lang,index) => 
                    <IonSelectOption value={lang} key={index}>{lang}</IonSelectOption>  
                )}

              </IonSelect>
            </IonItem> */}

          {/* <IonButton slot="icon-only" onClick={() => refreshView()}>
            <IonIcon icon={reload}></IonIcon>
          </IonButton> */}

          

          <IonBackButton text="" defaultHref="/" className={locationPath.pathname === '/' ? 'dn' : ''} />

          {(isOnline !== false) &&
            <IonMenuButton />
          }

        </IonButtons>
        
        <span id="ver">v. {packageJson.version}
          {!isPlatform('hybrid') && //PWA
            <span> PWA 7</span>
          }
        </span>
        <Timer />
      </IonToolbar>

      {/* {sharedValues.online !== false &&
        pwaComponent() 
      } */}
      
      {refresh === true &&
        <div className="ion-padding ion-text-center">
          <IonLoading 
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message="Aktualizacja danych..."
            duration={1000}
          />
        </div>
      }

      

    </IonHeader>
    
  );
};
//
export default Header;
