import { getPlatforms, isPlatform } from '@ionic/react';
import { menuPL, pathsPL, pointsPL, pointsNamesPL } from '../local/pl-PL';
import { menuDE, pathsDE, pointsDE, pointsNamesDE } from '../local/de-DE';
import { menuEN, pathsEN, pointsEN, pointsNamesEN } from '../local/en-EN';
import { Device } from '@capacitor/device';
import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../Context';

let debug = true;

export const consoleLog = (name:any,data:any) => {
  if (debug === true) {
    if (process.env.NODE_ENV === 'production') return;
    //if (process.env.NODE_ENV === 'development') return;
    console.log('OPTY '+name,data);
  } else {

  }
}


export const timerFormat = (timer:any) => {
  
  function addZero(i:any) {
    if (i < 10) {i = "0" + i}
    return i;
  }

  let stoperTimeFormat;
  if (timer > 60) {
    const stoperTimeMin    = Math.floor(timer / 60);
    const stoperTimeSec    = addZero(timer - (stoperTimeMin * 60));
    stoperTimeFormat = stoperTimeMin+':'+stoperTimeSec;
  } else {
    stoperTimeFormat ='0:'+timer;
  }
  //consoleLog('timer:',timer);
  return stoperTimeFormat;
}

export const platforms = getPlatforms();
consoleLog('platforms:',platforms);

export const theme = window.localStorage.getItem('theme');

let langLS  = window.localStorage.getItem('lang');
if (langLS === null) {
  langLS = 'pl';
}
export const lang  = langLS.substring(0,2) || 'pl';

consoleLog('lang',lang);

if (theme === "dark") {
  document.body.classList.add("dark");
  window.matchMedia('(prefers-color-scheme: dark)');
}

export const config = {
  /* apiURL: 'https://www.opty.com.pl/park', */
  apiURL: 'https://parksiemiradzkiego.um.gorzow.pl/park',
  theme:   theme
}

let menuLng, pathsLng, pointsLng, pointsNamesLng;

switch (lang!) {
  case "pl":
  case "pl-PL":
  default:
    menuLng   = menuPL!;
    pathsLng  = pathsPL!;
    pointsLng = pointsPL!;
    pointsNamesLng = pointsNamesPL;
  break;
  case "de":
  case "de-DE":
    menuLng   = menuDE!;  
    pathsLng  = pathsDE!;
    pointsLng = pointsDE!;
    pointsNamesLng = pointsNamesDE;
  break;
  case "en":
  case "en-EN":
  case "en-GB":
  case "en-gb":
  case "en-us":
    pathsLng  = pathsEN!;
    menuLng   = menuEN!;
    pointsLng = pointsEN;
    pointsNamesLng = pointsNamesEN;
  break;
}

export const menu:any   = menuLng;
export const paths:any  = pathsLng;
export const points:any = pointsLng;
export const pointsNames:any = pointsNamesLng;;

export const months = [
  "stycznia", 
  "lutego", 
  "marca", 
  "kwietnia", 
  "maja", 
  "czerwca", 
  "lipca", 
  "sierpnia", 
  "września", 
  "października", 
  "listopada", 
  "grudnia"];

/* export const paths = {
  1: 'botaniczna',
  2: 'historyczna',
  3: 'techniczna'
}; */

let ll  = [];
if(!isPlatform('hybrid')) {
  ll  = [
    'pl-PL',
    'en-EN',
    'de-DE'
  ];
} else {
  ll  = [
    'pl',
    'en',
    'de'
  ];
}

export const langs = ll;

export const startPoints:any = 
[
  {
    id:1, // od ul. Zacisze
    lat:52.74141122122182,
    lng:15.237265735708162,
    ico: '/assets/icon/start_l.png'
  },
  {
    id:2, // od ul. Wyszyńskiego
    lat:52.73898038333629,
    lng:15.237822212442023,
    ico: '/assets/icon/start_l.png'
  },
  {
    id:3, // amfiteatr
    lat:52.736231011187044,
    lng:15.239243426067167,
    ico: '/assets/icon/start_l.png'
  },
  {
    id:4, // od ul. Walczaka
    lat:52.738631796163126,
    lng:15.242713955305955,
    ico: '/assets/icon/start_r.png'
  },
  {
    id:5, // od ul. Walczaka 2
    lat:52.73774770055972,
    lng:15.241626036021252,
    ico: '/assets/icon/start_r.png'
  },
  {
    id:6, // od ul. Walczaka 3
    lat:52.7375598620018,
    lng:15.242507114919265,
    ico: '/assets/icon/start_r.png'
  }
];


export const pathPoints:any[] = [];

/* botaniczna */
/* {
    id:2,
    name:points![1][2],
    lat:52.73633861140922,
    lng:15.239967953790154,
    opis: 'Dąb czerwony (18)',
    QR: 'http://parksiemiradzkiego.pl/qr/18.html'
  }, */
pathPoints[1] = [
  {
    id:1,
    name:points![1][1],
    lat:52.7362783345334,
    lng:15.240020892380471,
    opis: pointsNames[1][1]+' (17)',
    QR: 'http://parksiemiradzkiego.pl/qr/17.html'
  },
  {
    id:2,
    name:points![1][2],
    lat:52.73816804733826,
    lng:15.240718972314324,
    opis: pointsNames[1][2]+' (18)',
    QR: 'http://parksiemiradzkiego.pl/qr/18.html'
  },
  {
    id:3,
    name:points![1][3],
    lat:52.73821839025076,
    lng:15.240340780843225,
    opis: pointsNames[1][3]+' (25)',
    QR: 'http://parksiemiradzkiego.pl/qr/25.html'
  },
  {
    id:4,
    name:points![1][4],
    lat:52.74003069637618,
    lng:15.239662181962457,
    opis: pointsNames[1][4]+' (28)',
    QR: 'http://parksiemiradzkiego.pl/qr/28.html'
  },
  {
    id:5,
    name:points![1][5],
    lat:52.74058232888491, 
    lng:15.238742408660595,
    opis: pointsNames[1][5]+' (31)',
    QR: 'http://parksiemiradzkiego.pl/qr/31.html'
  },
  
];

/* historyczna */
pathPoints[2] = [
  {
    id:1,
    name:points![2][1],
    lat:52.740438333783345,
    lng:15.238451562270301,
    opis: pointsNames[2][1]+' (30)',
    QR: 'http://parksiemiradzkiego.pl/qr/30.html'
  },
  {
    id:2,
    name:points![2][2],
    lat:52.73834952514501,
    lng:15.240577485788789,
    opis: pointsNames[2][2]+' (24)',
    QR: 'http://parksiemiradzkiego.pl/qr/24.html'
  },
  {
    id:3,
    name:points![2][3],
    lat:52.736060088685484,
    lng:15.241066988934007,
    opis: pointsNames[2][3]+' (D)',
    QR: 'http://parksiemiradzkiego.pl/qr/d.html'
  },
  {
    id:4,
    name:points![2][4],
    lat:52.73591879659427, 
    lng:15.241638970006433,
    opis: pointsNames[2][4]+' (4)',
    QR: 'http://parksiemiradzkiego.pl/qr/4.html'
  },
  {
    id:5,
    name:points![2][5],
    lat:52.734645929091315,
    lng:15.240685444701638,
    opis: pointsNames[2][5]+' (1)',
    QR: 'http://parksiemiradzkiego.pl/qr/1.html'
  }
];

/* sensoryczna */
pathPoints[3] = [
  {
    id:1,
    name:points![3][1],
    lat:52.73733494489193,
    lng:15.240520488847222,
    opis: pointsNames[3][1]+' (21)', // łuk dźwięku
    QR: 'http://parksiemiradzkiego.pl/qr/21.html'
    
  },{
    id:2,
    name:points![3][2],
    lat:52.737383664771585,
    lng:15.240641188252892,
    opis: pointsNames[3][2]+' (E)',
    QR: 'http://parksiemiradzkiego.pl/qr/e.html'
  },
  {
    id:3,
    name:points![3][3],
    lat:52.73694936952881,
    lng:15.242353326282291,
    opis: pointsNames[3][3]+' (C)', // tembos
    QR: 'http://parksiemiradzkiego.pl/qr/c.html'
    
  },
  {
    id:4,
    name:points![3][4],
    lat:52.7355253687892,
    lng:15.241799231995072,
    opis: pointsNames[3][4]+' (3)',
    QR: 'http://parksiemiradzkiego.pl/qr/3.html'
  },
  {
    id:5,
    name:points![3][5],
    lat:52.735426897677826,
    lng:15.241577941887522,
    opis: pointsNames[3][5]+' (2)',
    QR: 'http://parksiemiradzkiego.pl/qr/2.html'
  },

  /* {
    id:6,
    name:points![3][6],
    lat:52.73740557048895, 
    lng:15.240582563584889,
    opis: 'Pompy wietrzne (20)',
    QR: 'http://parksiemiradzkiego.pl/qr/20.html'
  },
  {
    id:7,
    name:points![3][7],
    lat:52.73805622919147,
    lng:15.24058594951619,
    opis: 'Łuk dźwięku (21)',
    QR: 'http://parksiemiradzkiego.pl/qr/21.html'
  } */
];

export const pathQA:any[] = [];

const pathQA_pl:any[] = [];
const pathQA_en:any[] = [];
const pathQA_de:any[] = [];

const pathQA_lang:any[] = [];

pathQA_pl[1] = [
  {
    id: 1,
    name: 'W którym kraju znajdują się jedyne naturalne stanowiska tego gatunku?',
    odp: [
      'w Japonii',
      'w Chinach',
      'w Polsce',
    ]
  },
  {
    id: 2,
    name: 'Klon pospolity w kilku regionach tego kraju uznawany jest za gatunek inwazyjny i jego uprawa jest prawnie zakazana. Ten kraj to:',
    odp: [
      'Wielka Brytania',
      'Chiny',
      'Stany Zjednoczone Ameryki Płn.',
    ]
  },
  /* {
    id: 2,
    name: 'Ten gatunek dębu zyskał swoją nazwę ze względu na kolor:',
    odp: [
      'kory',
      'liści',
      'żołędzi',
    ]
  }, */
  {
    id: 3,
    name: 'Kora młodych osobników brzozy brodawkowatej ma kolor:',
    odp: [
      'zielonkawy',
      'pomarańczowoczerwony',
      'szaroniebieski',
    ]
  },
  {
    id: 4,
    name: 'Z lipy do celów leczniczych wykorzystuje się:',
    odp: [
      'owoce',
      'liście',
      'kwiatostany',
    ]
  },
  {
    id: 5,
    name: 'Topola euroamerykńska znana jest również pod nazwą:',
    odp: [
      'topola Dickens\'a',
      'topola kanadyjska',
      'topola szara',
    ]
  },
]

pathQA_pl[2] = [
  {
    id: 1,
    name: 'Który okres historyczny uznawany jest za źródło nazwy Kozacza Góra?',
    odp: [
      'II wojna światowa',
      'Wojna siedmioletnia (1756-63)',
      'Rewolucja Październikowa',
    ]
  },
  {
    id: 2,
    name: 'Ceglany budynek na wzgórzu to:',
    odp: [
      'Baszta Kozacka',
      'wieża ciśnień',
      'obserwatorium meteorologiczne',
    ]
  },
  {
    id: 3,
    name: 'Obelisk w Parku Siemiradzkiego upamiętnia:',
    odp: [
      'Powstańców wielkopolskich',
      'Ofiary Rzezi Wołyńskiej',
      'Ofiary I Wojny Światowej',
    ]
  },
  {
    id: 4,
    name: 'Przedwojenna nazwa Parku Siemiradzkiego to:',
    odp: [
      'Stadtpark',
      'Kaiser Wilhelm-Park',
      'Quilitzpark',
    ]
  },
  {
    id: 5,
    name: 'Gustav Seitz - autor rysunków przedstawionych na muralu - sławę wielkiego artysty zdobył jako:',
    odp: [
      'malarz',
      'rzeźbiarz',
      'rysownik',
    ]
  }
];

pathQA_pl[3] = [
  {
    id: 1,
    name: 'Do jakiego typu instrumentów zaliczyłbyś łuk dźwięku?',
    odp: [
      'instrumenty dęte',
      'instrumenty strunowe',
      'instrumenty perkusyjne',
    ]
  },
  {
    id: 2,
    name: 'Jakie zajwisko fizyczne wykorzystują pompy wietrzne do wydobywania dźwięku?',
    odp: [
      'swobodny przepływ powietrza',
      'tarcie',
      'przepływ powietrza pod ciśnieniem',
    ]
  },
  {
    id: 3,
    name: 'Nazwa tego instrumentu (tembos) pochodzi od nazwy zwierzęcia. Którego?',
    odp: [
      'węża',
      'flaminga',
      'słonia',
    ]
  },
  {
    id: 4,
    name: 'Reflektory paraboliczne wykorzystują zjawisko:',
    odp: [
      'zwielokrotnionego echa',
      'skupienia fal dźwiękowych',
      'zakrzywienia fal dźwiękowychc',
    ]
   
  },
  {
    id: 5,
    name: 'Który budynek widać przez teleskop, kiedy patrzymy w kierunku SSE?',
    odp: [
      'Ratusz',
      'Katedrę',
      'Biały Kościółek',
    ]
  },
];

pathQA_en[1] = [
  {
    id: 1,
    name: 'In which country are the only natural stands of this species?',
    odp: [
      'Japan',
      'China',
      'Poland',
    ]
  },
  {
    id: 2,
    name: 'The common maple is considered an invasive species in several regions of this country and its cultivation is prohibited by law. This country is:',
    odp: [
      'Great Britain',
      'China',
      'United States of America',
    ]
  },
  {
    id: 3,
    name: 'The colour of the bark of young specimens of the bearded birch is:',
    odp: [
      'greenish',
      'orange-red',
      'grey-blue',
    ]
  },
  {
    id: 4,
    name: 'The following parts of the lime tree are used for medicinal purposes:',
    odp: [
      'fruit',
      'leaves',
      'inflorescences',
    ]
  },
  {
    id: 5,
    name: 'Euro-American poplar is also known as:',
    odp: [
      'Dickens poplar',
      'Canadian poplar',
      'grey poplar',
    ]
  },
]

pathQA_en[2] = [
  {
    id: 1,
    name: 'Which historical period is considered to be the source of the name Goat Mountain?',
    odp: [
      'the Second World War',
      'the Seven Years\' War (1756-63)',
      'the October Revolution',
    ]
  },
  {
    id: 2,
    name: 'The brick building on the hill is:',
    odp: [
      'the Cossack Tower',
      'water tower',
      'meteorological observatory',
    ]
  },
  {
    id: 3,
    name: 'The obelisk in Siemiradzki Park commemorates:',
    odp: [
      'insurgents of Wielkopolska',
      'victims of the Volhynian Massacre',
      'Victims of World War I',
    ]
  },
  {
    id: 4,
    name: 'The pre-war name of Siemiradzki Park is:',
    odp: [
      'Stadtpark',
      'Kaiser Wilhelm-Park',
      'Quilitzpark',
    ]
  },
  {
    id: 5,
    name: 'Gustav Seitz - the author of drawings depicted in the mural - became known for his great art as:',
    odp: [
      'painter',
      'sculptor',
      'illustrator',
    ]
  }
];

pathQA_en[3] = [
  {
    id: 1,
    name: 'To which type of instruments would you classify the arc of sound?',
    odp: [
      'wind instruments',
      'stringed instruments',
      'percussion instrumentsn',
    ]
  },
  {
    id: 2,
    name: 'What physical phenomenon do wind pumps use to produce sound?',
    odp: [
      'free flow of air',
      'friction of wooden parts',
      'flow of air under pressure',
    ]
  },
  {
    id: 3,
    name: 'This instrument (tembos) is named after an animal. Which one?',
    odp: [
      'snake',
      'flamingo',
      'elephantI',
    ]
  },
  {
    id: 4,
    name: 'Parabolic reflectors use the phenomenon of:',
    odp: [
      'a multiplied echo',
      'concentration of sound waves',
      'curvature of sound waves',
    ]
  },
  {
    id: 5,
    name: 'Which building can be seen through the telescope when looking towards the SSE?',
    odp: [
      'The Town Hall',
      'the cathedral',
      'the White Church',
    ]
  }
];

pathQA_de[1] = [
  {
    id: 1,
    name: 'In welchem Land ist diese Gattung beheimatet?',
    odp: [
      'in Japan',
      'in China',
      'in Polen',
    ]
  },
  {
    id: 2,
    name: 'Der Spitzahorn wird in einigen Regionen dieses Landes als eine invasive Art angesehen und sein Anbau ist rechtlich verboten. Dieses Land ist:',
    odp: [
      'Großbritannien',
      'China',
      'USA',
    ]
  },
  {
    id: 3,
    name: 'Die Rinde der jungen Hänge-Birken ist:',
    odp: [
      'grünlich',
      'orange-rot',
      'graublau',
    ]
  },
  {
    id: 4,
    name: 'Bei der Linde werden zu Heilzwecken genutzt:',
    odp: [
      'Früchte',
      'Blätter',
      'Blüten',
    ]
  },
  {
    id: 5,
    name: 'Euroamerikanische Pappel ist bekannt auch unter dem Namen:',
    odp: [
      'Dickens Pappel',
      'kanadische Pappel',
      'Grau-Pappel',
    ]
  },
]

pathQA_de[2] = [
  {
    id: 1,
    name: 'Welche historische Periode ist für die Namensquelle  Kosakenberg angesehen?',
    odp: [
      'Der II. Weltkrieg',
      'Der Siebenjährige Krieg (1756-63)',
      'Die Oktoberrevolution',
    ]
  },
  {
    id: 2,
    name: 'Ein Ziegelgebäude auf dem Hügel ist:',
    odp: [
      'Kosakenbastei',
      'Wasserturm',
      'Wetterstation',
    ]
  },
  {
    id: 3,
    name: 'Das Obelisk im Siemiradzki-Park gedenkt:',
    odp: [
      'der großpolnischen Aufständischen',
      'der Opfern des Massakers von Wolyn',
      'der Opfern des I. Weltkrieges',
    ]
  },
  {
    id: 4,
    name: 'Der Vorkriegsname von Siemiradzki-Park ist:',
    odp: [
      'Stadtpark',
      'Kaiser Wilhelm-Park',
      'Quilitzpark',
    ]
  },
  {
    id: 5,
    name: 'Gustav Seitz - ein Autor von den auf dem Mural dargestellten Zeichnungen – erlangte Ruhm eines großen Künstlers als:',
    odp: [
      'Maler',
      'Bildhauer',
      'Zeichner',
    ]
  }
];

pathQA_de[3] = [
  {
    id: 1,
    name: 'Zu welchem Instrumententyp  zählt der Klangbogen?',
    odp: [
      'Blasinstrumente',
      'Saiteninstrumente',
      'Schlaginstrumente',
    ]
  },
  {
    id: 2,
    name: 'Welches physikalisches Phänomen wird von den Luftpumpen zur Klangerzeugung benutzt?',
    odp: [
      'freie Luftströmung',
      'Reibung der Holzelemente',
      'Druckluftströmung',
    ]
  },
  {
    id: 3,
    name: 'Der Name dieses Instrumentes (Tembos) stammt von einem Tiernamen. Welchem?',
    odp: [
      'Schlange',
      'Flamingo',
      'Elefant',
    ]
  },
  {
    id: 4,
    name: 'Die Parabolreflektoren nutzen folgendes Phänomen, d. h.:',
    odp: [
      'vervielfältigtes Echo',
      'Schallwellenkonzentration',
      'Schallwellenkrümmung',
    ]
   
  },
  {
    id: 5,
    name: 'Welches Gebäude sieht man durch den Teleskop, wenn man in eine südsüdöstliche Richtung schaut?',
    odp: [
      'Rathaus',
      'Dom',
      'Weißkirche',
    ]
  },
];

switch (lang!) {
  case "pl":
  case "pl-PL":
  default:
    pathQA_lang[1] = pathQA_pl[1];
    pathQA_lang[2] = pathQA_pl[2];
    pathQA_lang[3] = pathQA_pl[3];
  break;
  case "de":
  case "de-DE":
    pathQA_lang[1] = pathQA_de[1];
    pathQA_lang[2] = pathQA_de[2];
    pathQA_lang[3] = pathQA_de[3];
  break;
  case "en":
  case "en-EN":
  case "en-GB":
  case "en-gb":
  case "en-us":
    pathQA_lang[1] = pathQA_en[1];
    pathQA_lang[2] = pathQA_en[2];
    pathQA_lang[3] = pathQA_en[3];
  break;
}


pathQA[1] = [
  {
    id: 1,
    img: 'points/17_milorzab_dwuklapowy.png',
    name: pathQA_lang![1][0].name,
    odp: pathQA_lang![1][0].odp,
    odpOk: 1
  },
  {
    id: 2,
    img: 'points/22_klon_pospolity.png',
    name: pathQA_lang[1][1].name,
    odp: pathQA_lang[1][1].odp,
    odpOk: 2
  },
  {
    id: 3,
    img: 'points/25_brzoza_brodawkowata.png',
    name: pathQA_lang[1][2].name,
    odp: pathQA_lang[1][2].odp,
    odpOk: 1
  },
  {
    id: 4,
    img: 'points/28_lipa_szerokolistna.png',
    name: pathQA_lang[1][3].name,
    odp: pathQA_lang[1][3].odp,
    odpOk: 2
  },
  {
    id: 5,
    img: 'points/31_topola_euroamerykanska.png',
    name: pathQA_lang[1][4].name,
    odp: pathQA_lang[1][4].odp,
    odpOk: 1
  }
];

pathQA[2] = [
  {
    id: 1,
    img: 'points/Kozacza_Gora.jpg',
    name: pathQA_lang[2][0].name,
    odp: pathQA_lang[2][0].odp,
    odpOk: 1
  },
  {
    id: 2,
    img: 'points/wieza_cisnien.jpg',
    name: pathQA_lang[2][1].name,
    odp: pathQA_lang[2][1].odp,
    odpOk: 1
  },
  
  {
    id: 3,
    img: 'points/tablica_wolynska.jpg',
    name: pathQA_lang[2][2].name,
    odp: pathQA_lang[2][2].odp,
    odpOk: 1
  },
  {
    id: 4,
    img: 'points/420-Park-Siemiradzkiego-scaled.jpg',
    name: pathQA_lang[2][3].name,
    odp: pathQA_lang[2][3].odp,
    odpOk: 2
  },
  {
    id: 5,
    img: 'points/mural.jpg',
    name: pathQA_lang[2][4].name,
    odp: pathQA_lang[2][4].odp,
    odpOk: 2
  }
];

/* sensoryczna */
pathQA[3] = [
  
  {
    id: 1,
    img: 'points/21_luk_dzwieku.jpg',
    name: pathQA_lang[3][0].name,
    odp: pathQA_lang[3][0].odp,
    odpOk: 2
  },
  {
    id: 2,
    img: 'points/E_pompy_wietrzne.jpg',
    name: pathQA_lang[3][1].name,
    odp: pathQA_lang[3][1].odp,
    odpOk: 1
  },
  {
    id: 3,
    img: 'points/10_tembos.jpg',
    name: pathQA_lang[3][2].name,
    odp: pathQA_lang[3][2].odp,
    odpOk: 2
  },
  {
    id: 4,
    img: 'points/reflektory.jpg',
    name: pathQA_lang[3][3].name,
    odp: pathQA_lang[3][3].odp,
    odpOk: 1
   
  },
  {
    id: 5,
    img: 'points/1_teleskop.jpg',
    name: pathQA_lang[3][4].name,
    odp: pathQA_lang[3][4].odp,
    odpOk: 0
  },
  /* {
    id: 5,
    img: 'points/21_luk_dzwieku.jpg',
    name: 'Co to?',
    opis: '',
    odp: [
      'łuk',
      'łuk',
      'aaa',
    ],
    odpOk: 2
  } */
];

export const dict:any|string[any|string] = [];

dict['pl'] = 
  {
    tak: 'tak',
    nie: 'nie',
    zamknij: 'zamknij',
    pytanie: 'pytanie',
    dobrze: 'dobrze',
    zle: 'źle',
    brak_odp: 'brak odpowiedzi',
    odp_zapisz: 'zapisz odpowiedź',
    odp_zapisana: 'Odpowiedź została zapisana!',
    zasady_gry: 'poznaj zasady gry',
    zacznij_gre: 'zacznij grę',
    zakoncz_gre: 'zakończ grę',
    zakonczyc_gre: 'Zakończyć grę?',
    zakonczyc_gre_msg: 'Zobaczysz podsumowanie gry i&nbsp;swój wynik. Będziesz miał możliwość zapisania gry na serwerze aplikacji.',
    ostatni_punkt: 'To jest ostatni punkt ścieżki. Po zakończeniu gry zobaczysz podsumowanie i swój wynik. Będziesz miał możliwość zapisania gry na serwerze aplikacji.',
    zakoncz_zapisz: 'zapisz wynik i zakończ',
    wyczysc_odp: 'wyczyść odpowiedzi',
    wroc_do_gry: 'wróć do gry',
    aktualny_wynik: 'Aktualny wynik',
    najnowsze_wyniki: 'Najnowsze wyniki',
    nastepny: 'następny',
    poprzedni: 'poprzedni',
    skanuj_QR: 'skanuj kod QR',
    gdzie_jestem: 'gdzie jestem?',
    przejdz_do_punktu: 'Przejdź do punktu',
    przejdz_do_nast_punktu: 'Przejdź do następnego punktu',
    przejdz_do_punktu_i_odp: 'Przejdź do punktu i odpowiedz na pytanie.',
    aktualizacja: 'Aktualizacja danych...',
    aktualnosci: 'Aktualności',
    moje_gry: 'Moje gry',
    brak_zapisanych: 'brak zapisanych gier...',
    ustawienia_reset: 'Ustawienia aplikacji zostaną zresetowane!',
    kontynuowac: 'Kontynuować?',
    ustawienia_zreset: 'Ustawienia zostały zresetowane.',
    gotowe: 'Gotowe!',
    zdjecie: 'Zrób zdjęcie',
    wyslij: 'Wyślij',
    twoj_email: 'Twój email',
    problem_opis: 'Opis problemu',
    problem_zglosz: 'Zgłaszanie problemów',
    sledzenie: 'Location tracking',
    zuzycie_baterii: '(higher battery consumption)',
    data: 'data',
    wynik: 'wynik',
    czas: 'czas'
  };

dict['en'] = 
  {
    tak: 'yes',
    nie: 'no',
    zamknij: 'close',
    pytanie: 'question',
    dobrze: 'right',
    zle: 'wrong',
    brak_odp: 'no answer',
    odp_zapisz: 'save the answer',
    odp_zapisana: 'The answer has been saved!',
    zasady_gry: 'rules of the game',
    zacznij_gre: 'start the game',
    zakoncz_gre: 'finish the game',
    zakonczyc_gre: 'Finish the game?',
    zakonczyc_gre_msg: 'You will see a summary of the game and your score. You will have the option to save the game on application server.',
    ostatni_punkt: 'This is the last point of this path. When you finish the game, you will see a summary of the game and your score. You will have the option to save the game on the application server.',
    zakoncz_zapisz: 'write score & finish the game',
    wyczysc_odp: 'clear answers',
    wroc_do_gry: 'back to the game',
    aktualny_wynik: 'The current score',
    najnowsze_wyniki: 'Recent results',
    nastepny: 'next',
    poprzedni: 'prev',
    skanuj_QR: 'scan the QR code',
    gdzie_jestem: 'where am I?',
    przejdz_do_punktu: 'Go to the point',
    przejdz_do_nast_punktu: 'Go to the next point',
    przejdz_do_punktu_i_odp: 'Goto the point and answer the question.',
    aktualizacja: 'loading...',
    aktualnosci: 'News',
    moje_gry: 'My games',
    brak_zapisanych: 'no saved games...',
    ustawienia_reset: 'Application settings will be reset!',
    kontynuowac: 'Continue?',
    ustawienia_zreset: 'The settings have been reset.',
    gotowe: 'Finished!',
    zdjecie: 'Photo',
    wyslij: 'Send',
    twoj_email: 'Your email',
    problem_opis: 'Opis problemu',
    problem_zglosz: 'Zgłaszanie problemów',
    sledzenie: 'Location tracking',
    zuzycie_baterii: '(higher battery consumption)',
    data: 'date',
    wynik: 'score',
    czas: 'time'
  };

  dict['de'] = 
  {
    tak: 'ja',
    nie: 'nein',
    zamknij: 'schließen',
    pytanie: 'Frage',
    dobrze: 'gut',
    zle: 'schlecht',
    brak_odp: 'keine Antwort',
    odp_zapisz: 'speichern',
    odp_zapisana: 'Die Atwort wurde gespeichert',
    zasady_gry: 'Spielregeln',
    zacznij_gre: 'Spiel beginnen',
    zakoncz_gre: 'Spiel beenden',
    zakonczyc_gre: 'Spiel beenden?',
    zakonczyc_gre_msg: 'Du siehst die Spielzusammenfassung und dein Ergebnis. Du hast die Möglichkeit, das Spiel auf dem App-Server zu speichern',
    ostatni_punkt: 'Das ist der letzte Punkt des Pfades. Nach Spielbeendigung siehst du die Zusammenfassung und dein Ergebnis. Du hast die Möglichkeit, das Spiel auf dem App-Server zu speichern.',
    zakoncz_zapisz: 'beenden & speichern',
    wyczysc_odp: 'Antworten löschen',
    aktualny_wynik: 'Aktuelles Ergebnis',
    najnowsze_wyniki: 'Die neuesten Ergebnisse',
    wroc_do_gry: 'Zurück ins Spiel',
    nastepny: 'weiter',
    poprzedni: 'zurück',
    skanuj_QR: 'QR-Code scannen',
    gdzie_jestem: 'Wo bin ich?',
    przejdz_do_punktu: 'zum Punkt wechseln',
    przejdz_do_nast_punktu: 'zum nächsten Punkt wechseln',
    przejdz_do_punktu_i_odp: 'zum Punkt wechseln und die Frage beantworten.',
    aktualizacja: 'Upgrade...',
    aktualnosci: 'Nachrichten',
    moje_gry: 'Meine Spiele',
    brak_zapisanych: 'brak zapisanych gier...',
    ustawienia_reset: 'Ustawienia aplikacji zostaną zresetowane!',
    kontynuowac: 'Kontynuować?',
    ustawienia_zreset: 'Ustawienia zostały zresetowane.',
    gotowe: 'Gotowe!',
    zdjecie: 'Foto machen',
    wyslij: 'Senden',
    twoj_email: 'Deine Email',
    problem_opis: 'Probleme beschreiben',
    problem_zglosz: 'Probleme melden',
    sledzenie: 'Standortverfolgung',
    zuzycie_baterii: '(höherer Batterieverbrauch)',
    data: 'Date',
    wynik: 'Ergebnis',
    czas: 'Zeit'
  };