import React from 'react';
import AppContext from '../Context';
import { 
  IonAlert 
} from '@ionic/react';
import { dict, lang } from './Config';

interface AlertsProps { 
  header:string, 
  message:string, 
  showerror:boolean,
  confirm:boolean, 
  success:boolean, 
  dismiss: () => void;
}

const Alerts: React.FC<AlertsProps> = ({header,message,showerror,confirm,success,dismiss}) => {

  const { sharedValues, setSharedValues } = React.useContext(AppContext);

  return (
    <div>
    <IonAlert
        isOpen={showerror}
        onDidDismiss={() => dismiss()}
        cssClass="opty-alert"
        header={header}
        message={message}
        buttons={[dict[lang]["zamknij"]]}
    />

    <IonAlert
        isOpen={confirm}
        onDidDismiss={() => dismiss()}
        cssClass="opty-alert"
        header={header}
        message={message}
        buttons={[
          {
            text: dict[lang]['nie'],
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              const newSharedValues = {
                ...sharedValues,
                confirm: false
              }
              setSharedValues(newSharedValues);
              console.log('Confirm Cancel');
            }
          },
          {
            text: dict[lang]['tak'],
            handler: () => {
              const newSharedValues = {
                ...sharedValues,
                confirm: true
              }
              setSharedValues(newSharedValues);
              console.log('Confirm Okay');
            }
          }
        ]}
    />
    <IonAlert
        isOpen={success}
        onDidDismiss={() => dismiss()}
        cssClass="opty-alert"
        header={header}
        message={message}
        buttons={[dict[lang]["zamknij"]]}
    />
    
    </div>
  );
}
export default Alerts;