import React, { useContext, useEffect, useState } from 'react';
import { config, consoleLog, dict, lang, paths, timerFormat } from '../components/Config';
import { 
    IonLoading,
    IonContent,
    IonPage,
    IonCardContent,
    IonCardHeader,
    IonList,
    IonItem,
    IonButton,
    IonSelect,
    IonSelectOption} from '@ionic/react';
import AppContext from '../Context';
import Refresher from '../components/Refresher';

interface Game { 
  game: {
    id:string,
    user_name:string,
    path:string,
    game_token:string,
    score_date:string,
    score_time:string,
    score:string,
    device_FCM_token:string
  }
}

const GameCard: React.FC<Game> = ({game}) => {
  return (
    <IonCardContent>
      {/* <IonCardHeader>{game.id}</IonCardHeader> */}
      <p><b>{game.user_name}</b>, {paths![game.path]}</p>
      <p>{dict[lang]['data']}: {game.score_date}</p>
      <div>{dict[lang]['wynik']}: <b>{game.score} pkt.</b>, {dict[lang]['czas']}: <b>{timerFormat(game.score_time)} min.</b></div>
    </IonCardContent>
  );
}

const BestGames: React.FC = () => {
  
  const { sharedValues, setSharedValues } = useContext(AppContext);
  const [isLoaded, setisLoaded]           = useState<boolean>(false);
  const [games, setGames]                 = useState<any[]>([]);
  
  const paths_arr = Object.keys(paths).map(function(key, index) {
    consoleLog('path',paths[key]);
    return paths[key]
  });

  const getGames = () => {
      
    //const FCM_mobile_token:any = localStorage.getItem('FCM_mobile_token');
    //const game_token:any       = localStorage.getItem('game_token');

    const formData = new FormData(); 
          formData.append('path', sharedValues.path);

    fetch(config.apiURL + "/best_results.php?uid=", {
      method: "POST",
      body: formData
    })
    .then(response => {
      
      const newSharedValues = {
        ...sharedValues,
        refresh: '0'
      }
      setSharedValues(newSharedValues);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(json => {
      consoleLog("Best Games:", json);
      setisLoaded(true);
      setGames(json);

    })
    .catch(err => consoleLog("Error:",err));
  }
  
  useEffect(() => {
    if (sharedValues.refresh === '1') {
      getGames();
    }
  },[sharedValues.path,sharedValues.refresh]);

  useEffect(() => {
      getGames();
  },[]);
  
  //useEffect(() => {getGames()},[sharedValues.refresh]);

  if(!isLoaded) {

    return (
      <div className="ion-padding ion-text-center">
        <IonLoading 
          isOpen
          message={dict[lang]['aktualizacja']}
          duration={5000}
        />
      </div>
    );
    
  } else {

    return(
      <IonPage>
        
        <IonContent className='ion-padding-page'>

          <Refresher />

          <h1 className='ion-padding'>{dict[lang]['najnowsze_wyniki']}</h1>
          
          <IonItem>
            <IonSelect 
              name="path" 
              value={sharedValues.path}
              placeholder={paths[sharedValues.path]}
              cancelText={dict[lang]['zamknij']}
              onIonChange={(e) => {
                //window.localStorage.setItem('path',e.detail.value);
                const newSharedValues = {
                  ...sharedValues,
                  path: e.detail.value,
                  refresh: '1'
                }
                setSharedValues(newSharedValues);
                //setResult(0);
              }}>
                
                {paths_arr.map((path:any,index:any) => 
                  <IonSelectOption value={(index+1)} key={index}>{path}</IonSelectOption>  
                )}

                {/* {Object.keys(paths).map(function(key, index) {
                  <IonSelectOption value={key} key={index}>{paths[key]}</IonSelectOption>
                })} */}

            </IonSelect>
          </IonItem>

          <IonList id="news">
          
            {games && games.map(game =>
              <IonItem key={game.id} lines="inset">
                <GameCard game={game} />
              </IonItem>
            )}

            {!games &&
              <h3 className="ion-text-center">
                {dict[lang]['brak_zapisanych']}
              </h3>
            }
              
          </IonList>
        </IonContent>
      </IonPage>
    )

  }
}

export default BestGames;