import { Device } from '@capacitor/device';
import React, { createContext, useEffect, useState } from 'react';
import { consoleLog } from './components/Config';

const AppTimerContext = createContext<any>(undefined);
      AppTimerContext.displayName = 'AppTimerContext';
export default AppTimerContext;

type Props = {
  children: React.ReactNode;
};

export const AppTimerProvider = ({ children }: Props) => {
  const [sharedTimer, setSharedTimer] = useState<any>({timer:0});

  let localTimerState:any = [];

  useEffect(() => {

    if (localStorage.getItem("sharedTimer") !== null) {

      localTimerState = JSON.parse(localStorage.getItem("sharedTimer") || '');
      
      //consoleLog('contextTimer localTimerState 1',localTimerState);
    } else {
      localTimerState = 
        {
          timer: 0
        }
      //consoleLog('contextTimer localTimerState 2',localTimerState);
    }

    setSharedTimer(localTimerState);

  },[]);

  useEffect(() => {
    if (sharedTimer) {
      const sharedToLocal = {
        ...sharedTimer
      }
      /* consoleLog('contextTimer:',sharedToLocal); */
      localStorage.setItem("sharedTimer", JSON.stringify(sharedToLocal));
    }
  },[sharedTimer]);

  let timerState = {
    sharedTimer, setSharedTimer
  }
  
  return (
    <AppTimerContext.Provider value={timerState}>{children}</AppTimerContext.Provider>
  );
};