import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonSelect,
  IonSelectOption,
  IonToggle,
  isPlatform,
} from '@ionic/react';

import { useHistory, useLocation } from 'react-router-dom';

import AppContext from '../Context';
import AppQRContext from '../ContextQR';

import { archiveOutline, archiveSharp, beer, beerSharp, bookmarkOutline, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, walk, walkSharp, warningOutline, warningSharp, location, locationSharp, scan, scanSharp, person, personSharp, image, imageSharp, documentText, documentTextSharp, school, schoolSharp, bug, bugSharp, newspaper, newspaperSharp, language, trailSign, trailSignSharp, hourglass, hourglassSharp, informationCircle, informationCircleSharp, camera, cameraSharp, call, callSharp } from 'ionicons/icons';
import './Menu.css';
import Stoper from './Stoper';
import { useContext, useEffect, useState } from 'react';
import React from 'react';


import { paths, menu, pathPoints, consoleLog, langs, dict, lang } from './Config';

/* if(!isPlatform('hybrid')) {
  const langs = [
    'pl-PL',
    'en-EN',
    'de-DE'
  ];
} */
/* import { menuPL, pathsPL, pointsPL } from '../local/pl-PL';
import { menuDE, pathsDE, pointsDE } from '../local/de-DE';
import { menuEN, pathsEN, pointsEN } from '../local/en-EN';

let menuLng, pathsLng, pointsLng;

const lang = window.localStorage.getItem('lang');

switch (lang) {
  case "pl-PL":
    menuLng   = menuPL!;
    pathsLng  = pathsPL!;
    pointsLng = pointsPL!;
  break;
  case "de-DE":
    menuLng   = menuDE!;  
    pathsLng  = pathsDE!;
    pointsLng = pointsDE!;
  break;
  case "en-EN":
    pathsLng  = pathsEN!;
    menuLng   = menuEN!;
    pointsLng = pointsEN;
  break;
}

const menu:any   = menuLng;
const paths:any  = pathsLng;
const points:any = pointsLng; */

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

//if (menu) {
  const appPages: AppPage[] = [
    
    {
      title: menu?.poczatek!,
      url: '/',
      iosIcon: paperPlaneOutline,
      mdIcon: paperPlaneSharp
    },
    {
      title: menu?.skanuj!,
      url: '/QR',
      iosIcon: scan,
      mdIcon: scanSharp
    },
    {
      title: menu?.zasady!,
      url: '/instrukcja',
      iosIcon: documentText,
      mdIcon: documentTextSharp
    },
    {
      title: menu?.gry!,
      url: '/games',
      iosIcon: trailSign,
      mdIcon: trailSignSharp
    },
    {
      title: menu?.wyniki!,
      url: '/results',
      iosIcon: school,
      mdIcon: schoolSharp
    },
    {
      title: menu?.najgry!,
      url: '/bestgames',
      iosIcon: trailSign,
      mdIcon: trailSignSharp
    },
    {
      title: menu?.historia!,
      url: '/historia',
      iosIcon: hourglass,
      mdIcon: hourglassSharp
    },
    {
      title: menu?.henryk!,
      url: '/henryk',
      iosIcon: person,
      mdIcon: personSharp
    },
    {
      title: menu?.news!,
      url: '/news',
      iosIcon: newspaper,
      mdIcon: newspaperSharp
    },
    {
      title: menu?.info!,
      url: '/info',
      iosIcon: informationCircle,
      mdIcon: informationCircleSharp
    },
    /* {
      title: menu?.galeria!,
      url: '/slides',
      iosIcon: image,
      mdIcon: imageSharp
    }, */
    /* {
      title: menu?.error!,
      url: '/admin',
      iosIcon: bug,
      mdIcon: bugSharp
    }, */
    /* {
      title: 'take picture',
      url: '/camera',
      iosIcon: camera,
      mdIcon: cameraSharp
    } */
  ];
//}

const labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];


const Menu: React.FC = () => {
  const {sharedValues, setSharedValues} = useContext(AppContext);
  const { sharedQR, setSharedQR }       = useContext(AppQRContext);
  
  const locationPath = useLocation();
  let history = useHistory();

  /* useEffect(() => {
    //window.location.reload();
    //window.setTimeout(() => {window.location.reload()},2000);
    consoleLog('lang change?','');

    if (locationPath.pathname.search('/en-EN') === 0) {
      const newSharedValues = {
        ...sharedValues,
        lang: 'en-EN' 
      }
      setSharedValues(newSharedValues);
      consoleLog('change?? EN','');
    } else if (locationPath.pathname.search('/de-DE') === 0) {
      const newSharedValues = {
        ...sharedValues,
        lang: 'de-DE' 
      }
      setSharedValues(newSharedValues);
      consoleLog('change?? DE','');
    }
  }, []); */

  //consoleLog('locationPath',locationPath.pathname.search('/en')+' | '+locationPath.pathname);

  const goToPoint = (point:any) => {
    //consoleLog('goToPoint','go '+point);
    const newSharedValues = {
      ...sharedValues,
      point: point
    }
    setSharedValues(newSharedValues);
  }

  /* const scanQR = () => {
    const newSharedValues = {
      ...sharedValues,
      scanQR: true
    }
    setSharedValues(newSharedValues);
  } */
  
  return (
    <IonMenu side="end" contentId="main" type="reveal" id="manuMain">
      <IonContent>
        <IonList id="inbox-list" lines="none">
          
          {/* {sharedValues.debug === true &&
            <Stoper />
          } */}
          
          
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={locationPath.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="forward" lines="none" detail={false}>
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title!}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
          
          <IonItem>
            <IonIcon slot="start" icon={language} />
            {/* <IonLabel position="floating">PL</IonLabel> */}
            <IonSelect 
              name="lang" 
              value={window.localStorage.getItem('lang')}
              cancelText={dict[lang]['zamknij']}
              onIonChange={(e) => {
              //history.push('/'+e.detail.value);
              //consoleLog('lang 1',e.detail.value);
              window.localStorage.setItem('lang',e.detail.value);
              //window.setTimeout(() => {history.push('/'+e.detail.value)},1000);
              /* const newSharedValues = {
                ...sharedValues,
                lang: e.detail.value
              }
              setSharedValues(newSharedValues); */
              window.setTimeout(() => {window.location.reload()},200);
              }}>
              
              {langs!.map((lang,index) => 
                <IonSelectOption value={lang} key={index}>{lang.toUpperCase()}{/* lang.substring(3) */}</IonSelectOption>  
              )}

            </IonSelect>
          </IonItem>
          
          <IonMenuToggle autoHide={false}>
            <IonItem lines="none" detail={false} routerLink='/zielony' /* href='tel:957355666' */>
              <IonIcon slot="start" ios={callSharp} md={call} />
              <IonLabel>{menu?.zielony!}</IonLabel>
            </IonItem>
          </IonMenuToggle>

          {sharedValues.path &&
            <>
            
            <IonItem>{paths![sharedValues.path]}:</IonItem>

            {pathPoints[sharedValues.path].map((point:any, index:any) => {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem className={sharedValues.point === point.id && locationPath.pathname.search('/start') !== -1  ? 'selected' : ''} /* onClick={() => goToPoint(point.id)} */ routerLink={"/start/"+sharedValues.path+"/"+point.id} routerDirection="forward" lines="none" detail={false}>
                    <IonIcon slot="start" ios={location} md={locationSharp} />
                    <IonLabel>{point.name}{/* points[sharedValues.path][point.id] */}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            })}
            </>
          }

          {/* <IonMenuToggle autoHide={false}>
            <IonItem onClick={() => scanQR()} lines="none" routerLink="/QR" routerDirection="forward" detail={false}>
              <IonIcon slot="start" ios={scan} md={scanSharp} />
              <IonLabel>skanuj kod QR</IonLabel>
            </IonItem>
          </IonMenuToggle> */}

          <IonItem>
            <IonLabel>{dict[lang]['sledzenie']} {/* ({JSON.stringify(sharedValues.geoloc)}) */}<br /><span>{dict[lang]['zuzycie_baterii']}</span></IonLabel>
            <IonToggle checked={sharedValues.geoloc} 
              onIonChange={e => {
                //setGeoLocOn(e.detail.checked);
                const newSharedValues = {
                  ...sharedValues,
                  geoloc: e.detail.checked
                }
                setSharedValues(newSharedValues);
              }} />
          </IonItem>
          
          {/* <IonItem>
            <IonLabel>debug</IonLabel>
            <IonToggle checked={sharedValues.debug} 
              onIonChange={e => {
                //setGeoLocOn(e.detail.checked);
                const newSharedValues = {
                  ...sharedValues,
                  debug: e.detail.checked
                }
                setSharedValues(newSharedValues);
                //consoleLog('geoLocOn',geoLocOn);
              }} />
          </IonItem> */}

        </IonList>

        {/* <IonList id="labels-list">
          <IonListHeader>Labels</IonListHeader>
          {labels.map((label, index) => (
            <IonItem lines="none" key={index}>
              <IonIcon slot="start" icon={bookmarkOutline} />
              <IonLabel>{label}</IonLabel>
            </IonItem>
          ))}
        </IonList> */}

      </IonContent>
    </IonMenu>
  );
};

export default Menu;
