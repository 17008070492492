export const menuDE = {
  poczatek: 'Spielbeginn',
  skanuj: 'QR-Code scannen',
  gry: 'Meine Spiele',
  zasady: 'Spielgrundsätze',
  wyniki: 'Aktuelles Ergebnis',
  najgry: 'Die neuesten Ergebnisse',
  henryk: 'Henryk Siemiradzki',
  historia: 'Parkgeschichte',
  news: 'Aktualitäten',
  info: 'Über die Applikation',
  galeria: 'Fotogalerie',
  error: 'Fehler melden',
  zielony: 'Grüne Notrufnummer'
}

export const pathsDE = {
  1: 'Botanischer Pfad',
  2: 'Historischer Pfad',
  3: 'Sensorischer Pfad'
}

export const pointsDE = [
  {},
  {
    1: 'Punkt 1',
    2: 'Punkt 2',
    3: 'Punkt 3',
    4: 'Punkt 4',
    5: 'Punkt 5'
  },
  {
    1: 'Punkt 1',
    2: 'Punkt 2',
    3: 'Punkt 3',
    4: 'Punkt 4',
    5: 'Punkt 5'
  },
  {
    1: 'Punkt 1',
    2: 'Punkt 2',
    3: 'Punkt 3',
    4: 'Punkt 4',
    5: 'Punkt 5'
  }
];

export const pointsNamesDE = [
  {},
  {
    1: 'Ginkgo',
    2: 'Roteiche',
    3: 'Hänge-Birke',
    4: 'Sommerlinde',
    5: 'Euroamerikanische Pappel'
  },
  {
    1: 'Gewöhnliche Esche',
    2: 'Berg-Ahorn',
    3: 'Teppich-Zwergmispel',
    4: 'Rosskastanien',
    5: 'Silber-Pappel'
  },
  {
    1: 'Flatter-Ulme',
    2: 'Schwarze Holunder',
    3: 'Alpen-Johannisbeere',
    4: 'Krim-Linde',
    5: 'Hainbuche'
  }
];